import { HierarchicalItem } from 'src/types/elements.type';
import { IItemGroup, ItemGroupAdd } from 'src/types/item_master_data/itemGroup.type';
import { http } from 'src/utils/http';

const controller = 'pm/item-groups';

export const getItemGroupsDropdown = async (itemTypeId?: number) => {
  return await http.get<HierarchicalItem[]>(`${controller}/dropdown`, {
    params: {
      itemTypeId
    }
  });
};

export const getItemGroups = async (
  sequenceStatus: string,
  itemTypeId: number | undefined,
  searchCondition: string | undefined
) => {
  return await http.get<IItemGroup[]>(controller, {
    params: {
      sequenceStatus,
      itemTypeId,
      searchCondition
    }
  });
};

export const getItemGroup = async (id: number) => {
  return await http.get<IItemGroup>(`${controller}/${id}`);
};

export const addItemGroup = async (itemGroup: ItemGroupAdd) => {
  return await http.post(controller, itemGroup);
};

export const updateItemGroup = async (id: number, itemGroup: ItemGroupAdd) => {
  return await http.put(`${controller}/${id}`, itemGroup);
};

export const deleteItemGroup = async (id: number) => {
  return await http.delete(`${controller}/${id}`);
};
