import { TicketResponses } from 'src/components/v2/Button/BtnHrHandle';
import { RangeDateTypeHr } from 'src/types/date.type';
import { FlatItem } from 'src/types/elements.type';
import { ApprovalStatus } from 'src/types/helpdesk/ticket.type';
import {
  EquipmentSupplyRequestTicketAdd,
  IEquipmentSupplyRequestTicket
} from 'src/types/hr_ticket/equipmentSupplyRequestTicket.type';
import { PagedList } from 'src/types/pagedList.type';
import { ITicketParams } from 'src/types/params.type';
import { http } from 'src/utils/http';

const controller = 'hr/equipment-supply-request-tickets';

export const getEquipmentSupplyRequestTickets = async (params: ITicketParams) => {
  return await http.get<PagedList<IEquipmentSupplyRequestTicket>>(controller, {
    params: params
  });
};

export const getEquipmentSupplyRequestTicketsApproval = async (params: ITicketParams) => {
  return await http.get<PagedList<IEquipmentSupplyRequestTicket>>(`${controller}/waiting-approval`, {
    params: params
  });
};

export const getEquipmentSupplyRequestTicket = async (id: number) => {
  return await http.get<IEquipmentSupplyRequestTicket>(`${controller}/${id}`);
};

export const getEquipmentSupplyRequestTicketsDropdown = async () => {
  return await http.get<FlatItem[]>(`${controller}/dropdown`);
};

export const addEquipmentSupplyRequestTicket = async (
  equipmentSupplyRequestTicket: EquipmentSupplyRequestTicketAdd
) => {
  return await http.post(controller, equipmentSupplyRequestTicket);
};

export const updateEquipmentSupplyRequestTicket = async (
  id: number,
  equipmentSupplyRequestTicket: EquipmentSupplyRequestTicketAdd
) => {
  return await http.put(`${controller}/${id}`, equipmentSupplyRequestTicket);
};

export const deleteEquipmentSupplyRequestTicket = async (id: number) => {
  return await http.delete(`${controller}/${id}`);
};

export const cancelEquipmentSupplyRequestTicket = async (id: number) => {
  return await http.put(`${controller}/${id}/cancel`);
};

export const approvalEquipmentSupplyRequestTickets = async (
  ticketResponses: TicketResponses[],
  approvalStatus: ApprovalStatus,
  note?: string
) =>
  await http.put(`${controller}/approvals`, {
    ticketResponses: ticketResponses,
    approvalStatus,
    note: note ? note : ''
  });

export const cancelApprovalEquipmentSupplyRequestTickets = async (id: string, step: number) =>
  await http.put(`${controller}/${id}/cancel-approval`, { step });

export const getRefEquipmentSupplyRequestTicketsForTicket = async (refTicketId: string) =>
  await http.get<IEquipmentSupplyRequestTicket[]>(`${controller}/get-ref-tickets/${refTicketId}`);

export const getEquipmentSupplyRequestWaitingProcessingCount = async (params: RangeDateTypeHr) => {
  return await http.get(`${controller}/count-ticket-waiting-processing`, {
    params: params
  });
};

export const getMyEquipmentSupplyRequestTicketCount = async (params: RangeDateTypeHr) => {
  return await http.get(`${controller}/count-my-tickets`, {
    params: params
  });
};

export const getEquipmentSupplyRequestTicketApprovalCount = async (params: RangeDateTypeHr) => {
  return await http.get(`${controller}/count-handle-tickets`, {
    params: params
  });
};

export const printEquipmentSupplyRequestTicket = async (id: number) =>
  await http.get(`${controller}/print`, {
    params: {
      id: id
    },
    responseType: 'blob'
  });
