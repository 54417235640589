import { useQuery } from '@tanstack/react-query';
import { Select, SelectProps } from 'antd';
import { getTicketCategoriesDropdown } from 'src/apis/helpdesk/ticketCategory.api';

type Props = SelectProps & {
  departmentId?: number;
  ticketCategoryGroupId?: number;
  placeholder?: string;
};

export default function TicketCategorySelect({
  value,
  onChange,
  style,
  allowClear = true,
  departmentId,
  ticketCategoryGroupId,
  placeholder
}: Props) {
  const ticketCategoriesDropdownQuery = useQuery({
    queryKey: ['TicketCategoriesDropdown', departmentId, ticketCategoryGroupId],
    queryFn: () => getTicketCategoriesDropdown(departmentId, ticketCategoryGroupId)
  });

  return (
    <Select
      allowClear={allowClear}
      placeholder={placeholder}
      value={ticketCategoriesDropdownQuery.isFetching ? undefined : value}
      style={style}
      onChange={onChange}
      options={ticketCategoriesDropdownQuery.data?.data.map((data) => ({ value: data.id, label: data.name }))}
      showSearch
      filterOption={(input, option) => (option?.label?.toString() ?? '').toLowerCase()?.includes(input.toLowerCase())}
      loading={ticketCategoriesDropdownQuery.isFetching}
    />
  );
}
