import { Select } from 'antd';
import { SelectProps } from 'src/types/elements.type';

export const dataSource = [
  { value: 'TM', label: 'Tiền mặt' },
  { value: 'CK', label: 'Chuyển khoản' },
  { value: 'TM/CK', label: 'Tiền mặt hoặc chuyển khoản' }
];

export default function PaymentMethodSelect({
  value,
  onChange,
  style,
  allowClear = true,
  placeholder,
  disabled
}: SelectProps) {
  return (
    <Select
      placeholder={placeholder}
      allowClear={allowClear}
      value={value}
      style={{ width: '100%', ...style }}
      onChange={onChange}
      options={dataSource}
      showSearch
      disabled={disabled}
      filterOption={(input, option) => (option?.label?.toString() ?? '').toLowerCase()?.includes(input.toLowerCase())}
    />
  );
}
