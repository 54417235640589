import { useQuery } from '@tanstack/react-query';
import { Select, type SelectProps } from 'antd';
import { useContext } from 'react';
import { getEmployeesDropdown } from 'src/apis/master_data/employee.api';
import { AppContext } from 'src/contexts/app.context';
import { IEmployeeShort, type IEmployeeWithDepartment } from 'src/types/master_data/employee.type';

type Props = SelectProps & {
  departmentId?: number;
  exclusionEmployee?: number;
  dataSource?: IEmployeeShort[];
};

export default function EmployeeSelectV2({
  value,
  style,
  disabled,
  allowClear,
  onChange,
  departmentId,
  exclusionEmployee,
  placeholder,
  dataSource
}: Props) {
  const { isUserAdmin } = useContext(AppContext);
  const deptId = isUserAdmin ? undefined : departmentId;
  const employeesDropdownV2Query = useQuery({
    queryKey: ['employeesDropdownV2', deptId],
    queryFn: () => getEmployeesDropdown(deptId),
    staleTime: 60 * 1000,
    enabled: !dataSource
  });

  return (
    <Select
      allowClear={allowClear}
      value={employeesDropdownV2Query.isFetching ? undefined : value}
      style={style}
      disabled={disabled}
      onChange={onChange}
      optionLabelProp='selectedShow'
      options={(dataSource ?? employeesDropdownV2Query.data?.data)
        ?.filter((x) => x.id !== exclusionEmployee)
        ?.map((item) => ({
          value: item?.id,
          label: `${item.code} - ${item.fullName}`,
          selectedShow: `${item.code} - ${item.fullName}`,
          data: item as IEmployeeWithDepartment
        }))}
      placeholder={placeholder}
      showSearch
      filterOption={(input, option) => (option?.label?.toString() ?? '').toLowerCase()?.includes(input.toLowerCase())}
      loading={employeesDropdownV2Query.isFetching}
    />
  );
}
