import { FlatItem } from 'src/types/elements.type';
import { BranchAdd, IBranch } from 'src/types/master_data/branch.type';
import { PagedList } from 'src/types/pagedList.type';
import { http } from 'src/utils/http';

const controller = 'master-data/branches';

export const getBranches = async (sequenceStatus: string, page: number, pageSize: number, orderBy?: string) => {
  return await http.get<PagedList<IBranch>>(controller, {
    params: {
      sequenceStatus,
      page,
      pageSize,
      orderBy
    }
  });
};

export const getBranch = async (id: number) => {
  return await http.get<IBranch>(`${controller}/${id}`);
};

export const getBranchesDropdown = async () => {
  return await http.get<FlatItem[]>(`${controller}/dropdown`);
};

export const addBranch = async (data: BranchAdd) => {
  return await http.post<IBranch>(controller, data);
};

export const updateBranch = async (id: number, branch: IBranch) => {
  return await http.put<IBranch[]>(`${controller}/${id}`, branch);
};

export const deleteBranch = async (id: number) => {
  await http.delete(`${controller}/${id}`);
};
