import { useQuery } from '@tanstack/react-query';
import { Select } from 'antd';
import { getSubSystemsDropdown } from 'src/apis/master_data/subSystem.api';

interface Props {
  value?: string;
  style?: React.CSSProperties | undefined;
  allowClear?: boolean;
  onChange?: (value: string) => void;
}

export default function SubSystemSelectV2({ value, onChange, style, allowClear = true }: Props) {
  const subSystemsDropdownV2Query = useQuery({
    queryKey: ['subSystemsDropdownV2'],
    queryFn: () => getSubSystemsDropdown(),
    staleTime: 60 * 1000
  });

  return (
    <Select
      allowClear={allowClear}
      value={subSystemsDropdownV2Query.isFetching ? undefined : value}
      style={style}
      onChange={onChange}
      options={subSystemsDropdownV2Query.data?.data.map((data) => ({
        value: JSON.stringify({
          id: data.id,
          name: data.name
        }),
        label: data.name
      }))}
      showSearch
      filterOption={(input, option) => (option?.label?.toString() ?? '').toLowerCase()?.includes(input.toLowerCase())}
      loading={subSystemsDropdownV2Query.isFetching}
    />
  );
}
