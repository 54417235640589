import React from 'react';
import './SlideShow.scss';

type Props = {
  direction?: 'toLeft' | 'toRight';
  children: React.ReactNode;
};

export default function SlideShow({ direction = 'toLeft', children }: Props) {
  return (
    <div style={{ overflowX: 'hidden' }}>
      <div className={`slide-show ${direction === 'toRight' ? 'to-right' : 'to-left'}`}>{children}</div>
    </div>
  );
}
