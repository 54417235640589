import { useQuery } from '@tanstack/react-query';
import { Select } from 'antd';
import { getJobCategoriesDropdown } from 'src/apis/master_data/jobCategory.api';
import { SelectProps } from 'src/types/elements.type';

type Props = SelectProps & {
  value?: string | undefined;
};

export default function JobCategorySelectV2({ value, onChange, style, allowClear = true, placeholder }: Props) {
  const jobCategoryDropdownQuery = useQuery({
    queryKey: ['jobCategoryDropdownV2'],
    queryFn: () => getJobCategoriesDropdown(),
    staleTime: 60 * 1000
  });

  return (
    <Select
      allowClear={allowClear}
      value={jobCategoryDropdownQuery.isFetching ? undefined : value}
      style={style}
      onChange={onChange}
      options={jobCategoryDropdownQuery.data?.data.map((data) => ({ value: String(data.id), label: data.name }))}
      showSearch
      filterOption={(input, option) => (option?.label?.toString() ?? '').toLowerCase()?.includes(input.toLowerCase())}
      loading={jobCategoryDropdownQuery.isFetching}
      placeholder={placeholder}
    />
  );
}
