import { useQuery } from '@tanstack/react-query';
import { Select } from 'antd';
import { getWageTypeDropdown } from 'src/apis/master_data/wageType.api';
import { SelectProps } from 'src/types/elements.type';

export default function WageTypeSelect({ value, style, allowClear = true, onChange, placeholder }: SelectProps) {
  const wageTypeDropdownQuery = useQuery({
    queryKey: ['wageTypeDropdown'],
    queryFn: () => getWageTypeDropdown(),
    staleTime: 60 * 1000 * 5
  });

  return (
    <Select
      allowClear={allowClear}
      value={wageTypeDropdownQuery.isFetching ? undefined : value}
      style={style}
      placeholder={placeholder}
      onChange={onChange}
      options={wageTypeDropdownQuery.data?.data.map((data) => ({
        value: data.id,
        label: data.name
      }))}
      showSearch
      filterOption={(input, option) => (option?.label?.toString() ?? '').toLowerCase()?.includes(input.toLowerCase())}
      loading={wageTypeDropdownQuery.isFetching}
    />
  );
}
