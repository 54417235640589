import { useQuery } from '@tanstack/react-query';
import { Select } from 'antd';
import { getFunctionsDropdown } from 'src/apis/master_data/subSystem.api';
import { SelectProps } from 'src/types/elements.type';

type Props = SelectProps & {
  subSystemId?: number;
};

export default function FunctionSelectV2({ value, onChange, style, allowClear = true, subSystemId }: Props) {
  const functionsDropdownQuery = useQuery({
    queryKey: ['functionsDropdownV2', subSystemId],
    queryFn: () => getFunctionsDropdown(subSystemId),
    staleTime: 60 * 1000,
    enabled: !!subSystemId
  });

  return (
    <Select
      allowClear={allowClear}
      value={functionsDropdownQuery.isFetching ? undefined : value}
      style={style}
      onChange={onChange}
      options={functionsDropdownQuery.data?.data.map((data) => ({
        value: JSON.stringify({
          id: data.id,
          name: data.name
        }),
        label: data.name
      }))}
      showSearch
      filterOption={(input, option) => (option?.label?.toString() ?? '').toLowerCase()?.includes(input.toLowerCase())}
      loading={functionsDropdownQuery.isFetching}
    />
  );
}
