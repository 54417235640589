import { ArrowLeftOutlined } from '@ant-design/icons';
import { Breadcrumb as BreadcrumbAntd, Button, Flex } from 'antd';
import { ItemType } from 'antd/es/breadcrumb/Breadcrumb';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import urlPath from 'src/constants/urlPath';
import { useBasePath } from 'src/hooks/useBasePath';
import './Breadcrumb.scss';

interface Props {
  className?: string;
}

export default function Breadcrumb({ className }: Props) {
  const { t } = useTranslation();
  const [items, setItems] = useState<ItemType[]>([]);
  const basePath = useBasePath();
  const { id } = useParams();
  const navigate = useNavigate();

  useMemo(() => {
    const filtered = Object.entries(urlPath).filter(([key, value]) => {
      return value.location === basePath;
    });
    const currentPath = filtered?.[0]?.[1].path;
    const currentLocationSplit = filtered?.[0]?.[1].location.split('/');

    const newPath = filtered?.[0]
      ? currentPath.map((item, index, array) => {
          const path = currentLocationSplit?.slice(0, index + 1)?.join('/');
          const isLastItem = index === array.length - 1;

          return Object.assign(
            {},
            {
              title: (
                <span
                  className='breadcrumb-item'
                  onClick={() => !isLastItem && navigate(`${path === '' ? '/' : path}`)}
                >
                  {t(item)}
                </span>
              )
            }
          );
        })
      : [{ title: 'Not found' }];

    setItems(newPath);

    if (id) {
      if (typeof id === 'number') {
        setItems([...newPath, { title: String(id) }]);
      } else {
        const truncatedId = id.length > 5 ? `${id.substring(0, 8)}...` : id;
        setItems([...newPath, { title: truncatedId }]);
      }
    }
  }, [basePath, id, navigate, t]);

  return (
    <Flex gap={5} className='breadcrumb-container' align='center'>
      <Button type='text' icon={<ArrowLeftOutlined />} onClick={() => navigate(-1)} />

      <BreadcrumbAntd style={{ margin: '16px 0' }} className={`breadcrumb ${className}`} items={items} />
    </Flex>
  );
}
