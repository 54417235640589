import { Button, Divider, Flex, Form, Popover, Typography } from 'antd';
import Paragraph from 'antd/es/typography/Paragraph';
import dayjs from 'dayjs';
import { Fragment, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import FilterFilled from 'src/assets/icons/filter-filled.png';
import Filter from 'src/assets/icons/filter.png';
import { RangeDate } from 'src/components/v1/Date';
import Icon from 'src/components/v2/Icon';
import {
  TicketCategoryFilterSelect,
  DepartmentTreeSelect,
  LeaveStatusSelect,
  ShortEmployeeGroupByDepartmentMultipleSelect
} from 'src/components/v2/Select';
import urlPath from 'src/constants/urlPath';
import { TicketContext } from 'src/contexts/ticket.context';
import { formatDate } from 'src/utils/utils';
import './ticketFilterPopover.scss';

const { Text, Title } = Typography;

export default function TicketFilterPopover() {
  const { t } = useTranslation();
  const ticketContext = useContext(TicketContext);
  const { pathname } = useLocation();
  const ticketMatch = pathname.includes(urlPath.ticketV2.location);
  const hrMatch = pathname.includes(urlPath.hrConfirmV2.location);
  const hrLeaveTicketMatch = pathname.includes(urlPath.hrConfirmLeaveTicketV2.location);
  const [open, setOpen] = useState<boolean>(false);
  const [departmentLabel, setDepartmentLabel] = useState<string | undefined>(undefined);
  const [leaveTypeLabel, setLeaveTypeLabel] = useState<string | undefined>(undefined);
  const [employeesLabel, setEmployeesLabel] = useState<string[] | undefined>(undefined);

  const content = () => {
    return (
      <div className='ticket-filter-content'>
        <Form layout='vertical' labelAlign='left'>
          {(ticketMatch || hrMatch) && (
            <Fragment>
              {ticketMatch && (
                <Form.Item label='Danh mục phiếu'>
                  <TicketCategoryFilterSelect
                    allowClear
                    placeholder={t('placeholder.filter.ticketCategory').toString()}
                    onChange={(data) => {
                      ticketContext.setTicketCategoryId(data ? JSON.parse(data).id : undefined);
                    }}
                  />
                </Form.Item>
              )}
              <Form.Item label={ticketMatch ? 'Bộ phận xử lý' : 'Bộ phận tạo phiếu'}>
                <DepartmentTreeSelect
                  allowClear
                  value={ticketContext.departmentId}
                  onChange={(value, labelList) => {
                    ticketMatch && ticketContext.setProcessingDepartmentId(Number(value) || undefined);
                    hrMatch && ticketContext.setDepartmentId(Number(value) || undefined);
                    hrMatch && setDepartmentLabel(labelList[0] as string);
                  }}
                />
              </Form.Item>
              {hrMatch && (
                <>
                  <Form.Item label={'Người phê duyệt'}>
                    <ShortEmployeeGroupByDepartmentMultipleSelect
                      maxTagCount={2}
                      onChange={(values: string | string[]) => {
                        const newValue = (values as string[]).map((item) => JSON.parse(item).id);
                        hrMatch && ticketContext.setSequenceApprovalId(newValue || undefined);
                        hrMatch &&
                          setEmployeesLabel((values as string[]).map((item) => JSON.parse(item).fullName) || undefined);
                      }}
                    />
                  </Form.Item>
                  {hrLeaveTicketMatch && (
                    <Form.Item label={'Loại phép'}>
                      <LeaveStatusSelect
                        value={ticketContext.leaveTypeId ? String(ticketContext.leaveTypeId) : undefined}
                        onChange={(data, option) => {
                          const convertData = data ? JSON.parse(data) : undefined;
                          ticketContext.setLeaveTypeId(convertData?.id);
                          setLeaveTypeLabel(convertData?.name);
                        }}
                      />
                    </Form.Item>
                  )}
                </>
              )}
            </Fragment>
          )}
          <Form.Item label='Ngày tạo'>
            <RangeDate
              allowClear={false}
              style={{ width: '100%' }}
              value={[
                ticketContext.rangeDate.fromDate ? dayjs(ticketContext.rangeDate.fromDate) : null,
                ticketContext.rangeDate.toDate ? dayjs(ticketContext.rangeDate.toDate) : null
              ]}
              onChange={(value) => {
                ticketContext.setRangeDate({
                  fromDate: (value && value[0]?.toDate()) ?? undefined,
                  toDate: (value && value[1]?.toDate()) ?? undefined
                });
              }}
            />
          </Form.Item>
        </Form>
        <Flex justify='end'>
          <Button type='default' onClick={() => setOpen(false)}>
            Đóng
          </Button>
        </Flex>
      </div>
    );
  };

  return (
    <Popover
      id='ticket-filter-popover'
      content={content}
      title={
        <div>
          <Title level={5}>Lọc</Title>
          <Divider style={{ margin: '0 4px' }} />
        </div>
      }
      placement='bottomRight'
      trigger='click'
      open={open}
      overlayStyle={{ width: '400px' }}
      onOpenChange={() => setOpen(true)}
    >
      <Flex
        className='filter-icon'
        justify='end'
        gap={10}
        style={{
          width: '100%'
        }}
      >
        <Flex
          vertical
          style={{
            width: '100%'
          }}
          align='end'
        >
          <Paragraph
            style={{
              width: '80%',
              textAlign: 'end',
              minWidth: '200px'
            }}
            ellipsis={{
              rows: 1,
              tooltip: {
                title: `${departmentLabel ? departmentLabel + '; ' : ''}${
                  leaveTypeLabel ? leaveTypeLabel + '; ' : ''
                }${formatDate(ticketContext.rangeDate.fromDate)} - ${formatDate(ticketContext.rangeDate.toDate)}`
              }
            }}
          >
            {hrMatch && !!departmentLabel && <Text className='filter-fields'>{`${departmentLabel}; `}</Text>}
            {hrMatch && !!leaveTypeLabel && <Text className='filter-fields'>{`${leaveTypeLabel}; `}</Text>}
            <Text className='filter-fields'>
              {`${formatDate(ticketContext.rangeDate.fromDate)} - ${formatDate(ticketContext.rangeDate.toDate)}`}
            </Text>
          </Paragraph>
          {hrMatch && !!employeesLabel && employeesLabel?.length > 0 && (
            <Paragraph
              style={{
                width: '80%',
                textAlign: 'end'
              }}
              ellipsis={{
                rows: 1,
                tooltip: {
                  title: employeesLabel?.join(', ')
                }
              }}
            >
              <Text className='filter-fields'>{`${employeesLabel?.join(', ')}`}</Text>
            </Paragraph>
          )}
        </Flex>
        <Icon url={ticketContext.rangeDate ? FilterFilled : Filter} size={20} titleTooltip='Bộ lọc' />
      </Flex>
    </Popover>
  );
}
