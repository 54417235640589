import { FlatItem } from 'src/types/elements.type';
import { IWorkDescription, WorkDescriptionAdd } from 'src/types/master_data/workDescription.type';
import { PagedList } from 'src/types/pagedList.type';
import { http } from 'src/utils/http';

const controller = 'master-data/work-descriptions';

export const getWorkDescriptions = async (
  searchCondition: string | undefined,
  page: number,
  pageSize: number,
  orderBy?: string
) => {
  return await http.get<PagedList<IWorkDescription>>(`${controller}`, {
    params: {
      name: searchCondition,
      page,
      pageSize,
      orderBy
    }
  });
};

export const getWorkDescription = async (id: number) => {
  return http.get<IWorkDescription>(`${controller}/${id}`);
};

export const addWorkDescription = async (workDescription: WorkDescriptionAdd) => {
  return http.post<IWorkDescription>(controller, workDescription);
};

export const updateWorkDescription = async (id: number, workDescription: IWorkDescription) => {
  return await http.put<IWorkDescription[]>(`${controller}/${id}`, workDescription);
};

export const deleteWorkDescription = async (id: number) => {
  return await http.delete(`${controller}/${id}`);
};

export const getWorkDescriptionsDropdown = async () => {
  return await http.get<FlatItem[]>(`${controller}/dropdown`);
};
