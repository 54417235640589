import { LeftOutlined } from '@ant-design/icons';
import { useQuery } from '@tanstack/react-query';
import { Button, Col, Flex, Modal, Row, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getTicketCategoryGroupsDropdown } from 'src/apis/helpdesk/ticketCategoryGroup.api';
import { IssueIcon, NewIcon } from 'src/assets/icons';
import CustomCard from 'src/components/v2/Card';
import TicketCategoryGroupList from 'src/components/v2/List/TicketCategoryGroupList';
import TicketCategoryList from 'src/components/v2/List/TicketCategoryList';
import SlideShow from 'src/components/v2/SlideShow';
import { ILinkId } from 'src/constants/linkId.enum';
import { TicketTypeEn } from 'src/constants/ticketType';
import urlPath from 'src/constants/urlPath';
import type { TreeItem } from 'src/types/elements.type';
import type { TicketCategoryToRaise } from 'src/types/helpdesk/ticketCategory.type';
import { buildHierarchySelect } from 'src/utils/collection';
import './RaiseTicket.scss';
const { Text } = Typography;

type Props = {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

type DataType = { data: TreeItem[] | undefined; title?: string };

export default function RaiseTicketModal({ isOpen, setIsOpen }: Props) {
  const navigate = useNavigate();
  const [ticketTypeId, setTicketTypeId] = useState<TicketTypeEn>();
  const [ticketCategoryGroupId, setTicketCategoryGroupId] = useState<number | string>();
  const [lastTitle, setLastTitle] = useState<string>('');
  const [ticketCategoryGroups, setTicketCategoryGroups] = useState<DataType[]>([{ data: [] }]);
  const current = ticketCategoryGroups[ticketCategoryGroups.length - 1];

  const ticketCategoryGroupsDropdownQuery = useQuery({
    queryKey: ['ticketCategoryGroupsDropdown', ticketTypeId],
    queryFn: () => getTicketCategoryGroupsDropdown(ticketTypeId),
    staleTime: 60 * 1000,
    enabled: !!ticketTypeId
  });

  useEffect(() => {
    if (ticketCategoryGroupsDropdownQuery.data) {
      const hierarchyDepartments = buildHierarchySelect(ticketCategoryGroupsDropdownQuery.data?.data);
      setTicketCategoryGroups([
        {
          data: hierarchyDepartments,
          title: ticketTypeId === TicketTypeEn.New ? 'Activities' : 'Sự cố, vấn đề phát sinh'
        }
      ]);
    }
  }, [ticketCategoryGroupsDropdownQuery.data, ticketTypeId]);

  const handleChangeTicketCategory = (value: string) => {
    const valueParse: TicketCategoryToRaise = JSON.parse(value);
    const ticketCategoryId = valueParse.id;
    const formId = valueParse.formId;
    const useForm = valueParse.useForm;

    setIsOpen(false);
    const url = `${urlPath.ticketAddV2.location}?${ILinkId.ticketCategoryGroupId}=${ticketCategoryGroupId}&&${ILinkId.ticketCategoryId}=${ticketCategoryId}&${ILinkId.formId}=${formId}&${ILinkId.useForm}=${useForm}`;
    navigate(url);
  };

  return (
    <Modal
      className='raise-ticket-modal'
      open={isOpen}
      maskClosable={false}
      title={
        <Flex align='center'>
          {ticketTypeId && (
            <Button
              type='text'
              size='small'
              className='back-btn'
              icon={<LeftOutlined />}
              onClick={() => {
                if (ticketCategoryGroupId) {
                  setTicketCategoryGroupId(undefined);
                  setLastTitle('');
                } else if (ticketCategoryGroups.length > 1) {
                  setTicketCategoryGroups((prev) => [...prev.slice(0, prev.length - 1)]);
                } else {
                  setTicketTypeId(undefined);
                }
              }}
            />
          )}
          <Text className='title'>
            {ticketCategoryGroupId ? lastTitle : ticketTypeId ? current.title : 'Chọn loại ticket'}
          </Text>
        </Flex>
      }
      zIndex={1050}
      footer={null}
      onCancel={() => setIsOpen(false)}
    >
      {ticketCategoryGroupId ? (
        <SlideShow>
          <TicketCategoryList
            onChange={handleChangeTicketCategory}
            ticketTypeId={ticketTypeId}
            ticketCategoryGroupId={ticketCategoryGroupId}
          />
        </SlideShow>
      ) : ticketTypeId ? (
        <SlideShow>
          <TicketCategoryGroupList
            onChange={(item) => {
              const isParent = !!item.children && item.children.length > 0;
              if (isParent) {
                setTicketCategoryGroups((prev) => [...prev, { data: item?.children, title: item?.title }]);
              } else {
                setTicketCategoryGroupId(item?.key);
                setLastTitle(item?.title);
              }
            }}
            items={current?.data}
            loading={ticketCategoryGroupsDropdownQuery.isFetching}
          />
        </SlideShow>
      ) : (
        <Row justify='space-evenly' className='card-row' gutter={[20, 20]}>
          <Col span={24}>
            <CustomCard
              image={NewIcon}
              imageSize={64}
              title='Activities'
              description='Áp dụng cho các vấn đề có trong kế hoạch'
              layout='horizontal'
              action={null}
              size='medium'
              style={{ width: '100%' }}
              hoverable
              onClick={() => {
                setTicketTypeId(TicketTypeEn.New);
              }}
            />
          </Col>
          <Col span={24}>
            <CustomCard
              image={IssueIcon}
              imageSize={64}
              title='Sự cố, vấn đề phát sinh'
              description='Áp dụng khi bạn gặp sự cố hoặc vấn đề phát sinh ngoài kế hoạch'
              layout='horizontal'
              action={null}
              size='medium'
              style={{ width: '100%' }}
              hoverable
              onClick={() => {
                setTicketTypeId(TicketTypeEn.Issue);
              }}
            />
          </Col>
        </Row>
      )}
    </Modal>
  );
}
