import { Select } from 'antd';
import { DefaultOptionType } from 'antd/es/select';

interface Props {
  value?: string;
  onChange: ((value: string, option: DefaultOptionType | DefaultOptionType[]) => void) | undefined;
}

export default function SequenceStatusSelect({ value, onChange }: Props) {
  return (
    <Select
      value={value ?? undefined}
      style={{ width: 200 }}
      allowClear
      onChange={onChange}
      // defaultValue='0,1'
      options={[
        // { value: '0,1', label: 'Tất cả' },
        { value: '0', label: 'Khóa' },
        { value: '1', label: 'Kích hoạt' }
      ]}
      placeholder='Chọn trạng thái'
    />
  );
}
