import { Button, Result } from 'antd';
import { useNavigate } from 'react-router-dom';
import urlPath from 'src/constants/urlPath';

export default function InternalServer() {
  const navigate = useNavigate();
  return (
    <Result
      status='500'
      title='500'
      subTitle='Sorry, something went wrong.'
      extra={
        <Button type='primary' onClick={() => navigate(urlPath.signoutOidc.location)}>
          Sign out
        </Button>
      }
    />
  );
}
