import { FlatItem } from 'src/types/elements.type';
import { IRoleUser, RoleUserAdd } from 'src/types/master_data/roleUser.type';
import { PagedList } from 'src/types/pagedList.type';
import { User } from 'src/types/user.type';
import { http } from 'src/utils/http';

const controller = 'master-data/role-users';

export const getRoleUsers = async (sequenceStatus: string, page: number, pageSize: number, orderBy?: string) => {
  return await http.get<PagedList<IRoleUser>>(`${controller}`, {
    params: {
      sequenceStatus,
      page,
      pageSize,
      orderBy
    }
  });
};

export const getRoleUser = async (id: number) => {
  return http.get<IRoleUser>(`${controller}/${id}`);
};

export const addRoleUser = async (roleUser: RoleUserAdd) => {
  return http.post<RoleUserAdd>(controller, roleUser);
};

export const updateRoleUser = async (id: number, roleUser: IRoleUser) => {
  return await http.put(`${controller}/${id}`, roleUser);
};

export const deleteRoleUser = async (id: number) => {
  await http.delete(`${controller}/${id}`);
};

export const getRoleUsersDropdown = async () => {
  return http.get<FlatItem[]>(`${controller}/dropdown`);
};

export const getRoleUsersFullAccess = async () => {
  return http.get<User>(`${controller}/get-full-access`);
};
