import { useQuery } from '@tanstack/react-query';
import { Select, SelectProps } from 'antd';
import { getPartnerTypeDropdown } from 'src/apis/master_data/partnerType.api';

export default function PartnerTypeSelect({ value, onChange, style, allowClear }: SelectProps) {
  const partnerTypeDropdownQuery = useQuery({
    queryKey: ['partnerTypeDropdown'],
    queryFn: () => getPartnerTypeDropdown(),
    staleTime: 60 * 1000
  });

  return (
    <Select
      allowClear={allowClear}
      value={partnerTypeDropdownQuery.isFetching ? undefined : value}
      style={style}
      onChange={onChange}
      options={partnerTypeDropdownQuery.data?.data.map((data) => ({ value: data.id, label: data.name }))}
      showSearch
      filterOption={(input, option) => (option?.label?.toString() ?? '').toLowerCase()?.includes(input.toLowerCase())}
      loading={partnerTypeDropdownQuery.isFetching}
    />
  );
}
