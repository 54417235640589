import { BiometricDeviceAdd, IBiometricDevice } from 'src/types/master_data/biometricDevice.type';
import { http } from 'src/utils/http';

const controller = 'hr/biometric-devices';

export const getBiometricDevices = async () => {
  return await http.get<IBiometricDevice[]>(controller);
};

export const getBiometricDevice = async (id: number) => {
  return await http.get<IBiometricDevice>(`${controller}/${id}`);
};

export const addBiometricDevice = async (biometricDevice: BiometricDeviceAdd) => {
  return await http.post(controller, biometricDevice);
};

export const updateBiometricDevice = async (id: number, biometricDevice: BiometricDeviceAdd) => {
  return await http.put(`${controller}/${id}`, biometricDevice);
};

export const deleteBiometricDevice = async (id: number) => {
  return await http.delete(`${controller}/${id}`);
};
