import { useQuery } from '@tanstack/react-query';
import { Select } from 'antd';
import { DefaultOptionType } from 'antd/es/select';
import { CSSProperties } from 'react';
import { getWorkDescriptionsDropdown } from 'src/apis/master_data/workDescription.api';

type Props = {
  value?: string[];
  style?: CSSProperties | undefined;
  allowClear: boolean | undefined;
  onChange: ((value: string[], option: DefaultOptionType | DefaultOptionType[]) => void) | undefined;
};

export default function WorkDescriptionSelectV2({ onChange, style, allowClear, value }: Props) {
  const workDescriptionsDropdownV2Query = useQuery({
    queryKey: ['workDescriptionsDropdownV2'],
    queryFn: () => {
      return getWorkDescriptionsDropdown();
    },
    staleTime: 60 * 1000
  });

  return (
    <Select
      allowClear={allowClear}
      value={workDescriptionsDropdownV2Query.isFetching ? undefined : value}
      style={style}
      onChange={onChange}
      mode='multiple'
      options={workDescriptionsDropdownV2Query.data?.data.map((data) => ({
        value: JSON.stringify({
          id: data.id,
          name: data.name
        }),
        label: data.name
      }))}
      showSearch
      filterOption={(input, option) => (option?.label?.toString() ?? '').toLowerCase()?.includes(input.toLowerCase())}
      loading={workDescriptionsDropdownV2Query.isFetching}
    />
  );
}
