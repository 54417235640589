import { HrCategoryEn } from 'src/constants/hrCategory.enum';
import { HrTicketTypeEn } from 'src/constants/hrTicketType.enum';
import { RangeDateType } from 'src/types/date.type';
import { IManpowerSupplyForm } from 'src/types/helpdesk/manpowerSupplyForm.type';
import {
  ApprovalStatus,
  HandleForRefParams,
  ITicket,
  ITicketApproval,
  ITicketConfirm,
  ITicketFile,
  ITicketForm,
  ITicketHandle,
  ITicketMyHandle,
  ITicketShort,
  TicketAdd,
  TicketFileAdd,
  TicketStatus
} from 'src/types/helpdesk/ticket.type';
import { PagedList } from 'src/types/pagedList.type';
import { ITicketParams } from 'src/types/params.type';
import { http, httpV2 } from 'src/utils/http';

const controller = 'helpdesk/tickets';

export const getTickets = async (params: ITicketParams) =>
  await http.get<PagedList<ITicket>>(controller, {
    params: params
  });

export const getTicketsApproval = async (params: ITicketParams) =>
  await http.get<PagedList<ITicketApproval>>(`${controller}/waiting-approval`, {
    params: params
  });

export const getTicketsConfirm = async (params: ITicketParams) =>
  await http.get<PagedList<ITicketConfirm>>(`${controller}/waiting-confirm`, {
    params: params
  });

export const getTicketsConfirmV2 = async (params: ITicketParams) =>
  await httpV2.get<PagedList<ITicketConfirm>>(`${controller}/waiting-confirm`, {
    params: params
  });

export const getTicketsHandle = async (params: ITicketParams) =>
  await http.get<PagedList<ITicketHandle>>(`${controller}/waiting-handle`, {
    params: params
  });

export const getTicketsHandleHistory = async (params: ITicketParams) =>
  await httpV2.get<PagedList<ITicketHandle>>(`${controller}/handle-history`, {
    params: params
  });

export const getTicketsHandleV2 = async (params: ITicketParams) =>
  await httpV2.get<PagedList<ITicketHandle>>(`${controller}/waiting-handle`, {
    params: params
  });

export const getTicketsMyHandle = async (params: ITicketParams) =>
  await http.get<PagedList<ITicketMyHandle>>(`${controller}/my-handle`, {
    params: params
  });

export const getTicketsMyHandleHistory = async (params: ITicketParams) =>
  await http.get<PagedList<ITicketMyHandle>>(`${controller}/my-handle-history`, {
    params: params
  });

export const getTicket = async (id: string) => await http.get<ITicket>(`${controller}/${id}`);

export const getTicketsDropdown = async (ticketStatusId: TicketStatus, hrTicketCategoryCode: HrCategoryEn) =>
  await httpV2.get<ITicketShort[]>(`${controller}/dropdown`, {
    params: {
      ticketStatusId,
      hrTicketCategoryCode
    }
  });

export const getTicketFormsByIds = async (id?: string[]) =>
  await httpV2.get<ITicketForm[]>(`${controller}/ticket-forms?sequenceIds=${id}`);

export const getTicketsMyHandleForRefExpired = async (
  userName?: string,
  employeeId?: number,
  searchCondition?: string
) =>
  await http.get<IManpowerSupplyForm[]>(`${controller}/my-handle-for-ref-expired`, {
    params: {
      userName,
      employeeId,
      searchCondition
    }
  });

export const getTicketsMyHandleForRef = async (
  params: HandleForRefParams,
  employeeId?: number,
  searchCondition?: string
) =>
  await http.get<PagedList<ITicket>>(`${controller}/my-handle-for-ref`, {
    params: {
      employeeId,
      searchCondition,
      page: params.page,
      pageSize: params.pageSize,
      orderBy: params.orderBy
    }
  });

export const addTicket = async (ticket: TicketAdd) => await http.post<ITicket>(controller, ticket);

export const updateTicket = async (id: string, ticket: TicketAdd) => await http.put(`${controller}/${id}`, ticket);

export const approvalTicket = async (
  id: string,
  approvalStatus?: ApprovalStatus,
  note?: string,
  reasonId?: number,
  stepWaiting?: number
) =>
  await httpV2.put(`${controller}/approval`, {
    approvalStatus,
    reasonId,
    ticketId: id,
    noteApprove: approvalStatus === ApprovalStatus.Approve ? note : '',
    noteReject: approvalStatus === ApprovalStatus.Reject ? note : '',
    step: stepWaiting
  });

export const cancelApprovalTickets = async (id: string, step: number) => {
  await http.put(`${controller}/cancel-approval/${id}`, {
    step
  });
};

export const acceptTicket = async (
  id: string,
  approvalStatus?: ApprovalStatus,
  reasonId?: number,
  noteReject?: string
) => {
  await httpV2.put(`${controller}/confirm`, {
    ticketId: id,
    approvalStatus,
    reasonId,
    noteReject
  });
};

export const deleteTicket = async (id: string) => await http.delete(`${controller}/${id}`);

export const cancelTicket = async (id: string) => await http.put(`${controller}/cancel/${id}`);

export const handleTicket = async (ticketId: string, handlerId?: number, ticketSolutionId?: number) =>
  await http.put(`${controller}/assign-to/${ticketId}`, {
    handlerId,
    ticketSolutionId
  });

export const rejectHandle = async (id?: string, employeeId?: number, reasonId?: number, noteReject?: string) =>
  await http.put(`${controller}/reject-assign/${id}`, {
    handlerId: employeeId,
    reasonId: reasonId,
    noteReject: noteReject
  });

export const completedHandle = async (id?: string, note?: string) =>
  await http.put(`${controller}/handle-complete/${id}`, {
    note
  });

export const confirmComplete = async (id: string, isConfirm?: boolean, reasonId?: number, message?: string) =>
  await httpV2.put(`${controller}/confirm-complete/${id}`, {
    isConfirm,
    reasonId,
    message
  });

export const uploadFile = async (formData: FormData) => {
  return await http.post<ITicketFile[]>(`${controller}/upload`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
};

export const getTicketFile = async (ticketFile: TicketFileAdd) => {
  return await http.get(`${controller}/get-file`, {
    params: {
      fileUrl: ticketFile.filePath,
      fileName: ticketFile.originalName
    },
    responseType: 'blob'
  });
};

export const deleteFile = async (fileUrls: string[]) =>
  await http.delete(`${controller}/delete-file`, {
    data: fileUrls
  });

export const getMyStatusCount = async (params: RangeDateType) => {
  return await httpV2.get(`${controller}/count-my-ticket`, {
    params: params
  });
};

export const getHandleStatusCount = async (params: RangeDateType) => {
  return await httpV2.get(`${controller}/summary-count`, {
    params: params
  });
};

export const getWaitingProcessingTicketCount = async (params: RangeDateType) => {
  return await httpV2.get(`${controller}/waiting-processing-count`, { params: params });
};

export const getRemarkByTicketId = async (id: string) => {
  return await http.get(`${controller}/get-remark-by-id/${id}`);
};

export const checkExistRefTicket = async (hrTicketType: HrTicketTypeEn, manpowerSupplyId: number) => {
  return await http.get(`${controller}/check-exist-ref-ticket`, { params: { hrTicketType, manpowerSupplyId } });
};

export const handleTicketV2 = async (
  ticketId: string,
  handlerId?: number,
  ticketSolutionId?: number,
  remark?: string
) =>
  await httpV2.put(`${controller}/assign-to/${ticketId}`, {
    handlerId,
    ticketSolutionId,
    remark
  });
