import { useQuery } from '@tanstack/react-query';
import { Select } from 'antd';
import { getTicketCategoriesAvailableToRaiseDropdown } from 'src/apis/helpdesk/ticketCategory.api';
import { SelectProps } from 'src/types/elements.type';

type Props = SelectProps & {
  onChange?: ((value: string) => void) | undefined;
  ticketTypeId?: number;
};

export default function TicketCategoryAvailableToRaiseSelect({
  value,
  onChange,
  style,
  allowClear = true,
  ticketTypeId
}: Props) {
  const ticketCategoriesAvailableToRaiseSelectQuery = useQuery({
    queryKey: ['ticketCategoriesAvailableToRaiseDropdown', ticketTypeId],
    queryFn: () => getTicketCategoriesAvailableToRaiseDropdown(ticketTypeId),
    staleTime: 60 * 1000
  });

  return (
    <Select
      allowClear={allowClear}
      value={ticketCategoriesAvailableToRaiseSelectQuery.isFetching ? value : undefined}
      style={style}
      onChange={onChange}
      maxTagCount={4}
      options={ticketCategoriesAvailableToRaiseSelectQuery.data?.data.map((item) => {
        return {
          value: item.id,
          label: item.name
        };
      })}
      showSearch
      filterOption={(input, option) => (option?.label?.toString() ?? '').toLowerCase()?.includes(input.toLowerCase())}
      loading={ticketCategoriesAvailableToRaiseSelectQuery.isFetching}
    />
  );
}
