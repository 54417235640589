import { useQuery } from '@tanstack/react-query';
import { Flex, Select, Typography } from 'antd';
import React from 'react';
import { getTicketsDropdown } from 'src/apis/helpdesk/ticket.api';
import { HrCategoryEn } from 'src/constants/hrCategory.enum';
import { TicketStatus } from 'src/types/helpdesk/ticket.type';
import { formatDate } from 'src/utils/utils';

const { Text } = Typography;

interface Props {
  hrCategory: HrCategoryEn;
  className?: string;
  value?: string | string[];
  style?: React.CSSProperties | undefined;
  allowClear?: boolean | undefined;
  mode?: 'multiple' | 'tags';
  maxTagCount?: number | 'responsive';
  onChange?: (value: string | string[]) => void;
  disabled?: boolean;
}

export default function TicketSelect({
  hrCategory,
  className,
  value,
  style,
  allowClear,
  mode,
  maxTagCount,
  onChange,
  disabled
}: Props) {
  const ticketsDropdownQuery = useQuery({
    queryKey: ['ticketsDropdown', hrCategory],
    queryFn: () => getTicketsDropdown(TicketStatus.Processing, hrCategory),
    staleTime: 60 * 1000,
    enabled: !!hrCategory
  });

  return (
    <Select
      className={className}
      allowClear={allowClear}
      value={ticketsDropdownQuery.isFetching ? undefined : value}
      style={{ height: 'auto', ...style }}
      onChange={onChange}
      mode={mode}
      disabled={disabled}
      maxTagCount={maxTagCount}
      options={ticketsDropdownQuery.data?.data?.map((item) => ({
        value: item.id,
        label: (
          <Flex vertical>
            <Text>
              {item.ticketCode} - {item.ticketCategory?.name}
            </Text>
            <Text type='secondary'>
              {`Ngày tạo: ${formatDate(item.createdDate)}`} - {`Người tạo: ${item.createdByObj?.name}`}
            </Text>
          </Flex>
        )
      }))}
      showSearch
      loading={ticketsDropdownQuery.isFetching}
    />
  );
}
