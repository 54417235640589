import { useQuery } from '@tanstack/react-query';
import { Select } from 'antd';
import { getProjectsDropdown } from 'src/apis/hr_ticket/projectCode.api';

import { FlatItem } from 'src/types/elements.type';

interface Props {
  value?: string[];
  placeholder?: string;
  style?: React.CSSProperties | undefined;
  allowClear?: boolean | undefined;
  onChange?: (value: string[]) => void;
  mode?: 'multiple' | 'tags';
  maxTagCount?: number | 'responsive';
  disabled?: boolean;
  filterItems?: string[];
}

export default function ProjectMultipleSelect({
  value,
  onChange,
  style,
  allowClear = true,
  mode = 'tags',
  maxTagCount,
  disabled,
  placeholder,
  filterItems = []
}: Props) {
  const projectsDropdownQuery = useQuery({
    queryKey: ['projectsDropdown'],
    queryFn: () => getProjectsDropdown(),
    staleTime: 60 * 1000
  });

  return (
    <Select
      disabled={disabled}
      mode={mode}
      maxTagCount={maxTagCount}
      allowClear={allowClear}
      value={projectsDropdownQuery.isFetching ? undefined : value}
      style={style}
      onChange={onChange}
      options={projectsDropdownQuery.data?.data
        ?.filter((x) => filterItems?.includes(x.code))
        .sort((a: FlatItem, b: FlatItem) => a.code?.localeCompare(b.code))
        .map((data) => ({ value: data.code, label: data.code }))}
      showSearch
      filterOption={(input, option) => (option?.label?.toString() ?? '').toLowerCase()?.includes(input.toLowerCase())}
      placeholder={placeholder}
      loading={projectsDropdownQuery.isFetching}
    />
  );
}
