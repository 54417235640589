import { useQuery } from '@tanstack/react-query';
import { Select, SelectProps } from 'antd';
import { useEffect, useState } from 'react';
import { getUsernamesByDepartmentDropdown } from 'src/apis/master_data/employee.api';

type Props = SelectProps & {
  departmentId?: number | undefined;
};

interface OptionType {
  label: React.ReactNode;
  value?: string | number | null;
}

interface OptionGroupType {
  label: React.ReactNode;
  options?: Omit<OptionType, 'children'>[];
}

export default function UsernameWithDepartmentSelect({
  value,
  departmentId,
  onChange,
  style,
  allowClear = true,
  disabled
}: Props) {
  const [optionsGroup, setOptionsGroup] = useState<OptionGroupType[]>([]);

  const userNamesByDepartmentDropdownQuery = useQuery({
    queryKey: ['userNamesByDepartmentDropdown', departmentId],
    queryFn: () => getUsernamesByDepartmentDropdown(departmentId),
    staleTime: 60 * 1000
  });

  useEffect(() => {
    if (userNamesByDepartmentDropdownQuery.data?.data) {
      const optionsGroup: OptionGroupType[] = [];

      userNamesByDepartmentDropdownQuery.data?.data.forEach((item) => {
        const index = optionsGroup.findIndex((optionGroup) => optionGroup.label === item?.department?.name);

        if (index === -1) {
          optionsGroup.push({
            label: item.department?.name,
            options: [
              {
                label: `${item.userName} - ${item.fullName}`,
                value: JSON.stringify(item)
              }
            ]
          });
        } else {
          optionsGroup[index].options?.push({
            label: `${item.userName} - ${item.fullName}`,
            value: JSON.stringify(item)
          });
        }
      });

      setOptionsGroup(optionsGroup);
    }
  }, [userNamesByDepartmentDropdownQuery.data?.data]);

  return (
    <Select
      allowClear={allowClear}
      disabled={disabled}
      value={userNamesByDepartmentDropdownQuery.isFetching ? undefined : value}
      style={style}
      onChange={onChange}
      options={optionsGroup}
      showSearch
      filterOption={(input, option) => (option?.label?.toString() ?? '').toLowerCase()?.includes(input.toLowerCase())}
      loading={userNamesByDepartmentDropdownQuery.isFetching}
    />
  );
}
