import { useQuery } from '@tanstack/react-query';
import { Select } from 'antd';
import { getTemplatesDropdown } from 'src/apis/master_data/template.api';
import { SelectProps } from 'src/types/elements.type';

type TemplateSelectProps = SelectProps & {
  departmentId?: number | number[];
  templateType?: number;
};

export default function TemplateSelect({
  value,
  onChange,
  style,
  allowClear,
  status,
  departmentId,
  templateType,
  disabled
}: TemplateSelectProps) {
  const templateDropdownQuery = useQuery({
    queryKey: ['templateDropdown', departmentId, templateType],
    queryFn: () => getTemplatesDropdown(departmentId, templateType),
    staleTime: 60 * 1000
  });

  return (
    <Select
      disabled={disabled}
      status={status}
      allowClear={allowClear}
      value={templateDropdownQuery.isFetching ? undefined : value}
      style={style}
      onChange={onChange}
      options={templateDropdownQuery.data?.data.map((data) => ({ value: data.id, label: data.name }))}
      showSearch
      filterOption={(input, option) => (option?.label?.toString() ?? '').toLowerCase()?.includes(input.toLowerCase())}
      loading={templateDropdownQuery.isFetching}
    />
  );
}
