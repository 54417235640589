import { FlatItem } from 'src/types/elements.type';
import { IAccountingAccountType, AccountingAccountTypeAdd } from 'src/types/master_data/accountingAccountType.type';
import { PagedList } from 'src/types/pagedList.type';
import { http } from 'src/utils/http';

const controller = 'master-data/accounting-account-types';

export const getAccountingAccountTypes = async (
  sequenceStatus: string,
  page: number,
  pageSize: number,
  orderBy?: string
) => {
  return await http.get<PagedList<IAccountingAccountType>>(`${controller}`, {
    params: {
      sequenceStatus,
      page,
      pageSize,
      orderBy
    }
  });
};

export const getAccountingAccountType = async (id: number) => {
  return http.get<IAccountingAccountType>(`${controller}/${id}`);
};

export const addAccountingAccountType = async (accountingAccountType: AccountingAccountTypeAdd) => {
  return http.post<AccountingAccountTypeAdd>(controller, accountingAccountType);
};

export const updateAccountingAccountType = async (id: number, accountingAccountType: IAccountingAccountType) => {
  return await http.put<IAccountingAccountType[]>(`${controller}/${id}`, accountingAccountType);
};

export const deleteAccountingAccountType = async (id: number) => {
  await http.delete(`${controller}/${id}`);
};

export const getAccountingAccountTypesDropdown = async () => {
  return http.get<FlatItem[]>(`${controller}/dropdown`);
};
