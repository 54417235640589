import { useQuery } from '@tanstack/react-query';
import { Select } from 'antd';
import { DefaultOptionType } from 'antd/es/select';
import { getPositionsDropdown } from 'src/apis/master_data/position.api';
import { SelectProps } from 'src/types/elements.type';

type Props = Omit<SelectProps, 'value' | 'onChange'> & {
  value?: string[];
  onChange?: ((value: string[], option: DefaultOptionType | DefaultOptionType[]) => void) | undefined;
};

export default function PositionMultipleSelect({ onChange, style, allowClear, value, disabled }: Props) {
  const positionsDropdownQuery = useQuery({
    queryKey: ['positionsDropdown'],
    queryFn: () => {
      return getPositionsDropdown();
    },
    staleTime: 60 * 1000
  });

  return (
    <Select
      mode='multiple'
      allowClear={allowClear}
      disabled={disabled}
      value={positionsDropdownQuery.isFetching ? undefined : value}
      style={style}
      onChange={onChange}
      options={positionsDropdownQuery.data?.data.map((data) => ({ value: data.id, label: data.name }))}
      showSearch
      filterOption={(input, option) => (option?.label?.toString() ?? '').toLowerCase()?.includes(input.toLowerCase())}
      loading={positionsDropdownQuery.isFetching}
    />
  );
}
