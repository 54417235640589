import { FlatItem } from 'src/types/elements.type';
import { PagedList } from 'src/types/pagedList.type';
import { ITicketType, TicketTypeAdd } from 'src/types/helpdesk/ticketType.type';
import { http } from 'src/utils/http';

const controller = 'helpdesk/ticket-types';

export const getTicketTypes = async (sequenceStatus: string, page: number, pageSize: number, orderBy?: string) =>
  await http.get<PagedList<ITicketType>>(controller, {
    params: {
      sequenceStatus,
      page,
      pageSize,
      orderBy
    }
  });

export const getTicketType = async (id: number) => await http.get<ITicketType>(`${controller}/${id}`);

export const getTicketTypesDropdown = async () => {
  return await http.get<FlatItem[]>(`${controller}/dropdown`);
};

export const addTicketType = async (ticketType: TicketTypeAdd) => await http.post(controller, ticketType);

export const updateTicketType = async (id: number, ticketType: ITicketType) =>
  await http.put(`${controller}/${id}`, ticketType);

export const deleteTicketType = async (id: number) => await http.delete(`${controller}/${id}`);
