import { useQuery } from '@tanstack/react-query';
import { Select } from 'antd';
import { ReactNode } from 'react';
import { getEmployeesDropdown } from 'src/apis/master_data/employee.api';

interface Props {
  value?: number;
  style?: React.CSSProperties | undefined;
  allowClear?: boolean | undefined;
  onChange?: (value: number) => void;
  departmentId?: number;
  placeholder?: ReactNode;
}

export default function EmployeeSelect({
  value,
  style,
  allowClear = true,
  onChange,
  departmentId,
  placeholder
}: Props) {
  const employeesDropdownQuery = useQuery({
    queryKey: ['employeesDropdown', departmentId],
    queryFn: () => getEmployeesDropdown(departmentId),
    staleTime: 60 * 1000
  });

  return (
    <Select
      allowClear={allowClear}
      value={employeesDropdownQuery.isFetching ? undefined : value}
      style={style}
      placeholder={placeholder}
      onChange={onChange}
      options={employeesDropdownQuery.data?.data.map((data) => ({
        value: data.id,
        label: `${data.code} - ${data.fullName}`
      }))}
      showSearch
      filterOption={(input, option) => (option?.label?.toString() ?? '').toLowerCase()?.includes(input.toLowerCase())}
      loading={employeesDropdownQuery.isFetching}
    />
  );
}
