import { useQuery } from '@tanstack/react-query';
import { Flex, Select, SelectProps, Typography } from 'antd';
import { useContext } from 'react';
import { getTicketsMyHandleForRefExpired } from 'src/apis/helpdesk/ticket.api';
import PriorityLevelTag from 'src/components/v2/Tag/PriorityLevelTag';
import { AppContext } from 'src/contexts/app.context';
import { FlatItem } from 'src/types/elements.type';
import { IManpowerSupplyFormDetail } from 'src/types/helpdesk/manpowerSupplyForm.type';
import { formatDate } from 'src/utils/utils';

const { Text } = Typography;

type Props = SelectProps & {
  userName?: string;
};

export default function ManpowerSupplySelect({ userName, value, onChange, style, allowClear = true, disabled }: Props) {
  const { userInfo } = useContext(AppContext);

  const manpowerSuppliesDropdownQuery = useQuery({
    queryKey: ['manpowerSuppliesDropdown', userName, userInfo?.id],
    queryFn: () => getTicketsMyHandleForRefExpired(userName, userInfo?.id),
    staleTime: 60 * 1000,
    enabled: !!userName || !!userInfo?.id
  });

  const handleGetMinDate = (details: IManpowerSupplyFormDetail[]) => {
    return details
      ?.map((x) => x.fromDate)
      ?.reduce((a, b) => {
        return a < b ? a : b;
      });
  };

  const handleGetMaxDate = (details: IManpowerSupplyFormDetail[]) => {
    return details
      ?.map((x) => x.toDate)
      ?.reduce((a, b) => {
        return a > b ? a : b;
      });
  };

  return (
    <Select
      allowClear={allowClear}
      value={value}
      style={{ height: 'auto', ...style }}
      onChange={onChange}
      disabled={disabled}
      options={manpowerSuppliesDropdownQuery.data?.data.map((data) => ({
        value: JSON.stringify(data),
        label: (
          <div className='manpower-ref-card'>
            <Flex justify='space-between' align='center'>
              <Flex vertical>
                <Text strong>ID Phiếu: {data?.id}</Text>
                <Flex
                  vertical
                  gap={6}
                  style={{
                    marginTop: '10px'
                  }}
                >
                  {data.projectCode}
                  {data?.details && (
                    <Text>
                      <Text type='secondary'>Kéo dài từ ngày: </Text>
                      {formatDate(handleGetMinDate(data.details))} <Text type='secondary'>đến </Text>{' '}
                      {formatDate(handleGetMaxDate(data.details))}
                    </Text>
                  )}
                </Flex>
              </Flex>
              <PriorityLevelTag priorityLevel={data?.priorityLevel as FlatItem} />
            </Flex>
          </div>
        )
      }))}
      showSearch
      filterOption={(input, option) => (option?.label?.toString() ?? '').toLowerCase()?.includes(input.toLowerCase())}
      loading={manpowerSuppliesDropdownQuery.isFetching}
    />
  );
}
