import { useQuery } from '@tanstack/react-query';
import { Select } from 'antd';
import { getReasonsDropdown } from 'src/apis/helpdesk/reason.api';

interface Props {
  value?: number;
  style?: React.CSSProperties | undefined;
  allowClear?: boolean;
  onChange?: (
    value: number,
    option?:
      | {
          value: number;
          label: string;
        }
      | {
          value: number;
          label: string;
        }[]
  ) => void;
}

export default function ReasonSelect({ value, onChange, style, allowClear }: Props) {
  const reasonsDropdown = useQuery({
    queryKey: ['reasonsDropdown'],
    queryFn: () => getReasonsDropdown(),
    staleTime: 60 * 1000
  });

  return (
    <Select
      allowClear={allowClear}
      value={reasonsDropdown.isFetching ? undefined : value}
      style={style}
      onChange={onChange}
      options={reasonsDropdown.data?.data.map((data) => ({ value: data.id, label: data.name }))}
      showSearch
      filterOption={(input, option) => (option?.label?.toString() ?? '').toLowerCase()?.includes(input.toLowerCase())}
      loading={reasonsDropdown.isFetching}
    />
  );
}
