import React, { CSSProperties } from 'react';
import './icon.scss';
import { Skeleton, Tooltip } from 'antd';
import { TooltipPlacement } from 'antd/es/tooltip';

interface Props {
  url: string | undefined;
  size?: number | string;
  titleTooltip?: string;
  colorTooltip?: string;
  arrowTooltip?: boolean;
  styleTooltip?: React.CSSProperties;
  placementTooltip?: TooltipPlacement;
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  className?: string;
  style?: React.CSSProperties;
  loading?: boolean;
  disable?: boolean;
  open?: boolean;
}

const Icon = ({
  url,
  size = 22,
  titleTooltip,
  colorTooltip = '#4f606b',
  arrowTooltip = true,
  styleTooltip,
  onClick,
  className,
  style,
  loading,
  disable,
  open,
  placementTooltip
}: Props) => {
  const imgStyle: CSSProperties = {
    width: size,
    height: size,
    cursor: 'pointer'
  };
  if (!titleTooltip)
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          ...style
        }}
        onClick={disable ? undefined : onClick}
        className={className}
      >
        <img
          src={url}
          alt={url}
          style={{
            ...imgStyle,
            cursor: disable ? 'not-allowed' : 'pointer',
            opacity: disable ? 0.5 : 1
          }}
        />
      </div>
    );

  return (
    <Tooltip
      title={titleTooltip}
      color={colorTooltip}
      arrow={arrowTooltip}
      open={open}
      overlayInnerStyle={styleTooltip}
      className='custom-icon-tooltip'
      placement={placementTooltip}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          ...style
        }}
        onClick={disable ? undefined : onClick}
        className={className}
      >
        {loading ? (
          <Skeleton.Avatar active shape='circle' size={'large'} />
        ) : (
          <img
            src={url}
            alt={url}
            style={{
              ...imgStyle,
              cursor: disable ? 'not-allowed' : 'pointer',
              opacity: disable ? 0.5 : 1
            }}
          />
        )}
      </div>
    </Tooltip>
  );
};

export default Icon;
