import { useQuery } from '@tanstack/react-query';
import { Select } from 'antd';
import { getTicketCategoryGroupsDropdown } from 'src/apis/helpdesk/ticketCategoryGroup.api';

interface Props {
  value?: number;
  onChange?: (value: number) => void;
  style?: React.CSSProperties | undefined;
  allowClear?: boolean;
  departmentId?: number | undefined;
  currentId?: number | undefined;
  className?: string;
  placeholder?: string;
}

export default function TicketCategoryGroupSelect({
  value,
  onChange,
  style,
  allowClear,
  departmentId,
  currentId,
  className,
  placeholder
}: Props) {
  const ticketCategoryGroupsDropdownQuery = useQuery({
    queryKey: ['TicketCategoryGroupsDropdown', departmentId],
    queryFn: () => getTicketCategoryGroupsDropdown(departmentId)
  });

  return (
    <Select
      allowClear={allowClear}
      value={ticketCategoryGroupsDropdownQuery.isFetching ? undefined : value}
      style={style}
      className={className}
      placeholder={placeholder}
      onChange={onChange}
      options={ticketCategoryGroupsDropdownQuery.data?.data
        .filter((item) => item.id !== currentId)
        .map((data) => ({ value: data.id, label: data.name }))}
      showSearch
      filterOption={(input, option) => (option?.label?.toString() ?? '').toLowerCase()?.includes(input.toLowerCase())}
      loading={ticketCategoryGroupsDropdownQuery.isFetching}
    />
  );
}
