import { SearchOutlined } from '@ant-design/icons';
import { useQuery } from '@tanstack/react-query';
import { Empty, Flex, Input, List, Typography } from 'antd';
import { getTicketCategoriesAvailableToRaiseDropdown } from 'src/apis/helpdesk/ticketCategory.api';
import './TicketCategoryList.scss';
const { Text } = Typography;

type Props = {
  style?: React.CSSProperties | undefined;
  onChange: (value: string) => void;
  ticketTypeId?: number | string;
  ticketCategoryGroupId?: number | string;
};

export default function TicketCategoryList({ onChange, ticketTypeId, ticketCategoryGroupId }: Props) {
  const ticketCategoriesAvailableToRaiseSelectQuery = useQuery({
    queryKey: ['ticketCategoriesAvailableToRaiseDropdown', Number(ticketTypeId), Number(ticketCategoryGroupId)],
    queryFn: () => getTicketCategoriesAvailableToRaiseDropdown(Number(ticketTypeId), Number(ticketCategoryGroupId)),
    staleTime: 60 * 1000,
    enabled: !!Number(ticketTypeId) && !!Number(ticketCategoryGroupId)
  });

  return (
    <Flex vertical gap={8} className='ticket-category'>
      <Input
        placeholder='Tìm kiếm danh mục ticket'
        size='middle'
        allowClear
        suffix={
          <SearchOutlined
            style={{
              fontSize: 16,
              color: '#1677ff'
            }}
          />
        }
      />
      <List
        dataSource={ticketCategoriesAvailableToRaiseSelectQuery.data?.data}
        loading={ticketCategoriesAvailableToRaiseSelectQuery.isFetching}
        className='ticket-category-list'
        locale={{
          emptyText: (
            <Empty
              image='https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg'
              imageStyle={{ height: 100 }}
              description='Không có kết quả được tìm thấy'
            />
          )
        }}
        renderItem={(itemChild) => (
          <List.Item
            key={itemChild.id}
            className='ticket-category-item'
            onClick={() => onChange(JSON.stringify(itemChild))}
          >
            <List.Item.Meta
              title={<Text style={{ fontWeight: 500 }}>{itemChild.name}</Text>}
              description={
                <Text type='secondary' style={{ fontSize: 13 }}>
                  {itemChild.description}
                </Text>
              }
            />
          </List.Item>
        )}
      />
    </Flex>
  );
}
