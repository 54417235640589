import { useQuery } from '@tanstack/react-query';
import { Select } from 'antd';
import { getPermissionsDropdown } from 'src/apis/master_data/permission.api';

interface Props {
  value?: string[];
  style?: React.CSSProperties | undefined;
  allowClear?: boolean;
  maxTagCount?: number | 'responsive' | undefined;
  onChange?: (
    value: string[],
    option?:
      | {
          value: string;
          label: string;
        }
      | {
          value: string;
          label: string;
        }[]
  ) => void;
}

export default function PermissionMultipleSelect({ value, onChange, maxTagCount, style, allowClear }: Props) {
  const permissionsDropdownV2Query = useQuery({
    queryKey: ['permissionsDropdownV2'],
    queryFn: () => getPermissionsDropdown(),
    staleTime: 60 * 1000
  });

  return (
    <Select
      mode='multiple'
      maxTagCount={maxTagCount}
      allowClear={allowClear}
      value={permissionsDropdownV2Query.isFetching ? undefined : value}
      style={style}
      onChange={onChange}
      options={permissionsDropdownV2Query.data?.data.map((data) => ({ value: JSON.stringify(data), label: data.name }))}
      showSearch
      filterOption={(input, option) => (option?.label?.toString() ?? '').toLowerCase()?.includes(input.toLowerCase())}
      loading={permissionsDropdownV2Query.isFetching}
    />
  );
}
