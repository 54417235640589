import { useQuery } from '@tanstack/react-query';
import { Select } from 'antd';
import { DefaultOptionType } from 'antd/es/select';
import { getWageBranchDropdown } from 'src/apis/master_data/wageBranch.api';

type Props = {
  value?: number | undefined;
  style?: React.CSSProperties | undefined;
  onChange?: ((value: number, option: DefaultOptionType | DefaultOptionType[]) => void) | undefined;
  allowClear?: boolean | undefined;
  placeholder?: React.ReactNode;
  className?: string;
};

export default function WageBranchSelect({ value, style, allowClear = true, onChange, placeholder, className }: Props) {
  const wageBranchDropdownQuery = useQuery({
    queryKey: ['wageBranchDropdown'],
    queryFn: () => getWageBranchDropdown(),
    staleTime: 60 * 1000 * 5
  });

  return (
    <Select
      allowClear={allowClear}
      value={wageBranchDropdownQuery.isFetching ? undefined : value}
      style={style}
      className={className}
      placeholder={placeholder}
      onChange={onChange}
      options={wageBranchDropdownQuery.data?.data.map((data) => ({
        value: data.id,
        label: data.name
      }))}
      showSearch
      filterOption={(input, option) => (option?.label?.toString() ?? '').toLowerCase()?.includes(input.toLowerCase())}
      loading={wageBranchDropdownQuery.isFetching}
    />
  );
}
