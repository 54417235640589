import { RightOutlined } from '@ant-design/icons';
import { Empty, Flex, List, Typography } from 'antd';
import type { TreeItem } from 'src/types/elements.type';
import './TicketCategoryGroupList.scss';
const { Text } = Typography;

type Props = {
  loading: boolean;
  items: TreeItem[] | undefined;
  onChange: (item: TreeItem) => void;
};

export default function TicketCategoryGroupList({ loading, items, onChange }: Props) {
  return (
    <Flex vertical gap={8} className='ticket-category-group'>
      <List
        dataSource={items}
        loading={loading}
        className='ticket-category-group-list'
        locale={{
          emptyText: (
            <Empty
              image='https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg'
              imageStyle={{ height: 100 }}
              description='Không có kết quả được tìm thấy'
            />
          )
        }}
        renderItem={(itemChild) => {
          const isParent = !!itemChild.children && itemChild.children.length > 0;

          return (
            <List.Item
              key={itemChild.key}
              className='ticket-category-group-item'
              onClick={() => {
                onChange(itemChild);
              }}
            >
              <List.Item.Meta title={<Text style={{ fontWeight: 500 }}>{itemChild.title}</Text>} />
              {isParent && (
                <Text type='secondary'>
                  <RightOutlined />
                </Text>
              )}
            </List.Item>
          );
        }}
      />
    </Flex>
  );
}
