import { useQuery } from '@tanstack/react-query';
import { Select } from 'antd';
import { getBiometricDevices } from 'src/apis/master_data/biometricDevice.api';

interface Props {
  value?: number;
  style?: React.CSSProperties | undefined;
  allowClear?: boolean;
  onChange?: (
    value: number,
    option?:
      | {
          value: number;
          label: string;
        }
      | {
          value: number;
          label: string;
        }[]
  ) => void;
}

export default function BiometricDeviceSelect({ value, onChange, style, allowClear }: Props) {
  const biometricDevicesDropdownQuery = useQuery({
    queryKey: ['biometricDevicesDropdown'],
    queryFn: () => getBiometricDevices(),
    staleTime: 60 * 1000
  });

  return (
    <Select
      allowClear={allowClear}
      value={value}
      style={style}
      onChange={onChange}
      options={biometricDevicesDropdownQuery.data?.data.map((data) => ({
        value: data.machineNumber,
        label: data.machineName
      }))}
      showSearch
      filterOption={(input, option) => (option?.label?.toString() ?? '').toLowerCase()?.includes(input.toLowerCase())}
      loading={biometricDevicesDropdownQuery.isFetching}
    />
  );
}
