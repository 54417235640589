import { Button, Divider, Flex, Form, Popover, Select, Typography } from 'antd';
import { useContext, useState } from 'react';
import SortAsc from 'src/assets/icons/sort-asc.png';
import SortDesc from 'src/assets/icons/sort-desc.png';
import Icon from 'src/components/v2/Icon';
import { TicketContext } from 'src/contexts/ticket.context';
import './ticketSortPopover.scss';
import { TableParams } from 'src/types/tableParams.type';
import { TicketSortSelect } from 'src/components/v2/Select';

const { Title } = Typography;

const sortOrientationOptions = [
  { value: 'asc', label: 'Tăng dần' },
  { value: 'desc', label: 'Giảm dần' }
];

interface ISortSelect {
  sortField: string;
  sortOrientation: string;
}

export default function TicketSortPopover() {
  const [form] = Form.useForm();
  const { sortField, sortOrientation, setSortField, setSortOrientation, setTableParams } = useContext(TicketContext);
  const [open, setOpen] = useState<boolean>(false);
  const content = () => {
    const handleOk = (values: ISortSelect) => {
      setSortField(values.sortField);
      setSortOrientation(values.sortOrientation);
      setTableParams((prev: TableParams) => ({
        ...prev,
        pagination: {
          ...prev.pagination,
          current: 1
        }
      }));
      setOpen(false);
    };

    return (
      <Form
        form={form}
        layout='vertical'
        onFinish={handleOk}
        labelAlign='left'
        fields={[
          { name: 'sortField', value: sortField },
          { name: 'sortOrientation', value: sortOrientation }
        ]}
      >
        <Form.Item name='sortField' label='Sắp xếp theo'>
          <TicketSortSelect value={sortField} style={{ height: '32px' }} />
        </Form.Item>
        <Form.Item name='sortOrientation' label='Thứ tự'>
          <Select
            style={{ height: '32px' }}
            value={sortOrientation}
            options={sortOrientationOptions.map((data) => ({
              value: data.value,
              label: data.label
            }))}
            showSearch
            filterOption={(input, option) =>
              (option?.label?.toString() ?? '').toLowerCase()?.includes(input.toLowerCase())
            }
          />
        </Form.Item>
        <Flex justify='end' gap={10}>
          <Button type='default' onClick={() => setOpen(false)}>
            Đóng
          </Button>
          <Button type='primary' htmlType='submit'>
            Xác nhận
          </Button>
        </Flex>
      </Form>
    );
  };

  return (
    <Popover
      id='ticket-sort-popover'
      content={content}
      title={
        <div>
          <Title level={5}>Lọc</Title>
          <Divider style={{ margin: '0 4px' }} />
        </div>
      }
      placement='bottomRight'
      trigger='click'
      open={open}
      overlayStyle={{ width: '400px' }}
      onOpenChange={(value) => setOpen(true)}
    >
      <Flex vertical={false} className='filter-icon'>
        <Icon url={sortOrientation === 'asc' ? SortAsc : SortDesc} size={25} titleTooltip='Sắp xếp' />
      </Flex>
    </Popover>
  );
}
