import { useQuery } from '@tanstack/react-query';
import { Select, SelectProps } from 'antd';
import { getApplicationInfosDropdown } from 'src/apis/master_data/applicationInfo.api';

export default function ApplicationInfoSelect({ value, onChange, style, allowClear, ...props }: SelectProps) {
  const applicationInfosDropdownQuery = useQuery({
    queryKey: ['applicationInfosDropdown'],
    queryFn: () => getApplicationInfosDropdown(),
    staleTime: 60 * 1000
  });

  return (
    <Select
      allowClear={allowClear}
      value={applicationInfosDropdownQuery.isFetching ? undefined : value}
      style={style}
      onChange={onChange}
      options={applicationInfosDropdownQuery.data?.data.map((data) => ({ value: data.id, label: data.name }))}
      showSearch
      filterOption={(input, option) => (option?.label?.toString() ?? '').toLowerCase()?.includes(input.toLowerCase())}
      loading={applicationInfosDropdownQuery.isFetching}
      {...props}
    />
  );
}
