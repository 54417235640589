import { HierarchicalItem } from 'src/types/elements.type';
import { ITicketCategoryGroup, TicketCategoryGroupAdd } from 'src/types/helpdesk/ticketCategoryGroup.type';
import { http } from 'src/utils/http';

const controller = 'helpdesk/ticket-category-groups';

export const getTicketCategoryGroups = async (sequenceStatus: string, departmentId: number | undefined) =>
  await http.get<ITicketCategoryGroup[]>(controller, {
    params: {
      sequenceStatus,
      departmentId
    }
  });

export const getTicketCategoryGroupsDropdown = async (departmentId?: number | undefined) =>
  await http.get<HierarchicalItem[]>(`${controller}/dropdown`, { params: { departmentId } });

export const getTicketCategoryGroupsByCategoryDropdown = async (ticketCategoryId?: number | undefined) =>
  await http.get<HierarchicalItem[]>(`${controller}/dropdown-by-category`, { params: { ticketCategoryId } });

export const getTicketCategoryGroup = async (id: number) => await http.get<ITicketCategoryGroup>(`${controller}/${id}`);

export const addTicketCategoryGroup = async (ticketType: TicketCategoryGroupAdd) =>
  await http.post(controller, ticketType);

export const updateTicketCategoryGroup = async (id: number, ticketType: ITicketCategoryGroup) =>
  await http.put(`${controller}/${id}`, ticketType);

export const deleteTicketCategoryGroup = async (id: number) => await http.delete(`${controller}/${id}`);
