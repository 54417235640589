import { HrConfirmTypeEn } from 'src/constants/hrConfirmTypeEn.enum';
import { RangeDateTypeHr } from 'src/types/date.type';
import { FlatItem, TicketResponses } from 'src/types/elements.type';
import { ApprovalStatus } from 'src/types/helpdesk/ticket.type';
import {
  ConfirmOvertimeRq,
  IOvertimeAttachFile,
  IOvertimeTicket,
  IOvertimeTicketDetail,
  OverTimeTicketConfirm,
  OvertimeTicketAdd,
  OvertimeTicketDetailAdd,
  OvertimeTicketDetailResponse,
  OvertimeTicketReportForLineChart,
  OvertimeTicketReportForPieChart,
  OvertimeTicketReportTakeEmployee
} from 'src/types/hr_ticket/overtimeTicket.type';
import { PagedList } from 'src/types/pagedList.type';
import { IHrTicketParams } from 'src/types/params.type';
import { http, httpV2 } from 'src/utils/http';

const controller = 'hr/overtime-tickets';

export const getOvertimeTickets = async (params: IHrTicketParams) => {
  return await http.get<PagedList<IOvertimeTicket>>(controller, {
    params: params
  });
};

export const getOvertimeTicketsApproval = async (params: IHrTicketParams) => {
  return await http.get<PagedList<IOvertimeTicket>>(`${controller}/waiting-approval`, {
    params: params
  });
};

export const getOvertimeTicketDetailsUsed = async (fromDate: string, toDate: string, employeeCode?: string) => {
  return await http.get<IOvertimeTicketDetail[]>(`${controller}/used-overtime-details/${fromDate}/${toDate}`, {
    params: {
      employeeCode
    }
  });
};

export const getOvertimeTicketsSupervisorConfirm = async (params: IHrTicketParams) => {
  return await http.get<PagedList<IOvertimeTicket>>(`${controller}/handle-confirm`, {
    params: params
  });
};

export const getOvertimeTicketsConfirm = async (
  fromDate: Date,
  toDate: Date,
  searchCondition: string | undefined,
  departmentId?: number | undefined,
  page?: number,
  pageSize?: number,
  orderBy?: string,
  confirmType?: HrConfirmTypeEn | undefined
) => {
  const newFromDate = fromDate.toISOString();
  const newToDate = toDate.toISOString();
  return await httpV2.get<PagedList<IOvertimeTicket>>(`${controller}/hr-confirm/${newFromDate}/${newToDate}`, {
    params: {
      searchCondition,
      departmentId,
      page,
      pageSize,
      orderBy,
      confirmType
    }
  });
};

export const getOvertimeTicket = async (id: number) => {
  return await http.get<IOvertimeTicket>(`${controller}/${id}`);
};

export const getOvertimeTicketsDropdown = async () => {
  return await http.get<FlatItem[]>(`${controller}/dropdown`);
};

export const getUnPaidOvertime = async (employeeId: number) => {
  return await http.get<FlatItem[]>(`${controller}/get-unpaid-tickets/${employeeId}`);
};

export const addOvertimeTicket = async (overtimeTicket: OvertimeTicketAdd) => {
  return await http.post(controller, overtimeTicket);
};

export const updateOvertimeTicket = async (id: number, overtimeTicket: OvertimeTicketAdd) => {
  return await http.put(`${controller}/${id}`, overtimeTicket);
};
export const updateOvertimeTicketDetail = async (id: number, overtimeTicketDetail: OvertimeTicketDetailAdd[]) => {
  return await http.put(`${controller}/update-details/${id}`, overtimeTicketDetail);
};

export const deleteOvertimeTicket = async (id: number) => {
  return await http.delete(`${controller}/${id}`);
};

export const confirmOvertimeTicket = async (rq: ConfirmOvertimeRq) => {
  return await http.put(`${controller}/${rq.id}/confirm-ticket`, {
    isConfirm: rq.isConfirm,
    confirmNote: rq.confirmNote
  });
};

export const supervisorConfirmOvertime = async (ticketId: number, overtimeTicketConfirm: OverTimeTicketConfirm) => {
  return await http.put(`${controller}/supervisor-confirm-detail`, overtimeTicketConfirm, {
    params: {
      ticketId
    }
  });
};

export const cancelOvertimeTicket = async (id: number) => {
  return await http.put(`${controller}/${id}/cancel`);
};

export const approvalOvertimeTickets = async (
  ticketResponses: TicketResponses[],
  approvalStatus: ApprovalStatus,
  note?: string
) =>
  await http.put(`${controller}/approvals`, {
    ticketResponses: ticketResponses,
    approvalStatus,
    note: note ? note : ''
  });

export const cancelApprovalOvertimeTickets = async (id: string, step: number) =>
  await http.put(`${controller}/${id}/cancel-approval`, { step });

export const printOvertimeTicket = async (id: number) =>
  await http.get(`${controller}/print`, {
    params: {
      id: id
    },
    responseType: 'blob'
  });

export const duplicateOvertimeTicket = async (id: number) => await http.put(`${controller}/duplicate/${id}`);

export const getRefOvertimeTicketsForTicket = async (refTicketId: string) =>
  await http.get<IOvertimeTicket[]>(`${controller}/get-ref-tickets/${refTicketId}`);

export const getOvertimeTicketWaitingProcessingCount = async (params: RangeDateTypeHr) => {
  return await http.get(`${controller}/count-ticket-waiting-processing`, {
    params: params
  });
};

export const getMyOvertimeTicketCount = async (params: RangeDateTypeHr) => {
  return await http.get(`${controller}/count-my-tickets`, {
    params: params
  });
};

export const getOvertimeTicketApprovalCount = async (params: RangeDateTypeHr) => {
  return await http.get(`${controller}/count-handle-tickets`, {
    params: params
  });
};

export const getOvertimeTicketHrConfirmCount = async (params: RangeDateTypeHr) => {
  return await httpV2.get(`${controller}/count-hr-confirm-tickets`, {
    params: params
  });
};

export const hrConfirmOvertimeTicket = async (
  id: number | undefined,
  confirmType: HrConfirmTypeEn,
  confirmNote?: string
) => await httpV2.put(`${controller}/hr-confirm`, { id, confirmType, confirmNote });

export const getOvertimeTicketsReportForPieChart = async (
  year: number,
  departmentId?: number,
  month?: number,
  laborType?: number
) => {
  return await http.get<OvertimeTicketReportForPieChart[]>(`${controller}/statistics-by-overtime-date-type/${year}`, {
    params: {
      month,
      laborType,
      departmentId
    }
  });
};

export const getOvertimeTicketsReportForLineChart = async (
  year: number,
  departmentId?: number,
  laborType?: number,
  employeeId?: string
) => {
  return await http.get<OvertimeTicketReportForLineChart[]>(`${controller}/statistics-by-year/${year}`, {
    params: { laborType, employeeId, departmentId }
  });
};

export const getOvertimeTicketsReportTopEmployee = async (
  year: number,
  take: number,
  departmentId?: number,
  month?: number,
  laborTypeId?: number
) => {
  return await http.get<OvertimeTicketReportTakeEmployee[]>(`${controller}/statistics-by-employee/${year}/${take}`, {
    params: {
      month,
      laborTypeId,
      departmentId
    }
  });
};

export const getOvertimeTicketDetailsReport = async (
  year: number,
  departmentId?: number,
  month?: number,
  laborTypeId?: number,
  page?: number,
  pageSize?: number,
  orderBy?: string
) => {
  return await http.get<PagedList<OvertimeTicketDetailResponse>>(
    `${controller}/statistics-by-employee-detail/${year}`,
    {
      params: {
        page,
        pageSize,
        month,
        laborTypeId,
        orderBy,
        departmentId
      }
    }
  );
};

export const getAllOvertimeTicketDetailsReport = async (
  year: number,
  departmentId?: number,
  month?: number,
  laborTypeId?: number
) => {
  return await http.get<OvertimeTicketDetailResponse[]>(`${controller}/statistics-by-employee-detail-all/${year}`, {
    params: {
      month,
      laborTypeId,
      departmentId
    }
  });
};

export const getOvertimeTicketFile = async (ticketFile: IOvertimeAttachFile) => {
  return await http.get(`${controller}/get-file`, {
    params: {
      fileUrl: ticketFile.filePath,
      fileName: ticketFile.originalName
    },
    responseType: 'blob'
  });
};

export const uploadOvertimeTicketFile = async (formData: FormData) => {
  return await http.post<IOvertimeAttachFile[]>(`${controller}/upload`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
};

export const getOvertimeTicketsReport = async (
  fromDate?: Date,
  toDate?: Date,
  departmentId?: number | undefined,
  searchCondition?: string | undefined,
  confirmType?: HrConfirmTypeEn | undefined
) => {
  return await httpV2.get<IOvertimeTicket[]>(`${controller}/hr-report`, {
    params: {
      fromDate,
      toDate,
      departmentId,
      searchCondition,
      confirmType
    }
  });
};
