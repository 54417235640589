import React, { useEffect, useState } from 'react';
import { OpenType } from './EmployeeCompensation';
import { Button, DatePicker, DatePickerProps, Form, InputNumber, Modal, Popover, Select, Skeleton } from 'antd';
import { useTranslation } from 'react-i18next';
import { EmployeeCompensationAdd, IEmployeeCompensation } from 'src/types/hr_ticket/employeeCompensation.type';
import { formatToVND } from 'src/utils/utils';
import { useMutation, useQuery } from '@tanstack/react-query';
import { getCompensationTypesDropdown } from 'src/apis/hr_ticket/compensationType';
import { useForm } from 'antd/es/form/Form';
import {
  addEmployeeCompensation,
  getEmployeeCompensationById,
  updateEmployeeCompensation
} from 'src/apis/hr_ticket/employeeCompensation.api';
import { showNotification } from 'src/utils/notification';
import dayjs from 'dayjs';
import TextArea from 'antd/es/input/TextArea';
import { DepartmentTreeSelect } from 'src/components/v2/Select';
import { FilterFilled } from '@ant-design/icons';
import { EmployeeGroupSelect } from 'src/components/v1/Select';
import { IEmployeeShort } from 'src/types/master_data/employee.type';

interface Props {
  open: OpenType;
  setOpen: React.Dispatch<React.SetStateAction<OpenType>>;
  handleInvalidate: () => void;
}

type FormStateType = IEmployeeCompensation | EmployeeCompensationAdd;

const initialFormState: FormStateType = {};

const AddEmployeeCompensation = ({ open, setOpen, handleInvalidate }: Props) => {
  const { t } = useTranslation();
  const isEditMode = Boolean(open.id);
  const [form] = useForm();
  const [compensationTypesDropdown, setCompensationTypesDropdown] = useState([]);
  const [formState, setFormState] = useState<FormStateType>(initialFormState);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [departmentId, setDepartmentId] = useState<number | undefined>();

  // Api handling
  const compensationTypesDropdownQuery = useQuery({
    queryKey: ['compensationTypesDropdownQuery'],
    queryFn: () => getCompensationTypesDropdown(),
    staleTime: 60 * 1000,
    keepPreviousData: true
  });

  const compensationTypesDropdownOptions = compensationTypesDropdown.map((item: { id: number; name: string }) => {
    return {
      value: item.id,
      label: item.name
    };
  });

  const addEmployeeCompensationsMutation = useMutation({
    mutationFn: (body: FormStateType) => {
      return addEmployeeCompensation(body);
    }
  });

  const employeeCompensationQuery = useQuery({
    queryKey: ['employeeCompensationQuery', open.id],
    queryFn: () => getEmployeeCompensationById(open.id as number),
    enabled: !!open.id,
    onSuccess: (data) => setFormState(data.data)
  });

  const updateEmployeeCompensationsMutation = useMutation({
    mutationFn: (body: FormStateType) => {
      return updateEmployeeCompensation(open.id as number, body as EmployeeCompensationAdd);
    }
  });

  // Event handling
  const onDatePickerChange: DatePickerProps['onChange'] = (date) => {
    const month = Number(date?.month()) + 1;
    const year = Number(date?.year());
    setFormState((prev) => ({
      ...prev,
      month,
      year
    }));
  };

  const onCompensationTypesChange = (value: number) => {
    setFormState((prev) => ({ ...prev, compensationTypeId: value }));
  };

  const handleSubmit = () => {
    form
      .validateFields()
      .then((_) => {
        let payload: EmployeeCompensationAdd = { ...formState };
        if (isEditMode) {
          updateEmployeeCompensationsMutation.mutate(payload, {
            onSuccess: (_) => {
              handleInvalidate();
              showNotification(t('notification.success.edit'), 'success');
              setFormState(initialFormState);
              setOpen({
                isOpen: false
              });
            }
          });
        } else {
          addEmployeeCompensationsMutation.mutate(payload, {
            onSuccess: () => {
              handleInvalidate();
              showNotification(t('notification.success.add'), 'success');
              setFormState(initialFormState);
              form.resetFields();
              setOpen({ isOpen: false });
            },
            onError: () => {
              setFormState(initialFormState);
              showNotification(t('notification.error.add'), 'error');
              form.resetFields();
            }
          });
        }
      })
      .catch((error: any) => {});
  };

  const handleCancel = () => {
    setFormState(initialFormState);
    form.resetFields();
    setOpen({
      isOpen: false
    });
    setDepartmentId(undefined);
  };

  useEffect(() => {
    if (compensationTypesDropdownQuery.data?.data) {
      setCompensationTypesDropdown(compensationTypesDropdownQuery.data?.data);
    }
  }, [compensationTypesDropdownQuery.data?.data]);

  return (
    <Modal
      title={isEditMode ? t('action.edit') : t('action.add')}
      open={open.isOpen}
      style={{ maxWidth: 500 }}
      maskClosable={false}
      onOk={handleSubmit}
      onCancel={handleCancel}
      centered
      footer={[
        <Button key='back' onClick={handleCancel}>
          {t('action.cancel')}
        </Button>,
        <Button
          key='submit'
          type='primary'
          onClick={handleSubmit}
          loading={
            addEmployeeCompensationsMutation.isLoading ||
            updateEmployeeCompensationsMutation.isLoading ||
            (employeeCompensationQuery.isFetching && isEditMode)
          }
        >
          {t('action.submit')}
        </Button>
      ]}
    >
      {employeeCompensationQuery.isFetching && isEditMode ? (
        <Skeleton active paragraph={{ rows: 4 }} />
      ) : (
        <Form
          labelCol={{ span: 7 }}
          wrapperCol={{ span: 18, offset: 1 }}
          layout='horizontal'
          form={form}
          fields={[
            {
              name: 'employeeCompensation',
              value:
                formState.year &&
                formState.month &&
                dayjs(`${formState.year}-${formState.month < 10 ? '0' + formState.month : formState.month}`)
            },
            {
              name: 'employee',
              value: formState.employeeId
            },
            {
              name: 'compensationType',
              value: formState.compensationTypeId
            },
            {
              name: 'amount',
              value: formState.amount
            },
            {
              name: 'note',
              value: formState.note
            }
          ]}
        >
          <Form.Item
            label='Kỳ chấm công'
            name='employeeCompensation'
            rules={[{ required: true, message: 'Vui lòng chọn kỳ chấm công' }]}
          >
            <DatePicker
              allowClear
              picker='month'
              placeholder='Tháng'
              onChange={onDatePickerChange}
              style={{ width: '100%' }}
              suffixIcon={false}
            />
          </Form.Item>

          <div className='additional-wage-filter'>
            <Form.Item
              label={t(`columnTitle.employeeCompensation.employee`)}
              name='employee'
              rules={[{ required: true, message: 'Vui lòng chọn nhân viên' }]}
            >
              <EmployeeGroupSelect
                onChange={(value) => {
                  const convertValue: IEmployeeShort | undefined = value ? JSON.parse(value) : undefined;
                  if (convertValue) {
                    setFormState((prev) => ({
                      ...prev,
                      employeeId: Number(convertValue.id)
                    }));
                  }
                }}
                allowClear
                departmentId={departmentId}
              />
            </Form.Item>
            <Popover
              openClassName='category-filter'
              title={t('columnTitle.base.filter')}
              trigger='click'
              placement='bottomRight'
              open={isOpen}
              onOpenChange={(newOpen: boolean) => setIsOpen(newOpen)}
              content={
                <DepartmentTreeSelect
                  style={{ width: 280 }}
                  placeholder='Chọn bộ phận'
                  allowClear
                  value={departmentId}
                  onChange={(departmentId) => {
                    setDepartmentId(!!departmentId ? Number(departmentId) : undefined);
                  }}
                />
              }
            >
              <Button type='text' style={{ background: '#807474' }}>
                <FilterFilled style={{ fontSize: 12, color: '#fff' }} />
              </Button>
            </Popover>
          </div>
          <Form.Item
            label={t(`columnTitle.employeeCompensation.compensationType`)}
            name='compensationType'
            rules={[{ required: true, message: 'Vui lòng chọn loại bổ sung' }]}
          >
            <Select
              options={compensationTypesDropdownOptions || []}
              loading={compensationTypesDropdownQuery.isFetching}
              onChange={onCompensationTypesChange}
            />
          </Form.Item>
          <Form.Item
            label={t(`columnTitle.employeeCompensation.amount`)}
            name='amount'
            rules={[{ required: true, message: 'Vui lòng điền số tiền công' }]}
          >
            <InputNumber
              style={{ width: '100%' }}
              addonAfter={t('value.vnd')}
              min='0'
              formatter={(value) => formatToVND(String(value))}
              onChange={(value) => {
                setFormState((prev) => ({
                  ...prev,
                  amount: Number(value) || undefined
                }));
              }}
            />
          </Form.Item>
          <Form.Item label={t('columnTitle.employeeCompensation.note')} name='note'>
            <TextArea onChange={(e) => setFormState((prev) => ({ ...prev, note: e.target.value }))} />
          </Form.Item>
        </Form>
      )}
    </Modal>
  );
};

export default AddEmployeeCompensation;
