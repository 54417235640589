import { CRUDPathSegmentEn } from '../newFeatures/crudPathSegment.enum';

const crmPath = {
  crm: '/crm',

  // Event
  // event: '/crm/event',
  // eventDetail: '/crm/event' + CRUDPathSegmentEn.DETAIL,
  // eventDetailCode: '/crm/event' + CRUDPathSegmentEn.DETAIL_BY_CODE,
  // eventAdd: '/crm/event' + CRUDPathSegmentEn.ADD,
  // eventEdit: '/crm/event' + CRUDPathSegmentEn.EDIT,

  // Event - để tạm bên master data
  event: '/system-admin/event',
  eventDetail: '/system-admin/event' + CRUDPathSegmentEn.DETAIL,
  eventDetailCode: '/system-admin/event' + CRUDPathSegmentEn.DETAIL_BY_CODE,
  eventAdd: '/system-admin/event' + CRUDPathSegmentEn.ADD,
  eventEdit: '/system-admin/event' + CRUDPathSegmentEn.EDIT,

  // Customer Survey
  customerSurvey: '/system-admin/customer-survey',
  customerSurveyDetail: '/system-admin/customer-survey' + CRUDPathSegmentEn.DETAIL
};
export default crmPath;
