import { Suspense, lazy, useMemo } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import { PermissionEn } from 'src/constants/permission.enum';
import Forbidden from 'src/pages/Error/Forbidden';
import InternalServer from 'src/pages/Error/InternalServer';
import NotFound from 'src/pages/Error/NotFound';
import EmployeeCompensation from 'src/pages/Hr/EmployeeCompensation';
import type { IRole } from 'src/types/master_data/role.type';
import { getPathsFromRoles, hasPermission } from 'src/utils/utils';
import urlPath from '../constants/urlPath';
import MainLayout from '../layouts/v1/MainLayout';
import MainLayoutV2 from '../layouts/v2/MainLayout';
import SigninOidc from '../pages/SigninOidc';
import SignoutCallbackOidc from '../pages/SignoutCallBackOidc';
import SignoutOidc from '../pages/SignoutOidc';
import crmPath from 'src/constants/urlPath/crmPath';

//#region Import components
const Home = lazy(() => import('../pages/Home'));

//#region Ticket
const TicketCategoryGroup = lazy(() => import('../pages/Ticket/TicketCategoryGroup'));
const TicketCategory = lazy(() => import('../pages/Ticket/TicketCategory'));
const AddTicketCategory = lazy(() => import('../pages/Ticket/TicketCategory/AddTicketCategory'));
const TicketCategoryDetail = lazy(() => import('../pages/Ticket/TicketCategoryDetail'));
const TicketType = lazy(() => import('../pages/Ticket/TicketType'));
const TicketForm = lazy(() => import('src/pages/Ticket/TicketForm/TicketForm'));
const AddTicketForm = lazy(() => import('src/pages/Ticket/TicketForm/AddTicketForm'));
const TicketFormDetail = lazy(() => import('src/pages/Ticket/TicketFormDetail'));
const Reason = lazy(() => import('src/pages/Ticket/Reason'));
const TicketFormMapping = lazy(() => import('src/pages/Ticket/TicketFormMapping'));
//#endregion

//#region Admininstration
const ApprovalProcess = lazy(() => import('src/pages/Administration/ApprovalProcess'));
const AddApprovalProcess = lazy(() => import('src/pages/Administration/AddApprovalProcess'));
const ApprovalProcessDetail = lazy(() => import('src/pages/Administration/ApprovalProcessDetail'));
const Template = lazy(() => import('src/pages/Administration/Template'));
const AddTemplate = lazy(() => import('src/pages/Administration/AddTemplate'));
//#endregion

//#region Master data
const Branch = lazy(() => import('src/pages/MasterData/Branch'));
const Department = lazy(() => import('src/pages/MasterData/Department'));
const Employee = lazy(() => import('src/pages/MasterData/Employee'));
const Position = lazy(() => import('src/pages/MasterData/Position'));
const AddEmployee = lazy(() => import('src/pages/MasterData/AddEmployee'));
const EmployeeQrCode = lazy(() => import('src/pages/MasterData/EmployeeQrCode'));
const EmployeeDetail = lazy(() => import('src/pages/MasterData/EmployeeDetail'));
const Board = lazy(() => import('src/pages/MasterData/Board'));
const Level = lazy(() => import('src/pages/MasterData/Level'));
const Tier = lazy(() => import('src/pages/MasterData/Tier'));
const JobCategory = lazy(() => import('src/pages/MasterData/JobCategory'));
const AddJobCategory = lazy(() => import('src/pages/MasterData/AddJobCategory'));
const OrganizationChart = lazy(() => import('src/pages/MasterData/OrganizationChart'));
const Asset = lazy(() => import('src/pages/MasterData/Asset'));
const Degree = lazy(() => import('src/pages/MasterData/Degree'));
const PartnerType = lazy(() => import('src/pages/MasterData/PartnerType'));
const Partner = lazy(() => import('src/pages/MasterData/Partner'));
const AddPartner = lazy(() => import('src/pages/MasterData/AddPartner'));
const AccountingAccountType = lazy(() => import('src/pages/MasterData/AccountingAccountType'));
const GeneralAccountingAccount = lazy(() => import('src/pages/MasterData/GeneralAccountingAccount'));
const ContractType = lazy(() => import('src/pages/MasterData/ContractType'));
const ApplicationInfo = lazy(() => import('src/pages/MasterData/ApplicationInfo'));
const Certificate = lazy(() => import('src/pages/MasterData/Certificate'));
const ErrorCode = lazy(() => import('src/pages/MasterData/ErrorCode'));
const HRTicketCategory = lazy(() => import('src/pages/MasterData/HRTicketCategory'));
const Resource = lazy(() => import('src/pages/MasterData/Resource'));
const Role = lazy(() => import('src/pages/MasterData/Role'));
const UserResource = lazy(() => import('src/pages/MasterData/UserResource'));
const AddRole = lazy(() => import('src/pages/MasterData/AddRole'));
const Permission = lazy(() => import('src/pages/MasterData/Permission'));
const Holiday = lazy(() => import('src/pages/MasterData/Holiday'));
const FuelConsumption = lazy(() => import('src/pages/MasterData/FuelConsumption'));
const FuelUnitPrice = lazy(() => import('src/pages/MasterData/FuelUnitPrice'));
const AdvancePaymentLimit = lazy(() => import('src/pages/Hr/AdvancePaymentLimit'));
const SubSystem = lazy(() => import('src/pages/MasterData/SubSystem'));
const WorkDescription = lazy(() => import('src/pages/MasterData/WorkDescription'));
const AddSubSystem = lazy(() => import('src/pages/MasterData/AddSubSystem'));
const AddApplicationInfo = lazy(() => import('src/pages/MasterData/AddApplicationInfo'));
const ApplicationInfoDetail = lazy(() => import('src/pages/MasterData/ApplicationInfoDetail'));
const Distance = lazy(() => import('src/pages/MasterData/Distance'));
const BiometricDevice = lazy(() => import('src/pages/Hr/BiometricDevice'));
const BiometricLog = lazy(() => import('src/pages/Hr/BiometricLog'));
const BiometricEmployeeMapping = lazy(() => import('src/pages/Hr/BiometricEmployeeMapping'));
const UserTicketCategory = lazy(() => import('src/pages/MasterData/UserTicketCategory'));
const UserJobCategory = lazy(() => import('src/pages/MasterData/UserJobCategory'));
const Shift = lazy(() => import('src/pages/MasterData/Shift'));
const ManagerAssignment = lazy(() => import('src/pages/MasterData/ManagerAssignment'));
const WareHouse = lazy(() => import('src/pages/MasterData/WareHouse'));
const MobileVersion = lazy(() => import('src/pages/MasterData/MobileVersion'));
const ProductionUnit = lazy(() => import('src/pages/MasterData/ProductionUnit'));
const AddProductionUnit = lazy(() => import('src/pages/MasterData/AddProductionUnit'));
const AcceptancePermission = lazy(() => import('src/pages/MasterData/AcceptancePermission'));
const EmployeeEvaluation = lazy(() => import('src/pages/Hr/EmployeeEvaluation'));
const EventPage = lazy(() => import('src/pages/CRM/Event'));
const CustomerSurvey = lazy(() => import('src/pages/CRM/CustomerSurvey'));
//#endregion

//#region HR
const EmployeeShift = lazy(() => import('src/pages/Hr/EmployeeShift'));
const AdvancePaymentEmployee = lazy(() => import('src/pages/Hr/AdvancePaymentEmployee'));
const LeaveTicketPackage = lazy(() => import('src/pages/Hr/LeaveTicketPackage'));
const AddLeaveTicketPackage = lazy(() => import('src/pages/Hr/AddLeaveTicketPackage'));
const LeaveTicketPackageDetail = lazy(() => import('src/pages/Hr/LeaveTicketPackageDetail'));
const Timesheet = lazy(() => import('src/pages/Hr/Timesheet'));
const AdditionalWorkingTimePackage = lazy(() => import('src/pages/Hr/AdditionalWorkingTimePackage'));
const AdditionalWorkingTimePackageDetail = lazy(() => import('src/pages/Hr/AdditionalWorkingTimePackageDetail'));
// const EmployeeCompensation = lazy(() => import('src/pages/Hr/EmployeeCompensation'));
//#endregion

//#region Import components V2
const HomeV2 = lazy(() => import('src/pages/v2/Home'));
const HomeSub = lazy(() => import('src/pages/v2/HomeSub'));
const BiometricLogV2 = lazy(() => import('src/pages/v2/HrTicket/BiometricLog'));
const Login = lazy(() => import('src/pages/v2/Login'));

// #region Ticket
const TicketManagement = lazy(() => import('src/pages/v2/TicketManagement'));
const TicketsV2 = lazy(() => import('src/pages/v2/Ticket/Tickets'));
const TicketLayout = lazy(() => import('src/layouts/v2/TicketLayout'));
const SubLayout = lazy(() => import('src/layouts/v2/ExternalLayout'));
const AddTicketV2 = lazy(() => import('src/pages/v2/Ticket/AddTicket'));
const TicketDetailSolutionAddV2 = lazy(
  () => import('src/pages/v2/Ticket/TicketDetail/TicketDetailSolution/TicketDetailSolutionAdd')
);
//#endregion

// #region HR
const AdvancePaymentTicketV2 = lazy(() => import('src/pages/v2/HrTicket/AdvancePaymentTicket'));
const AddAdvancePaymentTicketV2 = lazy(() => import('src/pages/v2/HrTicket/AddAdvancePaymentTicket'));

const TransportationTicketV2 = lazy(() => import('src/pages/v2/HrTicket/TransportationTicket'));
const AddTransportationTicketV2 = lazy(() => import('src/pages/v2/HrTicket/AddTransportationTicket'));

const LeaveTicketV2 = lazy(() => import('src/pages/v2/HrTicket/LeaveTicket'));
const LeaveTicketPackageV2 = lazy(() => import('src/pages/v2/HrTicket/LeaveTicketPackage'));
const AddLeaveTicketV2 = lazy(() => import('src/pages/v2/HrTicket/AddLeaveTicket'));
const AddLeaveTicketSub = lazy(() => import('src/pages/v2/Sub/AddLeaveTicket'));

const ExpenseTrackingRequestTicket = lazy(() => import('src/pages/v2/HrTicket/ExpenseTrackingRequestTicket'));
const AddExpenseTrackingRequestTicket = lazy(() => import('src/pages/v2/HrTicket/AddExpenseTrackingRequestTicket'));
const ExpenseTrackingRequestTicketHrConfirm = lazy(() => import('src/pages/v2/HrConfirm/ExpenseTrackingRequestTicket'));
const OvertimeTicketV2 = lazy(() => import('src/pages/v2/HrTicket/OvertimeTicket'));
const AddOvertimeTicketV2 = lazy(() => import('src/pages/v2/HrTicket/AddOvertimeTicket'));

const HandoverTicketV2 = lazy(() => import('src/pages/v2/OfficeTicket/HandoverTicket'));
const AddHandoverTicketV2 = lazy(() => import('src/pages/v2/OfficeTicket/AddHandoverTicket'));

const BusinessTripTicketV2 = lazy(() => import('src/pages/v2/HrTicket/BusinessTripTicket'));
const AddBusinessTripTicketV2 = lazy(() => import('src/pages/v2/HrTicket/AddBusinessTripTicket'));

const PurchaseRequestTicketV2 = lazy(() => import('src/pages/v2/HrTicket/PurchaseRequestTicket'));
const AddPurchaseRequestTicketV2 = lazy(() => import('src/pages/v2/HrTicket/AddPurchaseRequestTicket'));

const EquipmentSupplyRequestTicketV2 = lazy(() => import('src/pages/v2/HrTicket/EquipmentSupplyRequestTicket'));
const AddEquipmentSupplyRequestTicketV2 = lazy(() => import('src/pages/v2/HrTicket/AddEquipmentSupplyRequestTicket'));

const AdditionalWorkingTimeTicketV2 = lazy(() => import('src/pages/v2/HrTicket/AdditionalWorkingTimeTicket'));
const AddAdditionalWorkingTimeTicketV2 = lazy(() => import('src/pages/v2/HrTicket/AddAdditionalWorkingTimeTicket'));

const AdditionalWorkingTimePackageV2 = lazy(() => import('src/pages/v2/HrTicket/AdditionalWorkingTimePackage'));

const LeaveTicketHrConfirmV2 = lazy(() => import('src/pages/v2/HrConfirm/LeaveTicket'));
const BusinessTripHrConfirmV2 = lazy(() => import('src/pages/v2/HrConfirm/BusinessTripTicket'));
const OvertimeHrConfirmV2 = lazy(() => import('src/pages/v2/HrConfirm/OvertimeTicket'));
const TransportationHrConfirmV2 = lazy(() => import('src/pages/v2/HrConfirm/TransportationTicket'));
//#endregion

//#region V2 USER GUIDE
const UserGuides = lazy(() => import('src/pages/v2/UserGuide/UserGuides'));
//#endregion V2 USER GUIDE

//#region V2 LEAVE REMAINING YEAR
const LeaveRemainingYear = lazy(() => import('src/pages/v2/HrTicket/LeaveRemainingYear'));
//#endregion V2 LEAVE REMAINING YEAR

//#region TOPIC
const TopicLayout = lazy(() => import('src/layouts/v2/TopicLayout'));
const TopicConversationBox = lazy(() => import('src/pages/v2/Topic/TopicConversationBox'));
//#endregion TOPIC

//#region REPORT
const ReportLayout = lazy(() => import('src/layouts/v2/ReportLayout'));
const ReportManagement = lazy(() => import('src/pages/v2/Report/ReportManagement'));
const ReportLeaveTicket = lazy(() => import('src/pages/v2/Report/LeaveTicket'));
const ReportOvertimeTicket = lazy(() => import('src/pages/v2/Report/OvertimeTicket'));
const PNL04 = lazy(() => import('src/pages/v2/Report/PNL04'));
const ReportBusinessTripTicket = lazy(() => import('src/pages/v2/Report/BusinessTripTicket'));
const ReportDailyInventoryJournal = lazy(() => import('src/pages/v2/Report/DailyInventoryJournal'));
//#endregion REPORT

//#region PURCHASE
const PurchaseSaleLayout = lazy(() => import('src/layouts/v2/PurchaseSaleLayout'));
const ReceivePlan = lazy(() => import('src/pages/Purchase/ReceivePlan'));
const HistoryOrder = lazy(() => import('src/pages/Purchase/HistoryOrder'));
const Debt = lazy(() => import('src/pages/Purchase/Debt'));
const DebtGroup = lazy(() => import('src/pages/Purchase/DebtGroup'));

const PurchaseOrder = lazy(() => import('src/pages/Purchase/PurchaseOrder'));
const PurchaseOrderStamp = lazy(() => import('src/pages/Purchase/PurchaseOrderStamp'));
const AddPurchaseOrder = lazy(() => import('src/pages/Purchase/PurchaseOrder/AddPurchaseOrder'));
const PurchaseOrderDetail = lazy(() => import('src/pages/Purchase/PurchaseOrder/PurchaseOrderDetail'));

const GRPOR = lazy(() => import('src/pages/Purchase/GRPOR'));
const AddGRPOR = lazy(() => import('src/pages/Purchase/GRPOR/AddGRPOR'));
const GRPORDetail = lazy(() => import('src/pages/Purchase/GRPOR/GRPORDetail'));

const GRPO = lazy(() => import('src/pages/Purchase/GRPO'));
const AddGRPO = lazy(() => import('src/pages/Purchase/GRPO/AddGRPO'));
const GRPODetail = lazy(() => import('src/pages/Purchase/GRPO/GRPODetail'));

const AddQcResult = lazy(() => import('src/pages/Purchase/QcResult/AddQcResult'));
const QcResultDetail = lazy(() => import('src/pages/Purchase/QcResult/QcResultDetail'));

const AddAccounting = lazy(() => import('src/pages/Purchase/Accounting/AddAccounting'));
const AccountingDetail = lazy(() => import('src/pages/Purchase/Accounting/AccountingDetail'));

const AddAPInvoice = lazy(() => import('src/pages/Purchase/APInvoice/AddAPInvoice'));
const APInvoiceDetail = lazy(() => import('src/pages/Purchase/APInvoice/APInvoiceDetail'));

const PurchaseRequest = lazy(() => import('src/pages/Purchase/PurchaseRequest'));
const AddPurchaseRequest = lazy(() => import('src/pages/Purchase/PurchaseRequest/AddPurchaseRequest'));
const PurchaseRequestDetail = lazy(() => import('src/pages/Purchase/PurchaseRequest/PurchaseRequestDetail'));

const PurchaseQuotation = lazy(() => import('src/pages/Purchase/PurchaseQuotation'));
const AddPurchaseQuotation = lazy(() => import('src/pages/Purchase/PurchaseQuotation/AddPurchaseQuotation'));
const PurchaseQuotationDetail = lazy(() => import('src/pages/Purchase/PurchaseQuotation/PurchaseQuotationDetail'));

const TemplateSetOfDocument = lazy(() => import('src/pages/Purchase/TemplateSetOfDocument'));
const DocumentType = lazy(() => import('src/pages/Purchase/DocumentType'));
const RefKey = lazy(() => import('src/pages/Purchase/RefKey'));
//#endregion PURCHASEimport QcResult from './../pages/Purchase/QcResult/index';

//#region ITEM MASTER DATA
const ItemMasterDataLayout = lazy(() => import('src/layouts/v2/ItemMasterDataLayout'));
const ItemMasterData = lazy(() => import('src/pages/ItemMasterData/ItemMasterData'));
const AddItemMasterData = lazy(() => import('src/pages/ItemMasterData/AddItemMasterData'));
const ItemType = lazy(() => import('src/pages/ItemMasterData/ItemType'));
const ItemGroup = lazy(() => import('src/pages/ItemMasterData/ItemGroup'));
//#endregion ITEM MASTER DATA

interface RouteType {
  path: string;
  redirect?: string;
  Layout?: any;
  SubLayout?: any;
  Component?: any;
  permission?: PermissionEn;
  checkPath?: string;
  isUsePathV2?: boolean;
  hasSidebar?: boolean;
  hasSubSidebar?: boolean;
  hasSubHeader?: boolean;
  isHideDetailLayout?: boolean;
  isDisableFooter?: boolean;
}

export default function useRouteElements(resources: Record<string, string[]> | undefined, roles: IRole[] | undefined) {
  const paths = useMemo(() => {
    let result = getPathsFromRoles(roles);
    return result.map((item) => `/system-admin${item}`);
  }, [roles]);

  const pathsV2 = useMemo(() => {
    let result = getPathsFromRoles(roles);
    return result.map((item) => item);
  }, [roles]);

  const routesV1: RouteType[] = [
    {
      path: urlPath.home.location,
      Layout: MainLayout,
      Component: Home
    },
    {
      path: urlPath.ticketType.location,
      Layout: MainLayout,
      Component: TicketType,
      checkPath: urlPath.ticketType.location
    },
    {
      path: urlPath.ticketCategoryGroup.location,
      Layout: MainLayout,
      Component: TicketCategoryGroup,
      checkPath: urlPath.ticketCategoryGroup.location
    },
    {
      path: urlPath.ticketCategory.location,
      Layout: MainLayout,
      Component: TicketCategory,
      checkPath: urlPath.ticketCategory.location
    },
    {
      path: urlPath.ticketCategoryAdd.location,
      Layout: MainLayout,
      Component: AddTicketCategory,
      checkPath: urlPath.ticketCategory.location
    },
    {
      path: `${urlPath.ticketCategoryEdit.location}/:id`,
      Layout: MainLayout,
      Component: AddTicketCategory,
      checkPath: urlPath.ticketCategory.location
    },
    {
      path: `${urlPath.ticketCategoryDetail.location}/:id`,
      Layout: MainLayout,
      Component: TicketCategoryDetail,
      permission: PermissionEn.viewDetail
    },
    {
      path: urlPath.ticketForm.location,
      Layout: MainLayout,
      Component: TicketForm,
      checkPath: urlPath.ticketForm.location
    },
    {
      path: `${urlPath.ticketFormDetail.location}/:id`,
      Layout: MainLayout,
      Component: TicketFormDetail,
      permission: PermissionEn.viewDetail
    },
    {
      path: urlPath.ticketFormAdd.location,
      Layout: MainLayout,
      Component: AddTicketForm,
      permission: PermissionEn.create
    },
    {
      path: `${urlPath.ticketFormEdit.location}/:id`,

      Layout: MainLayout,
      Component: AddTicketForm,
      permission: PermissionEn.update
    },
    {
      path: urlPath.reason.location,
      Layout: MainLayout,
      Component: Reason,
      checkPath: urlPath.reason.location
    },
    {
      path: urlPath.ticketFormMapping.location,
      Layout: MainLayout,
      Component: TicketFormMapping,
      checkPath: urlPath.ticketFormMapping.location
    },
    {
      path: urlPath.biometricLog.location,
      Layout: MainLayout,
      Component: BiometricLog
    },
    {
      path: urlPath.biometricEmployeeMapping.location,
      Layout: MainLayout,
      Component: BiometricEmployeeMapping,
      checkPath: urlPath.biometricEmployeeMapping.location
    },
    {
      path: urlPath.leaveRemainingYear.location,
      Layout: MainLayout,
      Component: LeaveRemainingYear,
      checkPath: urlPath.leaveRemainingYear.location
    },
    {
      path: urlPath.leaveTicketPackage.location,
      Layout: MainLayout,
      Component: LeaveTicketPackage,
      checkPath: urlPath.leaveTicketPackage.location
    },
    {
      path: urlPath.leaveTicketPackageAdd.location,
      Layout: MainLayout,
      Component: AddLeaveTicketPackage,
      permission: PermissionEn.create
    },
    {
      path: `${urlPath.leaveTicketPackageDetail.location}/:id`,
      Layout: MainLayout,
      Component: LeaveTicketPackageDetail,
      permission: PermissionEn.viewDetail
    },
    {
      path: urlPath.additionalWorkingTimePackage.location,
      Layout: MainLayout,
      Component: AdditionalWorkingTimePackage,
      checkPath: urlPath.additionalWorkingTimePackage.location
    },
    {
      path: `${urlPath.additionalWorkingTimePackageDetail.location}/:id`,
      Layout: MainLayout,
      Component: AdditionalWorkingTimePackageDetail,
      permission: PermissionEn.viewDetail
    },
    {
      path: urlPath.employeeCompensation.location,
      Layout: MainLayout,
      Component: EmployeeCompensation,
      permission: PermissionEn.create
    },
    {
      path: urlPath.timesheet.location,
      Layout: MainLayout,
      Component: Timesheet,
      checkPath: urlPath.timesheet.location
    },
    {
      path: urlPath.employeeShift.location,
      Layout: MainLayout,
      Component: EmployeeShift,
      checkPath: urlPath.employeeShift.location
    },
    {
      path: urlPath.advancePaymentEmployee.location,
      Layout: MainLayout,
      Component: AdvancePaymentEmployee,
      checkPath: urlPath.advancePaymentEmployee.location
    },
    {
      path: urlPath.template.location,
      Layout: MainLayout,
      Component: Template,
      checkPath: urlPath.template.location
    },
    {
      path: `${urlPath.templateEdit.location}/:id`,
      Layout: MainLayout,
      Component: AddTemplate,
      permission: PermissionEn.update
    },
    {
      path: urlPath.templateAdd.location,
      Layout: MainLayout,
      Component: AddTemplate,
      permission: PermissionEn.update
    },
    {
      path: urlPath.templateAdd.location,
      Layout: MainLayout,
      Component: AddTemplate,
      permission: PermissionEn.create
    },
    {
      path: urlPath.approvalProcess.location,
      Layout: MainLayout,
      Component: ApprovalProcess,
      checkPath: urlPath.approvalProcess.location
    },
    {
      path: urlPath.approvalProcessAdd.location,
      Layout: MainLayout,
      Component: AddApprovalProcess,
      permission: PermissionEn.create
    },
    {
      path: `${urlPath.approvalProcessDetail.location}/:id`,
      Layout: MainLayout,
      Component: ApprovalProcessDetail,
      permission: PermissionEn.create
    },
    {
      path: `${urlPath.approvalProcessEdit.location}/:id`,
      Layout: MainLayout,
      Component: AddApprovalProcess,
      permission: PermissionEn.update
    },
    {
      path: urlPath.branch.location,
      Layout: MainLayout,
      Component: Branch,
      checkPath: urlPath.branch.location
    },
    {
      path: urlPath.department.location,
      Layout: MainLayout,
      Component: Department,
      checkPath: urlPath.department.location
    },
    {
      path: urlPath.employee.location,
      Layout: MainLayout,
      Component: Employee,
      checkPath: urlPath.employee.location
    },
    {
      path: urlPath.employeeAdd.location,
      Layout: MainLayout,
      Component: AddEmployee,
      permission: PermissionEn.create
    },
    {
      path: urlPath.employeeQR.location,
      Layout: MainLayout,
      Component: EmployeeQrCode,
      permission: PermissionEn.get_qr
    },
    {
      path: `${urlPath.employeeEdit.location}/:id`,
      Layout: MainLayout,
      Component: AddEmployee,
      permission: PermissionEn.update
    },
    {
      path: `${urlPath.employeeDetail.location}/:id`,
      Layout: MainLayout,
      Component: EmployeeDetail,
      permission: PermissionEn.viewDetail
    },
    {
      path: urlPath.position.location,
      Layout: MainLayout,
      Component: Position,
      checkPath: urlPath.position.location
    },
    {
      path: urlPath.board.location,
      Layout: MainLayout,
      Component: Board,
      checkPath: urlPath.board.location
    },
    {
      path: urlPath.level.location,
      Layout: MainLayout,
      Component: Level,
      checkPath: urlPath.level.location
    },
    {
      path: urlPath.tier.location,
      Layout: MainLayout,
      Component: Tier,
      checkPath: urlPath.tier.location
    },
    {
      path: urlPath.jobCategory.location,
      Layout: MainLayout,
      Component: JobCategory,
      checkPath: urlPath.jobCategory.location
    },
    {
      path: urlPath.jobCategoryAdd.location,
      Layout: MainLayout,
      Component: AddJobCategory,
      permission: PermissionEn.create
    },
    {
      path: `${urlPath.jobCategoryEdit.location}/:id`,
      Layout: MainLayout,
      Component: AddJobCategory,
      permission: PermissionEn.update
    },
    {
      path: urlPath.organizationChart.location,
      Layout: MainLayout,
      Component: OrganizationChart,
      checkPath: urlPath.organizationChart.location
    },
    {
      path: urlPath.partnerType.location,
      Layout: MainLayout,
      Component: PartnerType,
      checkPath: urlPath.partnerType.location
    },
    {
      path: urlPath.asset.location,
      Layout: MainLayout,
      Component: Asset,
      checkPath: urlPath.asset.location
    },
    {
      path: urlPath.degree.location,
      Layout: MainLayout,
      Component: Degree,
      checkPath: urlPath.degree.location
    },
    {
      path: urlPath.partner.location,
      Layout: MainLayout,
      Component: Partner,
      checkPath: urlPath.partner.location
    },
    {
      path: `${urlPath.partnerEdit.location}/:id`,
      Layout: MainLayout,
      Component: AddPartner,
      permission: PermissionEn.update
    },
    {
      path: urlPath.partnerAdd.location,
      Layout: MainLayout,
      Component: AddPartner,
      permission: PermissionEn.create
    },
    {
      path: urlPath.accountingAccountType.location,
      Layout: MainLayout,
      Component: AccountingAccountType,
      checkPath: urlPath.accountingAccountType.location
    },
    {
      path: urlPath.generalAccountingAccount.location,
      Layout: MainLayout,
      Component: GeneralAccountingAccount,
      checkPath: urlPath.generalAccountingAccount.location
    },
    {
      path: urlPath.contractType.location,
      Layout: MainLayout,
      Component: ContractType,
      checkPath: urlPath.contractType.location
    },
    {
      path: urlPath.applicationInfo.location,
      Layout: MainLayout,
      Component: ApplicationInfo,
      checkPath: urlPath.applicationInfo.location
    },
    {
      path: `${urlPath.applicationInfoEdit.location}/:id`,
      Layout: MainLayout,
      Component: AddApplicationInfo,
      permission: PermissionEn.update
    },
    {
      path: urlPath.applicationInfoAdd.location,
      Layout: MainLayout,
      Component: AddApplicationInfo,
      permission: PermissionEn.create
    },
    {
      path: `${urlPath.applicationInfoDetail.location}/:id`,
      Layout: MainLayout,
      Component: ApplicationInfoDetail,
      permission: PermissionEn.viewDetail
    },
    {
      path: urlPath.certificate.location,
      Layout: MainLayout,
      Component: Certificate,
      checkPath: urlPath.certificate.location
    },
    {
      path: urlPath.errorCode.location,
      Layout: MainLayout,
      Component: ErrorCode,
      checkPath: urlPath.errorCode.location
    },
    {
      path: urlPath.hrTicketCategory.location,
      Layout: MainLayout,
      Component: HRTicketCategory,
      checkPath: urlPath.hrTicketCategory.location
    },
    {
      path: urlPath.resource.location,
      Layout: MainLayout,
      Component: Resource,
      checkPath: urlPath.resource.location
    },
    {
      path: urlPath.role.location,
      Layout: MainLayout,
      Component: Role,
      checkPath: urlPath.role.location
    },
    {
      path: urlPath.roleAdd.location,
      Layout: MainLayout,
      Component: AddRole,
      permission: PermissionEn.create
    },
    {
      path: `${urlPath.roleEdit.location}/:id`,
      Layout: MainLayout,
      Component: AddRole,
      permission: PermissionEn.update
    },
    {
      path: urlPath.userResource.location,
      Layout: MainLayout,
      Component: UserResource,
      checkPath: urlPath.userResource.location
    },
    {
      path: urlPath.permission.location,
      Layout: MainLayout,
      Component: Permission,
      checkPath: urlPath.permission.location
    },
    {
      path: urlPath.biometricDevice.location,
      Layout: MainLayout,
      Component: BiometricDevice,
      checkPath: urlPath.biometricDevice.location
    },
    {
      path: urlPath.distance.location,
      Layout: MainLayout,
      Component: Distance,
      checkPath: urlPath.distance.location
    },
    {
      path: urlPath.holiday.location,
      Layout: MainLayout,
      Component: Holiday,
      checkPath: urlPath.holiday.location
    },
    {
      path: urlPath.fuelConsumption.location,
      Layout: MainLayout,
      Component: FuelConsumption,
      checkPath: urlPath.fuelConsumption.location
    },
    {
      path: urlPath.fuelUnitPrice.location,
      Layout: MainLayout,
      Component: FuelUnitPrice,
      checkPath: urlPath.fuelUnitPrice.location
    },
    {
      path: urlPath.advancePaymentLimit.location,
      Layout: MainLayout,
      Component: AdvancePaymentLimit,
      checkPath: urlPath.advancePaymentLimit.location
    },
    {
      path: urlPath.employeeEvaluation.location,
      Layout: MainLayout,
      Component: EmployeeEvaluation,
      checkPath: urlPath.employeeEvaluation.location
    },
    {
      path: urlPath.subSystem.location,
      Layout: MainLayout,
      Component: SubSystem,
      checkPath: urlPath.subSystem.location
    },
    {
      path: urlPath.subSystemAdd.location,
      Layout: MainLayout,
      Component: AddSubSystem,
      checkPath: urlPath.subSystem.location
    },
    {
      path: `${urlPath.subSystemEdit.location}/:id`,
      Layout: MainLayout,
      Component: AddSubSystem,
      checkPath: urlPath.subSystem.location
    },
    {
      path: urlPath.workDescription.location,
      Layout: MainLayout,
      Component: WorkDescription,
      checkPath: urlPath.workDescription.location
    },
    {
      path: urlPath.userTicketCategory.location,
      Layout: MainLayout,
      Component: UserTicketCategory,
      checkPath: urlPath.userTicketCategory.location
    },
    {
      path: urlPath.userTicketCategory.location,
      Layout: MainLayout,
      Component: UserTicketCategory
    },
    {
      path: urlPath.userJobCategory.location,
      Layout: MainLayout,
      Component: UserJobCategory,
      checkPath: urlPath.userJobCategory.location
    },
    {
      path: urlPath.shift.location,
      Layout: MainLayout,
      Component: Shift,
      checkPath: urlPath.shift.location
    },
    {
      path: urlPath.managerAssignment.location,
      Layout: MainLayout,
      Component: ManagerAssignment,
      checkPath: urlPath.managerAssignment.location
    },
    {
      path: urlPath.wareHouse.location,
      Layout: MainLayout,
      Component: WareHouse,
      checkPath: urlPath.wareHouse.location
    },
    {
      path: urlPath.mobileVersion.location,
      Layout: MainLayout,
      Component: MobileVersion,
      checkPath: urlPath.mobileVersion.location
    },
    {
      path: urlPath.productionUnit.location,
      Layout: MainLayout,
      Component: ProductionUnit,
      checkPath: urlPath.productionUnit.location
    },
    {
      path: urlPath.productionUnitAdd.location,
      Layout: MainLayout,
      Component: AddProductionUnit,
      checkPath: urlPath.productionUnit.location
    },
    {
      path: `${urlPath.productionUnitEdit.location}/:id`,
      Layout: MainLayout,
      Component: AddProductionUnit,
      checkPath: urlPath.productionUnit.location
    },
    {
      path: urlPath.acceptancePermission.location,
      Layout: MainLayout,
      Component: AcceptancePermission,
      checkPath: urlPath.acceptancePermission.location
    },
    {
      path: crmPath.event,
      Layout: MainLayout,
      Component: EventPage,
      checkPath: crmPath.event
    },
    {
      path: crmPath.customerSurvey,
      Layout: MainLayout,
      Component: CustomerSurvey,
      checkPath: crmPath.customerSurvey
    }
  ];

  const routesV2: RouteType[] = [
    {
      path: `${urlPath.homeV2.location}/v2`,
      Layout: MainLayoutV2,
      Component: HomeV2
    },
    {
      path: urlPath.homeV2.location,
      Layout: MainLayoutV2,
      Component: HomeV2
    },
    {
      path: urlPath.helpdeskV2.location,
      Layout: MainLayoutV2,
      SubLayout: TicketLayout,
      Component: TicketManagement,
      hasSidebar: false,
      hasSubHeader: false
    },
    {
      path: urlPath.hrTicketV2.location,
      Layout: MainLayoutV2,
      SubLayout: TicketLayout,
      redirect: urlPath.helpdeskV2.location,
      hasSidebar: false,
      hasSubHeader: false
    },
    {
      path: urlPath.ticketV2.location,
      Layout: MainLayoutV2,
      SubLayout: TicketLayout,
      Component: TicketsV2,
      hasSidebar: true
    },
    {
      path: urlPath.myTicketV2.location,
      Layout: MainLayoutV2,
      SubLayout: TicketLayout,
      Component: TicketsV2,
      hasSidebar: true
    },
    {
      path: `${urlPath.myTicketV2.location}/:id`,
      Layout: MainLayoutV2,
      SubLayout: TicketLayout,
      Component: TicketsV2,
      hasSidebar: true
    },
    {
      path: urlPath.approvalV2.location,
      Layout: MainLayoutV2,
      SubLayout: TicketLayout,
      Component: TicketsV2,
      hasSidebar: true
    },
    {
      path: `${urlPath.approvalV2.location}/:id`,
      Layout: MainLayoutV2,
      SubLayout: TicketLayout,
      Component: TicketsV2,
      hasSidebar: true
    },
    {
      path: urlPath.acceptV2.location,
      Layout: MainLayoutV2,
      SubLayout: TicketLayout,
      Component: TicketsV2,
      hasSidebar: true
    },
    {
      path: `${urlPath.acceptV2.location}/:id`,
      Layout: MainLayoutV2,
      SubLayout: TicketLayout,
      Component: TicketsV2,
      hasSidebar: true
    },
    {
      path: urlPath.solutionV2.location,
      Layout: MainLayoutV2,
      SubLayout: TicketLayout,
      Component: TicketsV2,
      hasSidebar: true
    },
    {
      path: `${urlPath.solutionV2.location}/:id`,
      Layout: MainLayoutV2,
      SubLayout: TicketLayout,
      Component: TicketsV2,
      hasSidebar: true
    },
    {
      path: `${urlPath.solutionAddV2.location}`,
      Layout: MainLayoutV2,
      SubLayout: TicketLayout,
      Component: TicketDetailSolutionAddV2,
      hasSidebar: false,
      isHideDetailLayout: true
    },
    {
      path: `${urlPath.solutionEditV2.location}/:id`,
      Layout: MainLayoutV2,
      SubLayout: TicketLayout,
      Component: TicketDetailSolutionAddV2,
      hasSidebar: false,
      isHideDetailLayout: true
    },
    {
      path: urlPath.handleV2.location,
      Layout: MainLayoutV2,
      SubLayout: TicketLayout,
      Component: TicketsV2,
      hasSidebar: true
    },
    {
      path: `${urlPath.handleV2.location}/:id`,
      Layout: MainLayoutV2,
      SubLayout: TicketLayout,
      Component: TicketsV2,
      hasSidebar: true
    },
    {
      path: `${urlPath.ticketAddV2.location}`,
      Layout: MainLayoutV2,
      SubLayout: TicketLayout,
      Component: AddTicketV2,
      hasSubHeader: false,
      hasSidebar: false,
      isDisableFooter: true,
      isHideDetailLayout: true
    },
    {
      path: `${urlPath.ticketEditV2.location}/:id`,
      Layout: MainLayoutV2,
      SubLayout: TicketLayout,
      Component: AddTicketV2,
      hasSubHeader: false,
      hasSidebar: false,
      isDisableFooter: true,
      isHideDetailLayout: true
    },
    {
      path: urlPath.myAdvancePaymentTicketV2.location,
      Layout: MainLayoutV2,
      SubLayout: TicketLayout,
      Component: AdvancePaymentTicketV2,
      hasSidebar: true
    },
    {
      path: urlPath.advancePaymentTicketV2.location,
      Layout: MainLayoutV2,
      SubLayout: TicketLayout,
      redirect: urlPath.myAdvancePaymentTicketV2.location,
      hasSidebar: true
    },
    {
      path: urlPath.advancePaymentTicketApprovalV2.location,
      Layout: MainLayoutV2,
      SubLayout: TicketLayout,
      Component: AdvancePaymentTicketV2,
      hasSidebar: true
    },
    {
      path: `${urlPath.myAdvancePaymentTicketV2.location}/:id`,
      Layout: MainLayoutV2,
      SubLayout: TicketLayout,
      Component: AdvancePaymentTicketV2,
      hasSidebar: true
    },
    {
      path: `${urlPath.advancePaymentTicketApprovalV2.location}/:id`,
      Layout: MainLayoutV2,
      SubLayout: TicketLayout,
      Component: AdvancePaymentTicketV2,
      hasSidebar: true
    },
    {
      path: urlPath.advancePaymentTicketAddV2.location,
      Layout: MainLayoutV2,
      SubLayout: TicketLayout,
      Component: AddAdvancePaymentTicketV2,
      hasSidebar: false,
      hasSubHeader: false,
      isDisableFooter: true,
      isHideDetailLayout: true
    },
    {
      path: `${urlPath.advancePaymentTicketEditV2.location}/:id`,
      Layout: MainLayoutV2,
      SubLayout: TicketLayout,
      Component: AddAdvancePaymentTicketV2,
      hasSidebar: false,
      hasSubHeader: false,
      isDisableFooter: true,
      isHideDetailLayout: true
    },
    {
      path: urlPath.myTransportationTicketV2.location,
      Layout: MainLayoutV2,
      SubLayout: TicketLayout,
      Component: TransportationTicketV2,
      hasSidebar: true
    },
    {
      path: urlPath.transportationTicketV2.location,
      Layout: MainLayoutV2,
      SubLayout: TicketLayout,
      redirect: urlPath.myTransportationTicketV2.location,
      hasSidebar: true
    },
    {
      path: urlPath.transportationTicketApprovalV2.location,
      Layout: MainLayoutV2,
      SubLayout: TicketLayout,
      Component: TransportationTicketV2,
      hasSidebar: true
    },
    {
      path: urlPath.transportationTicketConfirmV2.location,
      Layout: MainLayoutV2,
      SubLayout: TicketLayout,
      Component: TransportationTicketV2,
      hasSidebar: true
      // checkPath: urlPath.transportationTicketConfirmV2.location
    },
    {
      path: urlPath.transportationTicketHandleV2.location,
      Layout: MainLayoutV2,
      SubLayout: TicketLayout,
      Component: TransportationTicketV2,
      hasSidebar: true
    },
    {
      path: `${urlPath.myTransportationTicketV2.location}/:id`,
      Layout: MainLayoutV2,
      SubLayout: TicketLayout,
      Component: TransportationTicketV2,
      hasSidebar: true
    },
    {
      path: `${urlPath.transportationTicketApprovalV2.location}/:id`,
      Layout: MainLayoutV2,
      SubLayout: TicketLayout,
      Component: TransportationTicketV2,
      hasSidebar: true
    },
    {
      path: `${urlPath.transportationTicketConfirmV2.location}/:id`,
      Layout: MainLayoutV2,
      SubLayout: TicketLayout,
      Component: TransportationTicketV2,
      hasSidebar: true
      // checkPath: urlPath.transportationTicketConfirmV2.location
    },
    {
      path: `${urlPath.transportationTicketHandleV2.location}/:id`,
      Layout: MainLayoutV2,
      SubLayout: TicketLayout,
      Component: TransportationTicketV2,
      hasSidebar: true
    },
    {
      path: urlPath.transportationTicketAddV2.location,
      Layout: MainLayoutV2,
      SubLayout: TicketLayout,
      Component: AddTransportationTicketV2,
      hasSidebar: false,
      hasSubHeader: false,
      isDisableFooter: true,
      isHideDetailLayout: true
    },
    {
      path: `${urlPath.transportationTicketEditV2.location}/:id`,
      Layout: MainLayoutV2,
      SubLayout: TicketLayout,
      Component: AddTransportationTicketV2,
      hasSidebar: false,
      hasSubHeader: false,
      isDisableFooter: true,
      isHideDetailLayout: true
    },
    {
      path: urlPath.myLeaveTicketV2.location,
      Layout: MainLayoutV2,
      SubLayout: TicketLayout,
      Component: LeaveTicketV2,
      hasSidebar: true
    },
    {
      path: urlPath.leaveTicketV2.location,
      Layout: MainLayoutV2,
      redirect: urlPath.myLeaveTicketV2.location,
      hasSidebar: false,
      hasSubHeader: false,
      isDisableFooter: true
    },
    {
      path: urlPath.leaveTicketApprovalV2.location,
      Layout: MainLayoutV2,
      SubLayout: TicketLayout,
      Component: LeaveTicketV2,
      hasSidebar: true
    },
    {
      path: urlPath.leaveTicketPackageApprovalV2.location,
      Layout: MainLayoutV2,
      SubLayout: TicketLayout,
      Component: LeaveTicketPackageV2,
      hasSidebar: true
    },
    {
      path: `${urlPath.myLeaveTicketV2.location}/:id`,
      Layout: MainLayoutV2,
      SubLayout: TicketLayout,
      Component: LeaveTicketV2,
      hasSidebar: true
    },
    {
      path: `${urlPath.leaveTicketApprovalV2.location}/:id`,
      Layout: MainLayoutV2,
      SubLayout: TicketLayout,
      Component: LeaveTicketV2,
      hasSidebar: true
    },
    {
      path: `${urlPath.leaveTicketPackageApprovalV2.location}/:id`,
      Layout: MainLayoutV2,
      SubLayout: TicketLayout,
      Component: LeaveTicketPackageV2,
      hasSidebar: true
    },

    {
      path: urlPath.leaveTicketAddV2.location,
      Layout: MainLayoutV2,
      SubLayout: TicketLayout,
      Component: AddLeaveTicketV2,
      hasSidebar: false,
      hasSubHeader: false,
      isDisableFooter: true,
      isHideDetailLayout: true
    },
    {
      path: `${urlPath.leaveTicketEditV2.location}/:id`,
      Layout: MainLayoutV2,
      SubLayout: TicketLayout,
      Component: AddLeaveTicketV2,
      hasSidebar: false,
      hasSubHeader: false,
      isDisableFooter: true,
      isHideDetailLayout: true
    },
    {
      path: urlPath.myExpenseTrackingRequestTicket.location,
      Layout: MainLayoutV2,
      SubLayout: TicketLayout,
      Component: ExpenseTrackingRequestTicket,
      hasSidebar: true
    },
    {
      path: urlPath.expenseTrackingRequestTicket.location,
      Layout: MainLayoutV2,
      redirect: urlPath.myExpenseTrackingRequestTicket.location,
      hasSidebar: false,
      hasSubHeader: false,
      isDisableFooter: true
    },
    {
      path: urlPath.expenseTrackingRequestTicketApproval.location,
      Layout: MainLayoutV2,
      SubLayout: TicketLayout,
      Component: ExpenseTrackingRequestTicket,
      hasSidebar: true
    },
    {
      path: `${urlPath.myExpenseTrackingRequestTicket.location}/:id`,
      Layout: MainLayoutV2,
      SubLayout: TicketLayout,
      Component: ExpenseTrackingRequestTicket,
      hasSidebar: true
    },
    {
      path: `${urlPath.expenseTrackingRequestTicketApproval.location}/:id`,
      Layout: MainLayoutV2,
      SubLayout: TicketLayout,
      Component: ExpenseTrackingRequestTicket,
      hasSidebar: true
    },
    {
      path: urlPath.expenseTrackingRequestTicketAdd.location,
      Layout: MainLayoutV2,
      SubLayout: TicketLayout,
      Component: AddExpenseTrackingRequestTicket,
      hasSidebar: false,
      hasSubHeader: false,
      isDisableFooter: true,
      isHideDetailLayout: true
    },
    {
      path: `${urlPath.expenseTrackingRequestTicketEdit.location}/:id`,
      Layout: MainLayoutV2,
      SubLayout: TicketLayout,
      Component: AddExpenseTrackingRequestTicket,
      hasSidebar: false,
      hasSubHeader: false,
      isDisableFooter: true,
      isHideDetailLayout: true
    },
    {
      path: urlPath.myOvertimeTicketV2.location,
      Layout: MainLayoutV2,
      SubLayout: TicketLayout,
      Component: OvertimeTicketV2,
      hasSidebar: true
    },
    {
      path: urlPath.overtimeTicketV2.location,
      Layout: MainLayoutV2,
      SubLayout: TicketLayout,
      redirect: urlPath.myOvertimeTicketV2.location,
      hasSidebar: true
    },
    {
      path: urlPath.overtimeTicketApprovalV2.location,
      Layout: MainLayoutV2,
      SubLayout: TicketLayout,
      Component: OvertimeTicketV2,
      hasSidebar: true
    },
    {
      path: urlPath.overtimeTicketSupervisorConfirmV2.location,
      Layout: MainLayoutV2,
      SubLayout: TicketLayout,
      Component: OvertimeTicketV2,
      hasSidebar: true
    },
    {
      path: `${urlPath.overtimeTicketSupervisorConfirmV2.location}/:id`,
      Layout: MainLayoutV2,
      SubLayout: TicketLayout,
      Component: OvertimeTicketV2,
      hasSidebar: true
    },
    {
      path: `${urlPath.myOvertimeTicketV2.location}/:id`,
      Layout: MainLayoutV2,
      SubLayout: TicketLayout,
      Component: OvertimeTicketV2,
      hasSidebar: true
    },
    {
      path: `${urlPath.overtimeTicketApprovalV2.location}/:id`,
      Layout: MainLayoutV2,
      SubLayout: TicketLayout,
      Component: OvertimeTicketV2,
      hasSidebar: true
    },
    {
      path: urlPath.overtimeTicketAddV2.location,
      Layout: MainLayoutV2,
      SubLayout: TicketLayout,
      Component: AddOvertimeTicketV2,
      hasSidebar: false,
      hasSubHeader: false,
      isDisableFooter: true,
      isHideDetailLayout: true
    },
    {
      path: `${urlPath.overtimeTicketEditV2.location}/:id`,
      Layout: MainLayoutV2,
      SubLayout: TicketLayout,
      Component: AddOvertimeTicketV2,
      hasSidebar: false,
      hasSubHeader: false,
      isDisableFooter: true,
      isHideDetailLayout: true
    },
    {
      path: urlPath.myBusinessTripTicketV2.location,
      Layout: MainLayoutV2,
      SubLayout: TicketLayout,
      Component: BusinessTripTicketV2,
      hasSidebar: true
    },
    {
      path: urlPath.businessTripTicketV2.location,
      Layout: MainLayoutV2,
      SubLayout: TicketLayout,
      redirect: urlPath.myBusinessTripTicketV2.location,
      hasSidebar: true
    },
    {
      path: `${urlPath.myBusinessTripTicketV2.location}/:id`,
      Layout: MainLayoutV2,
      SubLayout: TicketLayout,
      Component: BusinessTripTicketV2,
      hasSidebar: true
    },
    {
      path: urlPath.businessTripTicketApprovalV2.location,
      Layout: MainLayoutV2,
      SubLayout: TicketLayout,
      Component: BusinessTripTicketV2,
      hasSidebar: true
    },
    {
      path: `${urlPath.businessTripTicketApprovalV2.location}/:id`,
      Layout: MainLayoutV2,
      SubLayout: TicketLayout,
      Component: BusinessTripTicketV2,
      hasSidebar: true
    },
    {
      path: urlPath.businessTripTicketAddV2.location,
      Layout: MainLayoutV2,
      SubLayout: TicketLayout,
      Component: AddBusinessTripTicketV2,
      hasSidebar: false,
      hasSubHeader: false,
      isDisableFooter: true,
      isHideDetailLayout: true
    },
    {
      path: `${urlPath.businessTripTicketEditV2.location}/:id`,
      Layout: MainLayoutV2,
      SubLayout: TicketLayout,
      Component: AddBusinessTripTicketV2,
      hasSidebar: false,
      hasSubHeader: false,
      isDisableFooter: true,
      isHideDetailLayout: true
    },
    {
      path: urlPath.myPurchaseRequestTicketV2.location,
      Layout: MainLayoutV2,
      SubLayout: TicketLayout,
      Component: PurchaseRequestTicketV2,
      hasSidebar: true
    },
    {
      path: urlPath.purchaseRequestTicketV2.location,
      Layout: MainLayoutV2,
      SubLayout: TicketLayout,
      redirect: urlPath.myPurchaseRequestTicketV2.location,
      hasSidebar: true
    },
    {
      path: `${urlPath.myPurchaseRequestTicketV2.location}/:id`,
      Layout: MainLayoutV2,
      SubLayout: TicketLayout,
      Component: PurchaseRequestTicketV2,
      hasSidebar: true
    },
    {
      path: urlPath.purchaseRequestTicketApprovalV2.location,
      Layout: MainLayoutV2,
      SubLayout: TicketLayout,
      Component: PurchaseRequestTicketV2,
      hasSidebar: true
    },
    {
      path: `${urlPath.purchaseRequestTicketApprovalV2.location}/:id`,
      Layout: MainLayoutV2,
      SubLayout: TicketLayout,
      Component: PurchaseRequestTicketV2,
      hasSidebar: true
    },
    {
      path: urlPath.purchaseRequestTicketAddV2.location,
      Layout: MainLayoutV2,
      SubLayout: TicketLayout,
      Component: AddPurchaseRequestTicketV2,
      hasSidebar: false,
      hasSubHeader: false,
      isDisableFooter: true,
      isHideDetailLayout: true
    },
    {
      path: `${urlPath.purchaseRequestTicketEditV2.location}/:id`,
      Layout: MainLayoutV2,
      SubLayout: TicketLayout,
      Component: AddPurchaseRequestTicketV2,
      hasSidebar: false,
      hasSubHeader: false,
      isDisableFooter: true,
      isHideDetailLayout: true
    },
    {
      path: urlPath.myEquipmentSupplyRequestTicketV2.location,
      Layout: MainLayoutV2,
      SubLayout: TicketLayout,
      Component: EquipmentSupplyRequestTicketV2,
      hasSidebar: true
    },
    {
      path: urlPath.equipmentSupplyRequestTicketV2.location,
      Layout: MainLayoutV2,
      SubLayout: TicketLayout,
      redirect: urlPath.myEquipmentSupplyRequestTicketV2.location,
      hasSidebar: true
    },
    {
      path: `${urlPath.myEquipmentSupplyRequestTicketV2.location}/:id`,
      Layout: MainLayoutV2,
      SubLayout: TicketLayout,
      Component: EquipmentSupplyRequestTicketV2,
      hasSidebar: true
    },
    {
      path: urlPath.equipmentSupplyRequestTicketApprovalV2.location,
      Layout: MainLayoutV2,
      SubLayout: TicketLayout,
      Component: EquipmentSupplyRequestTicketV2,
      hasSidebar: true
    },
    {
      path: `${urlPath.equipmentSupplyRequestTicketApprovalV2.location}/:id`,
      Layout: MainLayoutV2,
      SubLayout: TicketLayout,
      Component: EquipmentSupplyRequestTicketV2,
      hasSidebar: true
    },
    {
      path: urlPath.equipmentSupplyRequestTicketAddV2.location,
      Layout: MainLayoutV2,
      SubLayout: TicketLayout,
      Component: AddEquipmentSupplyRequestTicketV2,
      hasSidebar: false,
      hasSubHeader: false,
      isDisableFooter: true,
      isHideDetailLayout: true
    },
    {
      path: `${urlPath.equipmentSupplyRequestTicketEditV2.location}/:id`,
      Layout: MainLayoutV2,
      SubLayout: TicketLayout,
      Component: AddEquipmentSupplyRequestTicketV2,
      hasSidebar: false,
      hasSubHeader: false,
      isDisableFooter: true,
      isHideDetailLayout: true
    },
    {
      path: urlPath.myAdditionalWorkingTimeTicketV2.location,
      Layout: MainLayoutV2,
      SubLayout: TicketLayout,
      Component: AdditionalWorkingTimeTicketV2,
      hasSidebar: true
    },
    {
      path: urlPath.additionalWorkingTimeTicketV2.location,
      Layout: MainLayoutV2,
      SubLayout: TicketLayout,
      redirect: urlPath.myAdditionalWorkingTimeTicketV2.location,
      hasSidebar: true
    },
    {
      path: `${urlPath.myAdditionalWorkingTimeTicketV2.location}/:id`,
      Layout: MainLayoutV2,
      SubLayout: TicketLayout,
      Component: AdditionalWorkingTimeTicketV2,
      hasSidebar: true
    },
    {
      path: urlPath.additionalWorkingTimeTicketAddV2.location,
      Layout: MainLayoutV2,
      SubLayout: TicketLayout,
      Component: AddAdditionalWorkingTimeTicketV2,
      hasSidebar: false,
      hasSubHeader: false,
      isDisableFooter: true,
      isHideDetailLayout: true
    },
    {
      path: `${urlPath.additionalWorkingTimeTicketEditV2.location}/:id`,
      Layout: MainLayoutV2,
      SubLayout: TicketLayout,
      Component: AddAdditionalWorkingTimeTicketV2,
      hasSidebar: false,
      hasSubHeader: false,
      isDisableFooter: true,
      isHideDetailLayout: true
    },
    {
      path: urlPath.additionalWorkingTimeTicketApprovalV2.location,
      Layout: MainLayoutV2,
      SubLayout: TicketLayout,
      Component: AdditionalWorkingTimePackageV2,
      hasSidebar: true
    },
    {
      path: `${urlPath.additionalWorkingTimeTicketApprovalV2.location}/:id`,
      Layout: MainLayoutV2,
      SubLayout: TicketLayout,
      Component: AdditionalWorkingTimePackageV2,
      hasSidebar: true
    },
    {
      path: urlPath.hrConfirmV2.location,
      Layout: MainLayoutV2,
      SubLayout: TicketLayout,
      redirect: urlPath.helpdeskV2.location,
      hasSidebar: false,
      hasSubHeader: false
    },
    {
      path: urlPath.hrConfirmLeaveTicketV2.location,
      Layout: MainLayoutV2,
      SubLayout: TicketLayout,
      Component: LeaveTicketHrConfirmV2,
      hasSidebar: true,
      checkPath: urlPath.hrConfirmLeaveTicketV2.location,
      isUsePathV2: true
    },
    {
      path: `${urlPath.hrConfirmLeaveTicketV2.location}/:id`,
      Layout: MainLayoutV2,
      SubLayout: TicketLayout,
      Component: LeaveTicketHrConfirmV2,
      hasSidebar: true,
      checkPath: urlPath.hrConfirmLeaveTicketV2.location,
      isUsePathV2: true
    },
    {
      path: urlPath.hrConfirmExpenseTrackingRequestTicket.location,
      Layout: MainLayoutV2,
      SubLayout: TicketLayout,
      Component: ExpenseTrackingRequestTicketHrConfirm,
      hasSidebar: true,
      checkPath: urlPath.hrConfirmExpenseTrackingRequestTicket.location,
      isUsePathV2: true
    },
    {
      path: `${urlPath.hrConfirmExpenseTrackingRequestTicket.location}/:id`,
      Layout: MainLayoutV2,
      SubLayout: TicketLayout,
      Component: ExpenseTrackingRequestTicketHrConfirm,
      hasSidebar: true,
      checkPath: urlPath.hrConfirmExpenseTrackingRequestTicket.location,
      isUsePathV2: true
    },
    {
      path: urlPath.hrConfirmBusinessTripTicketV2.location,
      Layout: MainLayoutV2,
      SubLayout: TicketLayout,
      Component: BusinessTripHrConfirmV2,
      hasSidebar: true,
      checkPath: urlPath.hrConfirmBusinessTripTicketV2.location,
      isUsePathV2: true
    },
    {
      path: `${urlPath.hrConfirmBusinessTripTicketV2.location}/:id`,
      Layout: MainLayoutV2,
      SubLayout: TicketLayout,
      Component: BusinessTripHrConfirmV2,
      hasSidebar: true,
      checkPath: urlPath.hrConfirmBusinessTripTicketV2.location,
      isUsePathV2: true
    },
    {
      path: urlPath.hrConfirmOvertimeTicketV2.location,
      Layout: MainLayoutV2,
      SubLayout: TicketLayout,
      Component: OvertimeHrConfirmV2,
      hasSidebar: true,
      checkPath: urlPath.hrConfirmOvertimeTicketV2.location,
      isUsePathV2: true
    },
    {
      path: `${urlPath.hrConfirmOvertimeTicketV2.location}/:id`,
      Layout: MainLayoutV2,
      SubLayout: TicketLayout,
      Component: OvertimeHrConfirmV2,
      hasSidebar: true,
      checkPath: urlPath.hrConfirmOvertimeTicketV2.location,
      isUsePathV2: true
    },
    {
      path: urlPath.hrConfirmTransportationTicketV2.location,
      Layout: MainLayoutV2,
      SubLayout: TicketLayout,
      Component: TransportationHrConfirmV2,
      hasSidebar: true,
      checkPath: urlPath.hrConfirmTransportationTicketV2.location,
      isUsePathV2: true
    },
    {
      path: `${urlPath.hrConfirmTransportationTicketV2.location}/:id`,
      Layout: MainLayoutV2,
      SubLayout: TicketLayout,
      Component: TransportationHrConfirmV2,
      hasSidebar: true,
      checkPath: urlPath.hrConfirmTransportationTicketV2.location,
      isUsePathV2: true
    },
    {
      path: urlPath.biometricLogV2.location,
      Layout: MainLayoutV2,
      SubLayout: TicketLayout,
      Component: BiometricLogV2,
      hasSidebar: false
    },
    {
      path: urlPath.report.location,
      Layout: MainLayoutV2,
      SubLayout: ReportLayout,
      Component: ReportManagement
    },
    {
      path: urlPath.reportLeaveTicket.location,
      checkPath: urlPath.reportLeaveTicket.location,
      Layout: MainLayoutV2,
      SubLayout: ReportLayout,
      Component: ReportLeaveTicket,
      isUsePathV2: true
    },
    {
      path: urlPath.reportOvertimeTicket.location,
      checkPath: urlPath.reportOvertimeTicket.location,
      Layout: MainLayoutV2,
      SubLayout: ReportLayout,
      Component: ReportOvertimeTicket,
      isUsePathV2: true
    },
    {
      path: urlPath.reportPNL04.location,
      checkPath: urlPath.reportPNL04.location,
      Layout: MainLayoutV2,
      SubLayout: ReportLayout,
      Component: PNL04,
      isUsePathV2: true
    },
    {
      path: urlPath.reportBusinessTripTicket.location,
      checkPath: urlPath.reportBusinessTripTicket.location,
      Layout: MainLayoutV2,
      SubLayout: ReportLayout,
      Component: ReportBusinessTripTicket,
      isUsePathV2: true
    },
    {
      path: urlPath.reportDailyInventoryJournal.location,
      checkPath: urlPath.reportDailyInventoryJournal.location,
      Layout: MainLayoutV2,
      SubLayout: ReportLayout,
      Component: ReportDailyInventoryJournal,
      isUsePathV2: true
    },
    {
      path: urlPath.userGuideV2.location,
      Layout: MainLayoutV2,
      SubLayout: TicketLayout,
      Component: UserGuides,
      hasSidebar: false
    },
    {
      path: urlPath.topic.location,
      Layout: MainLayoutV2,
      SubLayout: TopicLayout,
      Component: TopicConversationBox
    },
    {
      path: `${urlPath.topic.location}/:id`,
      Layout: MainLayoutV2,
      SubLayout: TopicLayout,
      Component: TopicConversationBox
    },
    {
      path: urlPath.homeSub.location,
      Layout: MainLayoutV2,
      Component: HomeSub
    },
    {
      path: urlPath.qrScanner.location,
      Layout: MainLayoutV2,
      Component: HomeSub
    },
    {
      path: urlPath.biometricLogSub.location,
      Layout: MainLayoutV2,
      SubLayout: SubLayout,
      Component: BiometricLogV2,
      hasSidebar: false
    },
    {
      path: urlPath.myLeaveTicketSub.location,
      Layout: MainLayoutV2,
      SubLayout: SubLayout,
      Component: LeaveTicketV2,
      hasSidebar: false,
      hasSubSidebar: false
    },
    {
      path: `${urlPath.myLeaveTicketSub.location}/:id`,
      Layout: MainLayoutV2,
      SubLayout: SubLayout,
      Component: LeaveTicketV2,
      hasSidebar: false
    },
    {
      path: urlPath.leaveTicketAddSub.location,
      Layout: MainLayoutV2,
      SubLayout: SubLayout,
      Component: AddLeaveTicketSub,
      hasSidebar: false,
      hasSubHeader: false,
      isDisableFooter: true,
      isHideDetailLayout: true
    },
    {
      path: `${urlPath.leaveTicketEditSub.location}/:id`,
      Layout: MainLayoutV2,
      SubLayout: SubLayout,
      Component: AddLeaveTicketSub,
      hasSidebar: false,
      hasSubHeader: false,
      isDisableFooter: true,
      isHideDetailLayout: true
    },
    {
      path: urlPath.myHandoverTicketV2.location,
      Layout: MainLayoutV2,
      SubLayout: TicketLayout,
      Component: HandoverTicketV2,
      hasSidebar: true
    },
    {
      path: urlPath.handoverTicketApprovalV2.location,
      Layout: MainLayoutV2,
      SubLayout: TicketLayout,
      Component: HandoverTicketV2,
      hasSidebar: true
    },
    {
      path: `${urlPath.myHandoverTicketV2.location}/:id`,
      Layout: MainLayoutV2,
      SubLayout: TicketLayout,
      Component: HandoverTicketV2,
      hasSidebar: true
    },
    {
      path: `${urlPath.handoverTicketApprovalV2.location}/:id`,
      Layout: MainLayoutV2,
      SubLayout: TicketLayout,
      Component: HandoverTicketV2,
      hasSidebar: true
    },
    {
      path: urlPath.handoverTicketAddV2.location,
      Layout: MainLayoutV2,
      Component: AddHandoverTicketV2,
      hasSidebar: false,
      hasSubHeader: false,
      isDisableFooter: true,
      isHideDetailLayout: true
    },
    {
      path: `${urlPath.handoverTicketEditV2.location}/:id`,
      Layout: MainLayoutV2,
      Component: AddHandoverTicketV2,
      hasSidebar: false,
      hasSubHeader: false,
      isDisableFooter: true,
      isHideDetailLayout: true
    },
    {
      path: urlPath.purchase.location,
      Layout: MainLayoutV2,
      SubLayout: PurchaseSaleLayout,
      redirect: urlPath.receivePlan.location,
      hasSidebar: true,
      hasSubHeader: false,
      isDisableFooter: false
    },
    {
      path: urlPath.receivePlan.location,
      Layout: MainLayoutV2,
      SubLayout: PurchaseSaleLayout,
      Component: ReceivePlan,
      hasSidebar: true,
      hasSubHeader: false,
      isDisableFooter: false
    },
    {
      path: urlPath.ordersHistory.location,
      Layout: MainLayoutV2,
      SubLayout: PurchaseSaleLayout,
      Component: HistoryOrder,
      hasSidebar: true,
      hasSubHeader: false,
      isDisableFooter: false
    },
    {
      path: urlPath.purchaseOrder.location,
      Layout: MainLayoutV2,
      SubLayout: PurchaseSaleLayout,
      Component: PurchaseOrder,
      hasSidebar: true,
      hasSubHeader: false,
      isDisableFooter: false
    },
    {
      path: urlPath.purchaseOrderStamp.location,
      Layout: MainLayoutV2,
      SubLayout: PurchaseSaleLayout,
      Component: PurchaseOrderStamp,
      hasSidebar: true,
      hasSubHeader: false,
      isDisableFooter: false
    },
    {
      path: urlPath.purchaseOrderAdd.location,
      Layout: MainLayoutV2,
      SubLayout: PurchaseSaleLayout,
      Component: AddPurchaseOrder,
      hasSidebar: false,
      hasSubHeader: false,
      isDisableFooter: true
    },
    {
      path: `${urlPath.purchaseOrderEdit.location}/:id`,
      Layout: MainLayoutV2,
      SubLayout: PurchaseSaleLayout,
      Component: AddPurchaseOrder,
      hasSidebar: false,
      hasSubHeader: false,
      isDisableFooter: true
    },
    {
      path: `${urlPath.purchaseOrderDetail.location}/:id`,
      Layout: MainLayoutV2,
      SubLayout: PurchaseSaleLayout,
      Component: PurchaseOrderDetail,
      hasSidebar: false,
      hasSubHeader: false,
      isDisableFooter: false
    },
    {
      path: `${urlPath.purchaseOrderDetailCode.location}/:code`,
      Layout: MainLayoutV2,
      SubLayout: PurchaseSaleLayout,
      Component: PurchaseOrderDetail,
      hasSidebar: false,
      hasSubHeader: false,
      isDisableFooter: false
    },
    // GRPO REQUEST
    {
      path: urlPath.grpor.location,
      Layout: MainLayoutV2,
      SubLayout: PurchaseSaleLayout,
      Component: GRPOR,
      hasSidebar: true,
      hasSubHeader: false,
      isDisableFooter: false
    },
    {
      path: urlPath.grporAdd.location,
      Layout: MainLayoutV2,
      SubLayout: PurchaseSaleLayout,
      Component: AddGRPOR,
      hasSidebar: false,
      hasSubHeader: false,
      isDisableFooter: true
    },
    {
      path: `${urlPath.grporEdit.location}/:id`,
      Layout: MainLayoutV2,
      SubLayout: PurchaseSaleLayout,
      Component: AddGRPOR,
      hasSidebar: false,
      hasSubHeader: false,
      isDisableFooter: true
    },
    {
      path: `${urlPath.grporDetail.location}/:id`,
      Layout: MainLayoutV2,
      SubLayout: PurchaseSaleLayout,
      Component: GRPORDetail,
      hasSidebar: false,
      hasSubHeader: false,
      isDisableFooter: false
    },
    {
      path: `${urlPath.grporDetailCode.location}/:code`,
      Layout: MainLayoutV2,
      SubLayout: PurchaseSaleLayout,
      Component: GRPORDetail,
      hasSidebar: false,
      hasSubHeader: false,
      isDisableFooter: false
    },
    // GRPO
    {
      path: urlPath.grpo.location,
      Layout: MainLayoutV2,
      SubLayout: PurchaseSaleLayout,
      Component: GRPO,
      hasSidebar: true,
      hasSubHeader: false,
      isDisableFooter: false
    },
    {
      path: urlPath.grpoAdd.location,
      Layout: MainLayoutV2,
      SubLayout: PurchaseSaleLayout,
      Component: AddGRPO,
      hasSidebar: false,
      hasSubHeader: false,
      isDisableFooter: true
    },
    {
      path: `${urlPath.grpoEdit.location}/:id`,
      Layout: MainLayoutV2,
      SubLayout: PurchaseSaleLayout,
      Component: AddGRPO,
      hasSidebar: false,
      hasSubHeader: false,
      isDisableFooter: true
    },
    {
      path: `${urlPath.grpoDetail.location}/:id`,
      Layout: MainLayoutV2,
      SubLayout: PurchaseSaleLayout,
      Component: GRPODetail,
      hasSidebar: false,
      hasSubHeader: false,
      isDisableFooter: false
    },
    {
      path: `${urlPath.grpoDetailCode.location}/:code`,
      Layout: MainLayoutV2,
      SubLayout: PurchaseSaleLayout,
      Component: GRPODetail,
      hasSidebar: false,
      hasSubHeader: false,
      isDisableFooter: false
    },
    // QC RESULT
    {
      path: urlPath.qcResult.location,
      Layout: MainLayoutV2,
      SubLayout: PurchaseSaleLayout,
      redirect: urlPath.grpor.location,
      hasSidebar: true,
      hasSubHeader: false,
      isDisableFooter: false
    },
    {
      path: urlPath.qcResultAdd.location,
      Layout: MainLayoutV2,
      SubLayout: PurchaseSaleLayout,
      Component: AddQcResult,
      hasSidebar: false,
      hasSubHeader: false,
      isDisableFooter: true
    },
    {
      path: `${urlPath.qcResultEdit.location}/:id`,
      Layout: MainLayoutV2,
      SubLayout: PurchaseSaleLayout,
      Component: AddQcResult,
      hasSidebar: false,
      hasSubHeader: false,
      isDisableFooter: true
    },
    {
      path: `${urlPath.qcResultDetail.location}/:id`,
      Layout: MainLayoutV2,
      SubLayout: PurchaseSaleLayout,
      Component: QcResultDetail,
      hasSidebar: false,
      hasSubHeader: false,
      isDisableFooter: false
    },
    // HẠCH TOÁN - ACCOUNTING
    {
      path: urlPath.accounting.location,
      Layout: MainLayoutV2,
      SubLayout: PurchaseSaleLayout,
      redirect: urlPath.grpo.location,
      hasSidebar: true,
      hasSubHeader: false,
      isDisableFooter: false
    },
    {
      path: urlPath.accountingAdd.location,
      Layout: MainLayoutV2,
      SubLayout: PurchaseSaleLayout,
      Component: AddAccounting,
      hasSidebar: false,
      hasSubHeader: false,
      isDisableFooter: true
    },
    {
      path: `${urlPath.accountingEdit.location}/:id`,
      Layout: MainLayoutV2,
      SubLayout: PurchaseSaleLayout,
      Component: AddAccounting,
      hasSidebar: false,
      hasSubHeader: false,
      isDisableFooter: true
    },
    {
      path: `${urlPath.accountingDetail.location}/:id`,
      Layout: MainLayoutV2,
      SubLayout: PurchaseSaleLayout,
      Component: AccountingDetail,
      hasSidebar: false,
      hasSubHeader: false,
      isDisableFooter: false
    },
    // ĐỀ NGHỊ THANH TOÁN - AP INVOICE
    {
      path: urlPath.apInvoice.location,
      Layout: MainLayoutV2,
      SubLayout: PurchaseSaleLayout,
      redirect: urlPath.grpo.location,
      hasSidebar: true,
      hasSubHeader: false,
      isDisableFooter: false
    },
    {
      path: urlPath.apInvoiceAdd.location,
      Layout: MainLayoutV2,
      SubLayout: PurchaseSaleLayout,
      Component: AddAPInvoice,
      hasSidebar: false,
      hasSubHeader: false,
      isDisableFooter: true
    },
    {
      path: `${urlPath.apInvoiceDetail.location}/:id`,
      Layout: MainLayoutV2,
      SubLayout: PurchaseSaleLayout,
      Component: APInvoiceDetail,
      hasSidebar: false,
      hasSubHeader: false,
      isDisableFooter: false
    },
    // ĐỀ NGHỊ MUA HÀNG - PURCHASE REQUEST
    {
      path: urlPath.purchaseRequest.location,
      Layout: MainLayoutV2,
      SubLayout: PurchaseSaleLayout,
      Component: PurchaseRequest,
      hasSidebar: true,
      hasSubHeader: false,
      isDisableFooter: false
    },
    {
      path: urlPath.purchaseRequestAdd.location,
      Layout: MainLayoutV2,
      SubLayout: PurchaseSaleLayout,
      Component: AddPurchaseRequest,
      hasSidebar: false,
      hasSubHeader: false,
      isDisableFooter: true
    },
    {
      path: `${urlPath.purchaseRequestEdit.location}/:id`,
      Layout: MainLayoutV2,
      SubLayout: PurchaseSaleLayout,
      Component: AddPurchaseRequest,
      hasSidebar: false,
      hasSubHeader: false,
      isDisableFooter: true
    },
    {
      path: `${urlPath.purchaseRequestDetail.location}/:id`,
      Layout: MainLayoutV2,
      SubLayout: PurchaseSaleLayout,
      Component: PurchaseRequestDetail,
      hasSidebar: false,
      hasSubHeader: false,
      isDisableFooter: false
    },
    {
      path: `${urlPath.purchaseRequestDetailCode.location}/:code`,
      Layout: MainLayoutV2,
      SubLayout: PurchaseSaleLayout,
      Component: PurchaseRequestDetail,
      hasSidebar: false,
      hasSubHeader: false,
      isDisableFooter: false
    },
    // BÁO GIÁ MUA HÀNG - PURCHASE QUOTATION
    {
      path: urlPath.purchaseQuotation.location,
      Layout: MainLayoutV2,
      SubLayout: PurchaseSaleLayout,
      Component: PurchaseQuotation,
      hasSidebar: true,
      hasSubHeader: false,
      isDisableFooter: false
    },
    {
      path: urlPath.purchaseQuotationAdd.location,
      Layout: MainLayoutV2,
      SubLayout: PurchaseSaleLayout,
      Component: AddPurchaseQuotation,
      hasSidebar: false,
      hasSubHeader: false,
      isDisableFooter: true,
      isHideDetailLayout: false
    },
    {
      path: `${urlPath.purchaseQuotationEdit.location}/:id`,
      Layout: MainLayoutV2,
      SubLayout: PurchaseSaleLayout,
      Component: AddPurchaseQuotation,
      hasSidebar: false,
      hasSubHeader: false,
      isDisableFooter: true,
      isHideDetailLayout: false
    },
    {
      path: `${urlPath.purchaseQuotationDetail.location}/:id`,
      Layout: MainLayoutV2,
      SubLayout: PurchaseSaleLayout,
      Component: PurchaseQuotationDetail,
      hasSidebar: false,
      hasSubHeader: false,
      isDisableFooter: false
    },
    {
      path: `${urlPath.purchaseQuotationDetailCode.location}/:code`,
      Layout: MainLayoutV2,
      SubLayout: PurchaseSaleLayout,
      Component: PurchaseQuotationDetail,
      hasSidebar: false,
      hasSubHeader: false,
      isDisableFooter: false
    },
    // CÔNG NỢ - DEBT
    {
      path: urlPath.debtGroup.location,
      Layout: MainLayoutV2,
      SubLayout: PurchaseSaleLayout,
      Component: DebtGroup,
      hasSidebar: true,
      hasSubHeader: false,
      isDisableFooter: false
    },
    {
      path: urlPath.debt.location,
      Layout: MainLayoutV2,
      SubLayout: PurchaseSaleLayout,
      Component: Debt,
      hasSidebar: true,
      hasSubHeader: false,
      isDisableFooter: false
    },
    // BỘ CHỨNG TỪ - SET OF DOCUMENT
    {
      path: urlPath.setOfDocument.location,
      Layout: MainLayoutV2,
      SubLayout: PurchaseSaleLayout,
      Component: TemplateSetOfDocument,
      hasSidebar: true,
      hasSubHeader: false,
      isDisableFooter: false
    },
    // LOẠI CHỨNG TỪ - DOCUMENT TYPE
    {
      path: urlPath.documentType.location,
      Layout: MainLayoutV2,
      SubLayout: PurchaseSaleLayout,
      Component: DocumentType,
      hasSidebar: true,
      hasSubHeader: false,
      isDisableFooter: false
    },
    // REF KEY
    {
      path: urlPath.refKey.location,
      Layout: MainLayoutV2,
      SubLayout: PurchaseSaleLayout,
      Component: RefKey,
      hasSidebar: true,
      hasSubHeader: false,
      isDisableFooter: false
    },
    // ITEM MASTER DATA
    {
      path: urlPath.itemMasterData.location,
      Layout: MainLayoutV2,
      SubLayout: ItemMasterDataLayout,
      Component: ItemMasterData,
      hasSidebar: true,
      hasSubHeader: false,
      isDisableFooter: false
    },
    {
      path: urlPath.itemMasterDataAdd.location,
      Layout: MainLayoutV2,
      SubLayout: ItemMasterDataLayout,
      Component: AddItemMasterData,
      hasSidebar: false,
      hasSubHeader: false,
      isDisableFooter: true
    },
    {
      path: `${urlPath.itemMasterDataEdit.location}/:id`,
      Layout: MainLayoutV2,
      SubLayout: ItemMasterDataLayout,
      Component: AddItemMasterData,
      hasSidebar: false,
      hasSubHeader: false,
      isDisableFooter: true
    },
    {
      path: urlPath.itemType.location,
      Layout: MainLayoutV2,
      SubLayout: ItemMasterDataLayout,
      Component: ItemType,
      hasSidebar: true,
      hasSubHeader: false,
      isDisableFooter: false
    },
    {
      path: urlPath.itemGroup.location,
      Layout: MainLayoutV2,
      SubLayout: ItemMasterDataLayout,
      Component: ItemGroup,
      hasSidebar: true,
      hasSubHeader: false,
      isDisableFooter: false
    }
  ];

  const commonRoutes: RouteType[] = [
    {
      path: urlPath.signinOidc.location,
      Component: SigninOidc
    },
    {
      path: urlPath.signoutOidc.location,
      Component: SignoutOidc
    },
    {
      path: urlPath.signoutCallbackOidc.location,
      Component: SignoutCallbackOidc
    },
    {
      path: urlPath.serverError.location,
      Layout: MainLayout,
      Component: InternalServer
    },
    {
      path: '*',
      Layout: MainLayout,
      Component: NotFound
    },
    {
      path: '/login',
      Component: Login
    }
  ];

  const routeElements = useRoutes(
    [...routesV1, ...routesV2, ...commonRoutes].map(
      ({
        path,
        redirect,
        Layout,
        SubLayout,
        Component,
        permission,
        checkPath,
        isUsePathV2,
        hasSidebar,
        hasSubSidebar,
        isDisableFooter,
        isHideDetailLayout,
        hasSubHeader
      }) => {
        let result = {};

        const innerPaths = isUsePathV2 ? pathsV2 : paths;
        const resComponent = redirect ? <Navigate to={redirect} /> : <Component />;

        if (!SubLayout && Layout)
          result = {
            path,
            element: (
              <Layout isDisableFooter={isDisableFooter}>
                <Suspense>
                  {permission ? (
                    hasPermission(path, permission, resources) ? (
                      resComponent
                    ) : (
                      <Forbidden />
                    )
                  ) : !checkPath || innerPaths.length === 0 || innerPaths?.includes(checkPath) ? (
                    resComponent
                  ) : (
                    <NotFound />
                  )}
                </Suspense>
              </Layout>
            )
          };
        else if (SubLayout && Layout)
          result = {
            path,
            element: (
              <Layout isDisableFooter={isDisableFooter}>
                <Suspense>
                  <SubLayout
                    hasSidebar={hasSidebar}
                    hasSubSidebar={hasSubSidebar}
                    hasSubHeader={hasSubHeader}
                    isHideDetailLayout={isHideDetailLayout}
                  >
                    <Suspense>
                      {permission ? (
                        hasPermission(path, permission, resources) ? (
                          resComponent
                        ) : (
                          <Forbidden />
                        )
                      ) : !checkPath || innerPaths.length === 0 || innerPaths?.includes(checkPath) ? (
                        resComponent
                      ) : (
                        <NotFound />
                      )}
                    </Suspense>
                  </SubLayout>
                </Suspense>
              </Layout>
            )
          };
        else
          result = {
            path,
            element: resComponent
          };

        return result;
      }
    )
  );

  return routeElements;
}
