import { FlatItem } from 'src/types/elements.type';
import { IResource, ResourceAdd } from 'src/types/master_data/resource.type';
import { PagedList } from 'src/types/pagedList.type';
import { http } from 'src/utils/http';

const controller = 'master-data/resources';

export const getResources = async (
  searchCondition: string | undefined,
  sequenceStatus: string,
  page: number,
  pageSize: number,
  orderBy?: string,
  appInfoId?: number
) => {
  return await http.get<PagedList<IResource>>(`${controller}`, {
    params: {
      searchCondition,
      sequenceStatus,
      page,
      pageSize,
      orderBy,
      appInfoId
    }
  });
};

export const getResource = async (id: number) => {
  return http.get<IResource>(`${controller}/${id}`);
};

export const addResource = async (resource: ResourceAdd) => {
  return http.post<ResourceAdd>(controller, resource);
};

export const updateResource = async (id: number, resource: IResource) => {
  return await http.put(`${controller}/${id}`, resource);
};

export const deleteResource = async (id: number) => {
  await http.delete(`${controller}/${id}`);
};

export const getResourcesDropdown = async (appInfoId?: number) => {
  return http.get<FlatItem[]>(`${controller}/dropdown`, {
    params: {
      appInfoId
    }
  });
};
