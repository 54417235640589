import { useQuery } from '@tanstack/react-query';
import { Flex, Select, SelectProps, Typography } from 'antd';
import { debounce } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { getItemsMasterDataByCondition } from 'src/apis/hr_ticket/itemMasterData.api';
import { ItemMasterData } from 'src/types/itemMasterData.type';
type PropsType = SelectProps & {
  setSearchValue: (value: React.SetStateAction<string>) => void;
};

export default function ItemMasterDataSelect({
  value,
  searchValue,
  setSearchValue,
  onChange,
  style,
  placeholder
}: PropsType) {
  const [data, setData] = useState<ItemMasterData[]>([]);
  const [searchCondition, setSearchCondition] = useState<string>('');

  const itemsMasterDataDropdownQuery = useQuery({
    queryKey: ['itemsMasterDataDropdown', searchCondition],
    queryFn: () => getItemsMasterDataByCondition(searchCondition),
    staleTime: 60 * 1000,
    enabled: !!searchCondition
  });

  useEffect(() => {
    if (itemsMasterDataDropdownQuery.data) {
      setData(itemsMasterDataDropdownQuery.data.data);
    }
  }, [itemsMasterDataDropdownQuery.data]);

  useEffect(() => {
    if (searchValue) {
      setSearchCondition(searchValue);
    }

    return () => setSearchValue('');
  }, [searchValue, setSearchValue]);

  const debouncedSearch = useMemo(() => debounce((term) => setSearchCondition(term), 1000), []);

  return (
    <Select
      showSearch
      value={itemsMasterDataDropdownQuery.isFetching ? undefined : value}
      placeholder={placeholder}
      style={style}
      onChange={onChange}
      defaultActiveFirstOption={false}
      filterOption={false}
      onSearch={(value) => debouncedSearch(value)}
      notFoundContent={null}
      optionLabelProp='itemNo'
      options={data.map((item) => ({
        value: JSON.stringify(item),
        label: (
          <Flex vertical>
            <Typography.Text strong>Mã: {item.itemNo}</Typography.Text>
            <Typography.Text style={{ whiteSpace: 'break-spaces', fontSize: 13, fontWeight: 400 }}>
              {item.itemDescription}
            </Typography.Text>
          </Flex>
        ),
        itemNo: item.itemNo
      }))}
      loading={itemsMasterDataDropdownQuery.isFetching}
    />
  );
}
