import { useQueries } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { Dispatch, SetStateAction, createContext, useEffect, useState } from 'react';
import { getWebConfigs } from 'src/apis/helpdesk/webConfig.api';
import { getRoleUsersFullAccess } from 'src/apis/master_data/roleUser.api';
import { checkAdmin } from 'src/apis/master_data/user.api';
import { WebConfigEn } from 'src/constants/webConfig.enum';
import i18n from 'src/translation/i18n';
import { IEmployeeWithDepartment, UserInfo } from 'src/types/master_data/employee.type';
import { IRole } from 'src/types/master_data/role.type';
import { User } from 'src/types/user.type';

import { Language, Theme, WebConfigAdd } from 'src/types/webConfig.type';
import { getAccessTokenFromLS } from 'src/utils/auth';
import { getResourceFromRoles } from 'src/utils/utils';

interface AppContextInterface {
  ticketSelected: string | undefined;
  setTicketSelected: Dispatch<SetStateAction<string | undefined>>;
  isSidebarCollapsed: boolean;
  setIsSidebarCollapsed: Dispatch<SetStateAction<boolean>>;
  isTicketDetailCollapsed: boolean;
  setIsTicketDetailCollapsed: Dispatch<SetStateAction<boolean>>;
  isDetailSidebarCollapsed: boolean;
  setIsDetailSidebarCollapsed: Dispatch<SetStateAction<boolean>>;
  theme: Theme | undefined;
  setTheme: Dispatch<SetStateAction<Theme | undefined>>;
  language: Language | undefined;
  setLanguage: Dispatch<SetStateAction<Language | undefined>>;
  userInfo: UserInfo | null;
  setUserInfo: Dispatch<SetStateAction<UserInfo | null>>;
  userInfoQr: IEmployeeWithDepartment | undefined;
  setUserInfoQr: Dispatch<SetStateAction<IEmployeeWithDepartment | undefined>>;
  resources: Record<string, string[]> | undefined;
  setResources: Dispatch<SetStateAction<Record<string, string[]> | undefined>>;
  roles: IRole[] | undefined;
  setRoles: Dispatch<SetStateAction<IRole[] | undefined>>;
  qrCode: string | undefined;
  setQrCode: Dispatch<SetStateAction<string | undefined>>;
  isUserAdmin: boolean | undefined;
  setIsUserAdmin: Dispatch<SetStateAction<boolean | undefined>>;
}

const userInfoJson = localStorage.getItem('userInfo');
const isAdminJson = localStorage.getItem('isAdmin');

const initialAppContext: AppContextInterface = {
  ticketSelected: undefined,
  setTicketSelected: () => null,
  isSidebarCollapsed: false,
  setIsSidebarCollapsed: () => null,
  isDetailSidebarCollapsed: false,
  setIsDetailSidebarCollapsed: () => null,
  isTicketDetailCollapsed: true,
  setIsTicketDetailCollapsed: () => null,
  theme: 'light',
  setTheme: () => null,
  language: 'vi',
  setLanguage: () => null,
  userInfo: userInfoJson ? JSON.parse(userInfoJson) : null,
  setUserInfo: () => null,
  userInfoQr: undefined,
  setUserInfoQr: () => null,
  resources: {},
  setResources: () => null,
  roles: [],
  setRoles: () => null,
  qrCode: undefined,
  setQrCode: () => null,
  isUserAdmin: isAdminJson ? JSON.parse(isAdminJson) : null,
  setIsUserAdmin: () => null
};

export const AppContext = createContext<AppContextInterface>(initialAppContext);

export const AppProvider = ({ children }: { children: React.ReactNode }) => {
  const [ticketSelected, setTicketSelected] = useState<string | undefined>(initialAppContext.ticketSelected);
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(initialAppContext.isSidebarCollapsed);
  const [isTicketDetailCollapsed, setIsTicketDetailCollapsed] = useState(initialAppContext.isTicketDetailCollapsed);
  const [isDetailSidebarCollapsed, setIsDetailSidebarCollapsed] = useState(initialAppContext.isDetailSidebarCollapsed);
  const [theme, setTheme] = useState<Theme | undefined>(initialAppContext.theme);
  const [language, setLanguage] = useState<Language | undefined>(initialAppContext.language);
  const [userInfo, setUserInfo] = useState<UserInfo | null>(initialAppContext.userInfo);
  const [userInfoQr, setUserInfoQr] = useState<IEmployeeWithDepartment | undefined>(initialAppContext.userInfoQr);
  const [resources, setResources] = useState<Record<string, string[]> | undefined>(initialAppContext.resources);
  const [roles, setRoles] = useState<IRole[] | undefined>(initialAppContext.roles);
  const [qrCode, setQrCode] = useState<string | undefined>(initialAppContext.qrCode);
  const [isUserAdmin, setIsUserAdmin] = useState<boolean | undefined>(initialAppContext.isUserAdmin);
  const token = getAccessTokenFromLS();

  useQueries({
    queries: [
      {
        queryKey: ['webConfigs'],
        queryFn: async () => await getWebConfigs(),
        staleTime: 60 * 1000,
        enabled: !!token,
        onSuccess: (data: AxiosResponse<WebConfigAdd[], any>) => {
          if (data?.data && data?.data.length > 0) {
            const result = Object.fromEntries(data?.data.map((item) => [item.key, item.value]));
            setTheme(result[WebConfigEn.theme] as Theme);
            setLanguage(result[WebConfigEn.language] as Language);
          }
        }
      },
      {
        queryKey: ['roleUsersFullAccess'],
        queryFn: async () => await getRoleUsersFullAccess(),
        staleTime: 60 * 1000,
        enabled: !!token,
        onSuccess: (data: AxiosResponse<User, any>) => {
          setRoles(data?.data?.roles);
          setUserInfo(data?.data?.employee);
          if (data.data.employee) {
            localStorage.setItem('userInfo', JSON.stringify(data.data.employee));
          }
          const newResource = getResourceFromRoles(data?.data?.roles);
          setResources(newResource);
        }
      },
      {
        queryKey: ['checkAdmin'],
        queryFn: async () => await checkAdmin(),
        enabled: !!token,
        onSuccess: (data: AxiosResponse<boolean, any>) => {
          if (data.data) {
            localStorage.setItem('isAdmin', JSON.stringify(data.data));
          }
          setIsUserAdmin(data.data);
        }
      }
    ]
  });

  useEffect(() => {
    i18n.language === 'en' ? i18n.changeLanguage('vi') : i18n.changeLanguage('en');
    if (i18n.language !== language) {
      i18n.changeLanguage(language);
    }
  }, [language]);

  return (
    <AppContext.Provider
      value={{
        ticketSelected,
        setTicketSelected,
        isSidebarCollapsed,
        setIsSidebarCollapsed,
        isDetailSidebarCollapsed,
        setIsDetailSidebarCollapsed,
        isTicketDetailCollapsed,
        setIsTicketDetailCollapsed,
        theme,
        setTheme,
        language,
        setLanguage,
        userInfo,
        setUserInfo,
        userInfoQr,
        setUserInfoQr,
        resources,
        setResources,
        roles,
        setRoles,
        qrCode,
        setQrCode,
        isUserAdmin,
        setIsUserAdmin
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
