import { useQuery } from '@tanstack/react-query';
import { Select } from 'antd';
import { DefaultOptionType } from 'antd/es/select';
import { getBranchesDropdown } from 'src/apis/master_data/branch.api';

type Props = {
  value?: number | undefined;
  style?: React.CSSProperties | undefined;
  disabled?: boolean;
  onChange?: ((value: number, option: DefaultOptionType | DefaultOptionType[]) => void) | undefined;
  allowClear?: boolean | undefined;
  status?: '' | 'error' | 'warning' | undefined;
  placeholder?: React.ReactNode;
  className?: string;
  inCludeBranches?: number[];
};

export default function BranchSelect({
  value,
  style,
  allowClear = true,
  onChange,
  placeholder,
  disabled,
  inCludeBranches
}: Props) {
  const branchesDropdownQuery = useQuery({
    queryKey: ['branchesDropdown'],
    queryFn: () => getBranchesDropdown(),
    staleTime: 60 * 1000
  });

  return (
    <Select
      allowClear={allowClear}
      value={branchesDropdownQuery.isFetching ? undefined : value}
      style={style}
      placeholder={placeholder}
      onChange={onChange}
      disabled={disabled}
      options={branchesDropdownQuery.data?.data
        .filter((branch) => (inCludeBranches ? inCludeBranches?.includes(branch.id) : branch))
        .map((item) => ({
          value: item.id,
          label: item.name,
          branch: item
        }))}
      showSearch
      filterOption={(input, option) => (option?.label?.toString() ?? '').toLowerCase()?.includes(input.toLowerCase())}
      loading={branchesDropdownQuery.isFetching}
    />
  );
}
