import { useQuery } from '@tanstack/react-query';
import { Select, type SelectProps } from 'antd';
import { getHRTicketCategoriesDropdown } from 'src/apis/master_data/hrTicketCategory.api';
import type { SelectOptionType } from 'src/types/elements.type';

type Props = SelectProps & {
  value?: number | number[];
  style?: React.CSSProperties | undefined;
  allowClear?: boolean | undefined;
  onChange?: (value: number | number[], option: SelectOptionType) => void;
  disabled?: boolean;
  placeholder?: string;
  mode?: 'multiple' | 'tags';
};

export default function HRTicketCategorySelect({
  value,
  onChange,
  style,
  allowClear,
  disabled,
  placeholder,
  mode
}: Props) {
  const hrTicketCategoriesDropdownQuery = useQuery({
    queryKey: ['HRTicketCategoryDropdown'],
    queryFn: () => getHRTicketCategoriesDropdown(),
    staleTime: 60 * 1000
  });

  return (
    <Select
      mode={mode}
      disabled={disabled}
      allowClear={allowClear}
      placeholder={placeholder}
      value={hrTicketCategoriesDropdownQuery.isFetching ? undefined : value}
      style={style}
      onChange={onChange}
      options={hrTicketCategoriesDropdownQuery.data?.data.map((data) => ({
        value: data.id,
        label: data.name,
        data: data
      }))}
      showSearch
      filterOption={(input, option) => (option?.label?.toString() ?? '').toLowerCase()?.includes(input.toLowerCase())}
      loading={hrTicketCategoriesDropdownQuery.isFetching}
    />
  );
}
