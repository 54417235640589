import { App as AntdApp, ConfigProvider, theme } from 'antd';
import dayjs from 'dayjs';
import { useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import './App.scss';
import urlPath from './constants/urlPath';
import { AppContext, AppProvider } from './contexts/app.context';
import useRouteElements from './hooks/useRouteElements';

import 'dayjs/locale/vi';
dayjs.locale('vi');

const { defaultAlgorithm, darkAlgorithm } = theme;

function App() {
  const { theme, resources, roles } = useContext(AppContext);
  const routeElements = useRouteElements(resources, roles);
  const location = useLocation();
  const isHomePage = location.pathname === urlPath.homeV2.location || location.pathname === urlPath.home.location;

  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      if ('serviceWorker' in navigator && navigator.serviceWorker.controller) {
        navigator.serviceWorker.controller.postMessage({
          type: 'CHECK_VERSION_AND_CLEAR_CACHE',
          data: {}
        });
      }
    }
  }, []);

  return (
    <div className={`app-container ${isHomePage ? 'home' : ''} `}>
      <ConfigProvider
        componentSize='middle'
        theme={{
          components: {
            Divider: {
              colorSplit: 'var(--divider-color)'
            }
          },
          algorithm: theme === 'dark' ? darkAlgorithm : defaultAlgorithm,
          token: {
            borderRadius: 4,
            colorSplit: 'grey'
          }
        }}
      >
        <AppProvider>
          <AntdApp>{routeElements}</AntdApp>
        </AppProvider>
      </ConfigProvider>
    </div>
  );
}

export default App;
