import { HierarchicalItem } from 'src/types/elements.type';
import { BoardAdd, IBoard } from 'src/types/master_data/board.type';
import { PagedList } from 'src/types/pagedList.type';
import { http } from 'src/utils/http';

const controller = 'master-data/boards';

export const getBoards = async (sequenceStatus: string, page: number, pageSize: number, orderBy?: string) => {
  return await http.get<PagedList<IBoard>>(`${controller}`, {
    params: {
      sequenceStatus,
      page,
      pageSize,
      orderBy
    }
  });
};

export const getBoard = async (id: number) => {
  return http.get<IBoard>(`${controller}/${id}`);
};

export const addBoard = async (board: BoardAdd) => {
  return http.post<BoardAdd>(controller, board);
};

export const updateBoard = async (id: number, board: IBoard) => {
  return await http.put<IBoard[]>(`${controller}/${id}`, board);
};

export const deleteBoard = async (id: number) => {
  await http.delete(`${controller}/${id}`);
};

export const getBoardsDropdown = async () => {
  return await http.get<HierarchicalItem[]>(`${controller}/dropdown`);
};
