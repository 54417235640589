import { useQuery } from '@tanstack/react-query';
import { TreeSelect } from 'antd';
import { useEffect, useState } from 'react';
import { getGeneralAccountingAccountsDropdown } from 'src/apis/master_data/generalAccountingAccount.api';
import { TreeItem } from 'src/types/elements.type';
import { buildHierarchySelect } from 'src/utils/collection';

interface Props {
  value?: React.SetStateAction<number | undefined>;
  style?: React.CSSProperties | undefined;
  allowClear?: boolean | undefined;
  onChange?: (value: React.SetStateAction<number | undefined>) => void;
  accountingAccountTypeId: number;
}

export default function GeneralAccountingAccountTreeSelect({
  value,
  style,
  allowClear,
  onChange,
  accountingAccountTypeId
}: Props) {
  const [generalAccountingAccountTreeItem, setGeneralAccountingAccountTreeItem] = useState<TreeItem[] | undefined>(
    undefined
  );

  const generalAccountingAccountsDropdownQuery = useQuery({
    queryKey: ['generalAccountingAccountsDropdown', accountingAccountTypeId],
    queryFn: () => getGeneralAccountingAccountsDropdown(accountingAccountTypeId)
  });

  useEffect(() => {
    const result = buildHierarchySelect(generalAccountingAccountsDropdownQuery.data?.data);
    setGeneralAccountingAccountTreeItem(result);
  }, [generalAccountingAccountsDropdownQuery.data?.data]);

  return (
    <TreeSelect
      treeLine={true}
      value={generalAccountingAccountsDropdownQuery.isFetching ? undefined : value}
      showSearch
      treeDefaultExpandAll
      style={style}
      dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
      treeNodeFilterProp='title'
      allowClear={allowClear}
      onChange={onChange}
      treeData={generalAccountingAccountTreeItem}
      loading={generalAccountingAccountsDropdownQuery.isFetching}
      filterTreeNode={(inputValue, treeNode) => {
        return treeNode.title?.toString().toLocaleLowerCase().includes(inputValue.toLocaleLowerCase()) ?? false;
      }}
    />
  );
}
