import { FlatItem } from 'src/types/elements.type';
import { IJobCategory, JobCategoryAdd } from 'src/types/master_data/jobCategory.type';
import { IJobTask } from 'src/types/master_data/jobTask.type';
import { PagedList } from 'src/types/pagedList.type';
import { http } from 'src/utils/http';

const controller = 'master-data/job-categories';

export async function getJobCategoriesDropdown() {
  return await http.get<FlatItem[]>(`${controller}/dropdown`);
}

export const getJobCategories = async (
  searchCondition: string | undefined,
  sequenceStatus: string,
  page: number,
  pageSize: number,
  orderBy?: string
) => {
  return await http.get<PagedList<IJobCategory>>(`${controller}`, {
    params: {
      name: searchCondition,
      sequenceStatus,
      page,
      pageSize,
      orderBy
    }
  });
};

export const getJobCategory = async (id: number) => {
  return http.get<IJobCategory>(`${controller}/${id}`);
};

export const getJobTasksDropdown = async () => {
  return http.get<IJobTask[]>(`${controller}/job-tasks/dropdown`);
};

export const addJobCategory = async (jobCategory: JobCategoryAdd) => {
  return http.post<JobCategoryAdd>(controller, jobCategory);
};

export const updateJobCategory = async (id: number, jobCategory: IJobCategory) => {
  return await http.put<IJobCategory[]>(`${controller}/${id}`, jobCategory);
};

export const deleteJobCategory = async (id: number) => {
  await http.delete(`${controller}/${id}`);
};
