import { useQuery } from '@tanstack/react-query';
import { Select } from 'antd';
import { getOrganizationChartsDropdown } from 'src/apis/master_data/organizationChart.api';

interface Props {
  value?: React.SetStateAction<number | undefined>;
  style?: React.CSSProperties | undefined;
  allowClear?: boolean | undefined;
  onChange?: ((value: number | ((prevState: number | undefined) => number | undefined)) => void) | undefined;
}

export default function OrganizationChartSelect({ value, style, allowClear, onChange }: Props) {
  const organizationChartQuery = useQuery({
    queryKey: ['organizationChartsDropdown'],
    queryFn: () => getOrganizationChartsDropdown(),
    staleTime: 5 * 60 * 1000
  });

  return (
    <Select
      allowClear={allowClear}
      value={organizationChartQuery.isFetching ? undefined : value}
      style={style}
      onChange={onChange}
      options={organizationChartQuery.data?.data.map((data) => ({
        value: data.id,
        label: data.name
      }))}
      showSearch
      filterOption={(input, option) => (option?.label?.toString() ?? '').toLowerCase()?.includes(input.toLowerCase())}
      loading={organizationChartQuery.isFetching}
    />
  );
}
