import { useQuery } from '@tanstack/react-query';
import { Select, SelectProps } from 'antd';
import { getBoardsDropdown } from 'src/apis/master_data/board.api';

export default function BoardSelect({ value, onChange, style, allowClear }: SelectProps) {
  const boardsDropdownQuery = useQuery({
    queryKey: ['boardsDropdown'],
    queryFn: () => getBoardsDropdown(),
    staleTime: 60 * 1000
  });

  return (
    <Select
      allowClear={allowClear}
      value={boardsDropdownQuery.isFetching ? undefined : value}
      style={style}
      onChange={onChange}
      options={boardsDropdownQuery.data?.data.map((data) => ({ value: data.id, label: data.name }))}
      showSearch
      filterOption={(input, option) => (option?.label?.toString() ?? '').toLowerCase()?.includes(input.toLowerCase())}
      loading={boardsDropdownQuery.isFetching}
    />
  );
}
