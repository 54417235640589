import { useQuery } from '@tanstack/react-query';
import { TreeSelect } from 'antd';
import { useEffect, useState } from 'react';
import { getDepartmentsDropdown } from 'src/apis/master_data/department.api';
import { TreeItem } from 'src/types/elements.type';
import { buildHierarchySelect } from 'src/utils/collection';

interface Props {
  value?: React.SetStateAction<number | undefined>;
  style?: React.CSSProperties | undefined;
  allowClear?: boolean | undefined;
  onChange?: (value: React.SetStateAction<number | undefined>, label: React.ReactNode[]) => void;
  disabled?: boolean;
  className?: string;
  placeholder?: string;
}

export default function DepartmentTreeSelect({
  value,
  style,
  allowClear = true,
  onChange,
  disabled,
  className,
  placeholder = 'Chọn bộ phận'
}: Props) {
  const [departmentTreeItem, setDepartmentTreeItem] = useState<TreeItem[] | undefined>(undefined);

  const departmentsDropdownQuery = useQuery({
    queryKey: ['departmentsTreeDropdown'],
    queryFn: () => getDepartmentsDropdown(),
    staleTime: 5 * 60 * 1000
  });

  useEffect(() => {
    const result = buildHierarchySelect(departmentsDropdownQuery.data?.data);
    setDepartmentTreeItem(result);
  }, [departmentsDropdownQuery.data?.data]);

  return (
    <TreeSelect
      treeLine={true}
      value={departmentsDropdownQuery.isFetching ? undefined : value}
      disabled={disabled}
      showSearch
      placeholder={placeholder}
      className={className}
      style={style}
      dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
      treeNodeFilterProp='value'
      allowClear={allowClear}
      onChange={onChange}
      treeData={departmentTreeItem}
      loading={departmentsDropdownQuery.isFetching}
      filterTreeNode={(inputValue, treeNode) => {
        return treeNode.title?.toString().toLocaleLowerCase().includes(inputValue.toLocaleLowerCase()) ?? false;
      }}
    />
  );
}
