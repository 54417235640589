import { Tag } from 'antd';
import { FlatItem } from 'src/types/elements.type';

type Props = {
  priorityLevel: FlatItem;
};

export default function priorityLevelTag({ priorityLevel }: Props) {
  switch (priorityLevel?.id) {
    case 1:
      return <Tag color={'red'}>{priorityLevel.name}</Tag>;
    case 2:
      return <Tag color={'warning'}>{priorityLevel.name}</Tag>;
    case 3:
      return <Tag color={'blue'}>{priorityLevel.name}</Tag>;
    case 4:
      return <Tag color={'default'}>{priorityLevel.name}</Tag>;

    default:
      return <></>;
  }
}
