import { FlatItem } from 'src/types/elements.type';
import { AdvancePaymentEmployeeAdd, IAdvancePaymentEmployee } from 'src/types/hr_ticket/advancePaymentTicket.type';
import { PagedList } from 'src/types/pagedList.type';
import { http } from 'src/utils/http';

const controller = 'hr/advance-payment-employees';

export const getAdvancePaymentEmployees = async (
  searchCondition: string,
  page: number,
  pageSize: number,
  orderBy?: string,
  departmentId?: number | undefined
) => {
  return await http.get<PagedList<IAdvancePaymentEmployee>>(`${controller}`, {
    params: {
      searchCondition: searchCondition,
      page,
      pageSize,
      orderBy,
      departmentId
    }
  });
};

export const getAdvancePaymentEmployeesDropdown = async (departmentId?: number) => {
  return await http.get<FlatItem[]>(`${controller}/dropdown`, {
    params: { departmentId }
  });
};
export const getAdvancePaymentEmployee = async (employeeCode: string) => {
  return await http.get<IAdvancePaymentEmployee>(`${controller}/${employeeCode}`);
};

export const addAdvancePaymentEmployees = async (advancePaymentEmployee: AdvancePaymentEmployeeAdd) => {
  return http.post(controller, advancePaymentEmployee);
};

export const updateAdvancePaymentEmployees = async (id: number, advancePaymentEmployee: AdvancePaymentEmployeeAdd) => {
  return await http.put<FlatItem[]>(`${controller}/${id}`, advancePaymentEmployee);
};

export const deleteAdvancePaymentEmployees = async (id: number) => {
  return await http.delete(`${controller}/${id}`);
};
