import { FlatItem, HierarchicalItem } from 'src/types/elements.type';
import {
  IGeneralAccountingAccount,
  GeneralAccountingAccountAdd
} from 'src/types/master_data/generalAccountingAccount.type';
import { PagedList } from 'src/types/pagedList.type';
import { http } from 'src/utils/http';

const controller = 'master-data/general-accounting-accounts';

export async function getJobCategoriesDropdown() {
  return await http.get<FlatItem[]>(`${controller}/dropdown`);
}

export const getGeneralAccountingAccounts = async (
  sequenceStatus: string,
  page: number,
  pageSize: number,
  orderBy?: string
) => {
  return await http.get<PagedList<IGeneralAccountingAccount>>(`${controller}`, {
    params: {
      sequenceStatus,
      page,
      pageSize,
      orderBy
    }
  });
};

export const getGeneralAccountingAccount = async (id: number) => {
  return http.get<IGeneralAccountingAccount>(`${controller}/${id}`);
};

export const addGeneralAccountingAccount = async (generalAccountingAccount: GeneralAccountingAccountAdd) => {
  return http.post<GeneralAccountingAccountAdd>(controller, generalAccountingAccount);
};

export const updateGeneralAccountingAccount = async (
  id: number,
  generalAccountingAccount: IGeneralAccountingAccount
) => {
  return await http.put<IGeneralAccountingAccount[]>(`${controller}/${id}`, generalAccountingAccount);
};

export const deleteGeneralAccountingAccount = async (id: number) => {
  await http.delete(`${controller}/${id}`);
};

export const getGeneralAccountingAccountsDropdown = async (accountingAccountTypeId: number) => {
  return http.get<HierarchicalItem[]>(`${controller}/dropdown`, {
    params: { accountingAccountTypeId }
  });
};
