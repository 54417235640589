import { useQuery } from '@tanstack/react-query';
import { Select } from 'antd';
import { useEffect, useState } from 'react';
import { getEmployeeShortsDropdown } from 'src/apis/master_data/employee.api';
import type { SelectProps } from 'src/types/elements.type';

type Props = Omit<SelectProps, 'onChange' | 'value'> & {
  departmentId?: number;
  onChange?: (value: string[]) => void;
  value?: string[];
  maxTagCount?: number | 'responsive' | undefined;
};

interface OptionType {
  label: React.ReactNode;
  value?: string | number | null;
}

interface OptionGroupType {
  label: React.ReactNode;
  departmentId?: number | undefined;
  options?: Omit<OptionType, 'children'>[];
}

export default function EmployeeGroupMultipleSelect({
  value,
  style,
  allowClear = true,
  onChange,
  departmentId,
  placeholder,
  disabled,
  maxTagCount
}: Props) {
  const [optionsGroup, setOptionsGroup] = useState<OptionGroupType[]>([]);
  const employeesDropdownQuery = useQuery({
    queryKey: ['employeeShortsDropdownV2', departmentId],
    queryFn: () => getEmployeeShortsDropdown(departmentId),
    staleTime: 60 * 1000
  });

  useEffect(() => {
    if (employeesDropdownQuery.data?.data) {
      const newOptionsGroup: OptionGroupType[] = [];
      employeesDropdownQuery.data?.data.forEach((item) => {
        const index = newOptionsGroup.findIndex((optionGroup) => optionGroup.label === item.department?.name);

        if (index === -1) {
          newOptionsGroup.push({
            label: item.department?.name,
            options: [
              {
                label: `${item.code} - ${item.fullName}`,
                value: JSON.stringify(item)
              }
            ]
          });
        } else {
          newOptionsGroup[index].options?.push({
            label: `${item.code} - ${item.fullName}`,
            value: JSON.stringify(item)
          });
        }
      });

      setOptionsGroup(newOptionsGroup);
    }
  }, [employeesDropdownQuery.data?.data]);

  return (
    <Select
      disabled={disabled}
      allowClear={allowClear}
      mode='multiple'
      maxTagCount={maxTagCount}
      value={employeesDropdownQuery.isFetching ? undefined : value}
      style={style}
      placeholder={placeholder}
      onChange={onChange}
      options={optionsGroup}
      showSearch
      filterOption={(input, option) => (option?.label?.toString() ?? '').toLowerCase()?.includes(input.toLowerCase())}
      loading={employeesDropdownQuery.isFetching}
    />
  );
}
