export enum ILinkId {
  manpowerSupplyTicketId = 'manpowerSupplyTicketId',
  ticketCategoryGroupId = 'ticketCategoryGroupId',
  ticketCategoryId = 'ticketCategoryId',
  formId = 'formId',
  useForm = 'useForm',
  refTicketId = 'refTicketId',
  manpowerSupplyFormId = 'manpowerSupplyFormId',
  businessTripTicketId = 'businessTripTicketId',
  equipmentSupplyTicketId = 'equipmentSupplyTicketId',
  businessTripTicketDepartureId = 'businessTripTicketDepartureId',
  businessTripTicketDestinationId = 'businessTripTicketDestinationId',
  businessTripTicketDepartureAddress = 'businessTripTicketDepartureAddress',
  businessTripTicketDestinationAddress = 'businessTripTicketDestinationAddress',

  wageTypeId = 'wageTypeId',
  ownerId = 'ownerId',
  ownerDepartmentId = 'ownerDepartmentId',

  poId = 'poId',
  poCode = 'poCode',
  grpoId = 'grpoId',
  grporId = 'grporId',
  grpoCode = 'grpoCode'
}
