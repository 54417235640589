import { FlatItem } from 'src/types/elements.type';
import { IItemType, ItemTypeAdd } from 'src/types/item_master_data/itemType.type';
import { PagedList } from 'src/types/pagedList.type';
import { http } from 'src/utils/http';

const controller = 'pm/item-types';

export const getItemTypesDropdown = async () => {
  return await http.get<FlatItem[]>(`${controller}/dropdown`);
};

export const getItemTypes = async (
  sequenceStatus: string,
  searchCondition: string | undefined,
  page: number,
  pageSize: number,
  orderBy?: string
) => {
  return await http.get<PagedList<IItemType>>(controller, {
    params: {
      sequenceStatus,
      searchCondition,
      page,
      pageSize,
      orderBy
    }
  });
};

export const getItemType = async (id: number) => {
  return await http.get<IItemType>(`${controller}/${id}`);
};

export const addItemType = async (itemType: ItemTypeAdd) => {
  return await http.post(controller, itemType);
};

export const updateItemType = async (id: number, itemType: ItemTypeAdd) => {
  return await http.put(`${controller}/${id}`, itemType);
};

export const deleteItemType = async (id: number) => {
  return await http.delete(`${controller}/${id}`);
};
