import { FlatItem } from 'src/types/elements.type';
import { ReasonAdd, IReason } from 'src/types/helpdesk/reason.type';
import { PagedList } from 'src/types/pagedList.type';
import { http } from 'src/utils/http';

const controller = 'helpdesk/reasons';

export const getReasons = async (sequenceStatus: string, page: number, pageSize: number, orderBy?: string) => {
  return await http.get<PagedList<IReason>>(controller, {
    params: {
      sequenceStatus,
      page,
      pageSize,
      orderBy
    }
  });
};

export const getReason = async (id: number) => {
  return await http.get<IReason>(`${controller}/${id}`);
};

export const getReasonsDropdown = async () => {
  return await http.get<FlatItem[]>(`${controller}/dropdown`);
};

export const addReason = async (reason: ReasonAdd) => {
  return await http.post(controller, reason);
};

export const updateReason = async (id: number, reason: ReasonAdd) => {
  return await http.put(`${controller}/${id}`, reason);
};

export const deleteReason = async (id: number) => {
  return await http.delete(`${controller}/${id}`);
};
