import {
  EmployeeAdd,
  IEmployee,
  IEmployeeShort,
  IEmployeeShortWithDepartment,
  IEmployeeWithLaborType,
  UsernameByDepartment,
  IEmployeeWithDepartment
} from 'src/types/master_data/employee.type';
import { PagedList } from 'src/types/pagedList.type';

import { http } from 'src/utils/http';
import { FlatItem } from 'src/types/elements.type';
import { IEmployeeUser } from 'src/types/master_data/employeeUser.type';

const controller = 'master-data/employees';

export const getEmployees = async (
  searchCondition: string | undefined,
  page: number,
  pageSize: number,
  orderBy?: string,
  departmentId?: number,
  userName?: string
) =>
  await http.get<PagedList<IEmployee>>(controller, {
    params: {
      searchCondition,
      page,
      pageSize,
      orderBy,
      departmentId,
      userName
    }
  });

export const getEmployeesByDepartment = async (
  departmentId?: number,
  searchCondition?: string,
  sequenceEmployeeIdExclude?: string
) => {
  return await http.get<IEmployee[]>(`${controller}/fullInfo`, {
    params: {
      departmentId,
      searchCondition,
      sequenceEmployeeIdExclude
    }
  });
};

export const getEmployeesDropdown = async (
  departmentId?: number,
  searchCondition?: string,
  sequenceEmployeeIdExclude?: string,
  hasUserName?: boolean
) => {
  return await http.get<IEmployeeShort[]>(`${controller}/dropdown`, {
    params: {
      departmentId,
      searchCondition,
      sequenceEmployeeIdExclude,
      hasUserName
    }
  });
};

export const getDriversDropdown = async (dateCheck?: Date) => {
  return await http.get<FlatItem[]>(`${controller}/drivers-short`, {
    params: { dateCheck }
  });
};

export const getEmployeeShortsDropdown = async (
  departmentId?: number,
  searchCondition?: string,
  sequenceEmployeeIdExclude?: string
) => {
  return await http.get<IEmployee[]>(`${controller}/employee-short`, {
    params: {
      departmentId,
      searchCondition,
      sequenceEmployeeIdExclude
    }
  });
};

export const getShortEmployeesDropdown = async (
  departmentId?: number,
  searchCondition?: string,
  sequenceEmployeeIdExclude?: string
) => {
  return await http.get<IEmployeeShortWithDepartment[]>(`${controller}/employee-short`, {
    params: {
      departmentId,
      searchCondition,
      sequenceEmployeeIdExclude
    }
  });
};

export const getEmployeUsersDropdown = async (searchCondition?: string, sequenceEmployeeIdExclude?: string) => {
  return await http.get<IEmployeeUser[]>(`${controller}/employee-user-by-condition`, {
    params: {
      searchCondition,
      sequenceEmployeeIdExclude
    }
  });
};

export const getEmployeesDropdownByDepartments = async (lstDepartmentId: string | undefined) => {
  return await http.get<IEmployeeWithDepartment[]>(`${controller}/dropdown-by-departments`, {
    params: {
      lstDepartmentId
    }
  });
};

export const getUsernamesByDepartmentDropdown = async (departmentId?: number) => {
  return await http.get<UsernameByDepartment[]>(`${controller}/get-username-by-department`, {
    params: {
      departmentId
    }
  });
};

export const getEmployeesDropdownByWithLaborType = async () => {
  return await http.get<IEmployeeWithLaborType[]>(`${controller}/employee-include-labor-type`);
};

export const getEmployee = async (id: number) => await http.get<IEmployee>(`${controller}/${id}`);

export const addEmployee = async (employee: EmployeeAdd) => {
  return await http.post(controller, employee);
};

export const updateEmployee = async (id: number, employee: EmployeeAdd) => {
  return await http.put(`${controller}/${id}`, employee);
};

export const deleteEmployee = async (id: number) => await http.delete(`${controller}/${id}`);
