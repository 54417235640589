import { IPosition, IPositionDropdown, PositionAdd } from 'src/types/master_data/position.type';
import { PagedList } from 'src/types/pagedList.type';
import { http } from 'src/utils/http';

const controller = 'master-data/positions';

export async function getPositions(
  sequenceStatus: string,
  searchCondition: string | undefined,
  page: number,
  pageSize: number,
  orderBy?: string
) {
  return await http.get<PagedList<IPosition>>(`${controller}`, {
    params: {
      name: searchCondition,
      sequenceStatus,
      page,
      pageSize,
      orderBy
    }
  });
}

export async function getPosition(id: number) {
  return await http.get<IPosition>(`${controller}/${id}`);
}

export async function deletePosition(id: number) {
  return await http.delete(`${controller}/${id}`);
}

export async function getPositionsDropdown() {
  return await http.get<IPositionDropdown[]>(`${controller}/dropdown`);
}

export async function addPosition(position: PositionAdd) {
  return await http.post<IPosition[]>(controller, position);
}

export async function updatePosition(id: number, position: IPosition) {
  return await http.put<IPosition[]>(`${controller}/${id}`, position);
}
