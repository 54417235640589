import { useQuery } from '@tanstack/react-query';
import Select, { SelectProps } from 'antd/es/select';
import { getTicketTypesDropdown } from 'src/apis/helpdesk/ticketType.api';

interface Props extends SelectProps {
  placeholder?: string;
}

export default function TicketTypeSelect({ value, onChange, style, allowClear = true, placeholder }: Props) {
  const ticketTypesDropdownQuery = useQuery({
    queryKey: ['ticketTypesDropdown'],
    queryFn: () => getTicketTypesDropdown(),
    staleTime: 60 * 1000
  });

  return (
    <Select
      value={ticketTypesDropdownQuery.isFetching ? undefined : value}
      style={style}
      onChange={onChange}
      options={ticketTypesDropdownQuery.data?.data.map((item) => ({
        value: item.id,
        label: item.name
      }))}
      placeholder={placeholder}
      allowClear={allowClear}
      showSearch
      filterOption={(input, option) => (option?.label?.toString() ?? '').toLowerCase()?.includes(input.toLowerCase())}
      loading={ticketTypesDropdownQuery.isFetching}
    />
  );
}
