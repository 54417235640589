import { useQuery } from '@tanstack/react-query';
import { Select, SelectProps } from 'antd';
import { getItemTypesDropdown } from 'src/apis/item_master_data/itemType.api';

export default function ItemTypeSelect({
  value,
  onChange,
  style,
  allowClear = true,
  placeholder,
  disabled
}: SelectProps) {
  const itemTypesDropdownQuery = useQuery({
    queryKey: ['itemTypesDropdown'],
    queryFn: () => getItemTypesDropdown(),
    staleTime: 60 * 1000
  });

  return (
    <Select
      disabled={disabled}
      allowClear={allowClear}
      value={itemTypesDropdownQuery.isFetching ? undefined : value}
      style={style}
      onChange={onChange}
      options={itemTypesDropdownQuery.data?.data.map((item) => ({ value: item.id, label: item.name }))}
      showSearch
      filterOption={(input, option) => (option?.label?.toString() ?? '').toLowerCase()?.includes(input.toLowerCase())}
      placeholder={placeholder}
      loading={itemTypesDropdownQuery.isFetching}
    />
  );
}
