import { useQuery } from '@tanstack/react-query';
import { Select } from 'antd';
import { getDepartmentChildDropdown } from 'src/apis/master_data/department.api';
import { FlatItem } from 'src/types/elements.type';

interface Props {
  value?: string;
  style?: React.CSSProperties | undefined;
  allowClear?: boolean | undefined;
  onChange?: (value: string) => void;
  disabled?: boolean;
  departmentParentId?: number;
  placement?: 'bottomLeft' | 'bottomRight' | 'topLeft' | 'topRight';
}

export default function DepartmentChildrenSelectV2({
  value,
  style,
  allowClear = true,
  onChange,
  disabled,
  departmentParentId,
  placement
}: Props) {
  const departmentChildDropdownQuery = useQuery({
    queryKey: ['departmentChildDropdown'],
    queryFn: () => getDepartmentChildDropdown(departmentParentId),
    staleTime: 5 * 60 * 1000,
    enabled: !!departmentParentId
  });

  return (
    <Select
      allowClear={allowClear}
      value={departmentChildDropdownQuery.isFetching ? undefined : value}
      style={style}
      placement={placement}
      onChange={onChange}
      disabled={disabled}
      options={departmentChildDropdownQuery.data?.data?.map((data: FlatItem) => ({
        value: JSON.stringify({
          id: data.id,
          name: data.name,
          code: data.code
        }),
        label: data.name
      }))}
      showSearch
      filterOption={(input, option) => (option?.label?.toString() ?? '').toLowerCase()?.includes(input.toLowerCase())}
      loading={departmentChildDropdownQuery.isFetching}
    />
  );
}
