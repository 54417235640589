import { useQuery } from '@tanstack/react-query';
import { Select } from 'antd';
import { getPriorityLevelsDropdown } from 'src/apis/helpdesk/priorityLevel.api';
import { SelectProps } from 'src/types/elements.type';

export default function PriorityLevelSelectV2({ value, onChange, style, allowClear = true }: SelectProps) {
  const priorityLevelsDropdownV2Query = useQuery({
    queryKey: ['priorityLevelsDropdownV2'],
    queryFn: () => getPriorityLevelsDropdown(),
    staleTime: 60 * 1000
  });

  return (
    <Select
      allowClear={allowClear}
      value={priorityLevelsDropdownV2Query.isFetching ? undefined : value}
      style={style}
      onChange={onChange}
      options={priorityLevelsDropdownV2Query.data?.data.map((data) => ({
        value: JSON.stringify(data),
        label: data.name
      }))}
      showSearch
      filterOption={(input, option) => (option?.label?.toString() ?? '').toLowerCase()?.includes(input.toLowerCase())}
      loading={priorityLevelsDropdownV2Query.isFetching}
    />
  );
}
