import { useQuery } from '@tanstack/react-query';
import { Select } from 'antd';
import { DefaultOptionType } from 'antd/es/select';
import { useEffect, useState } from 'react';
import { getPositionsDropdown } from 'src/apis/master_data/position.api';
import { SelectProps } from 'src/types/elements.type';
import { IPositionDropdown } from 'src/types/master_data/position.type';

type Props = Omit<SelectProps, 'value'> & {
  onChange?: ((value: any, option: DefaultOptionType | DefaultOptionType[]) => void) | undefined;
  mode?: 'all' | 'differentDepartment';
  value?: number;
};

export default function PositionSelect({
  onChange,
  style,
  allowClear = true,
  value,
  mode = 'all',
  disabled,
  placeholder
}: Props) {
  const [dropdowns, setDropdowns] = useState<IPositionDropdown[]>([]);
  const positionsDropdownQuery = useQuery({
    queryKey: ['positionsDropdown'],
    queryFn: () => {
      return getPositionsDropdown();
    },
    onSuccess: (data) => {
      if (mode === 'all') {
        setDropdowns(data.data);
      } else {
        const dropdownsDifferentDepartment = data.data.filter((item) => item.isSameDepartment === false);
        setDropdowns(dropdownsDifferentDepartment);
      }
    },
    staleTime: 60 * 1000
  });

  useEffect(() => {
    if (positionsDropdownQuery.data?.data) {
      if (mode === 'all') {
        setDropdowns(positionsDropdownQuery.data.data);
      } else {
        const dropdownsDifferentDepartment = positionsDropdownQuery.data.data.filter(
          (item) => item.isSameDepartment === false
        );
        setDropdowns(dropdownsDifferentDepartment);
      }
    }
  }, [positionsDropdownQuery.data?.data, mode]);

  return (
    <Select
      placeholder={placeholder}
      allowClear={allowClear}
      disabled={disabled}
      value={positionsDropdownQuery.isFetching ? undefined : value ? Number(value) : undefined}
      style={style}
      onChange={onChange}
      options={dropdowns.map((data) => ({ value: data.id, label: data.name }))}
      showSearch
      filterOption={(input, option) => (option?.label?.toString() ?? '').toLowerCase()?.includes(input.toLowerCase())}
      loading={positionsDropdownQuery.isFetching}
    />
  );
}
