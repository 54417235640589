import { useQuery } from '@tanstack/react-query';
import { Select } from 'antd';
import { debounce } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { getTicketCategoriesAvailableToSetupDropdown } from 'src/apis/helpdesk/ticketCategory.api';
import { SelectProps } from 'src/types/elements.type';

type Props = SelectProps & {
  ticketCategoryUpdateId?: number | undefined;
};

export default function TicketCategoryAvailableSetupSelect({
  value,
  onChange,
  style,
  allowClear = true,
  placeholder
}: Props) {
  const [ticketCategoryUpdateId, setTicketCategoryUpdateId] = useState<number | undefined>();
  const [valueCurrent, setValueCurrent] = useState<string | undefined>(undefined);

  const ticketCategoriesAvailableSetupDropdownQuery = useQuery({
    queryKey: ['ticketCategoriesAvailableSetupDropdown', ticketCategoryUpdateId],
    queryFn: () => getTicketCategoriesAvailableToSetupDropdown(ticketCategoryUpdateId),
    staleTime: 60 * 1000
  });

  const debouncedSearch = useMemo(
    () =>
      debounce((ticketCategoryUpdateId) => {
        setTicketCategoryUpdateId(ticketCategoryUpdateId);
      }, 1000),
    []
  );

  useEffect(() => {
    setTicketCategoryUpdateId(ticketCategoryUpdateId);
  }, [ticketCategoryUpdateId, debouncedSearch]);

  useEffect(() => {
    if (
      ticketCategoriesAvailableSetupDropdownQuery.data?.data &&
      ticketCategoriesAvailableSetupDropdownQuery.data?.data.length > 0
    ) {
      const find = ticketCategoriesAvailableSetupDropdownQuery.data?.data.find(
        (item) => JSON.stringify(item) === value
      );
      if (find) {
        setValueCurrent(value as string);
      } else {
        setValueCurrent(undefined);
      }
    }
  }, [value, ticketCategoriesAvailableSetupDropdownQuery.data?.data]);

  return (
    <Select
      allowClear={allowClear}
      placeholder={placeholder}
      value={ticketCategoriesAvailableSetupDropdownQuery.isFetching ? undefined : valueCurrent}
      style={style}
      onChange={onChange}
      options={ticketCategoriesAvailableSetupDropdownQuery.data?.data.map((data) => ({
        value: JSON.stringify(data),
        label: data.name
      }))}
      showSearch
      filterOption={(input, option) => (option?.label?.toString() ?? '').toLowerCase()?.includes(input.toLowerCase())}
      loading={ticketCategoriesAvailableSetupDropdownQuery.isFetching}
    />
  );
}
