import { useQuery } from '@tanstack/react-query';
import { Flex, Select, Typography } from 'antd';
import { ReactNode, useEffect, useState } from 'react';
import { getDistanceAll } from 'src/apis/master_data/distance.api';
import { SelectProps } from 'src/types/elements.type';
import { IDistance } from 'src/types/master_data/distance.type';
import './DistanceSelect.scss';

const { Text } = Typography;

type Props = SelectProps & {
  placeholder?: ReactNode;
};

export default function DistanceSelect({ value, style, allowClear = true, onChange, placeholder }: Props) {
  const [options, setOption] = useState<IDistance[] | undefined>();

  const distancesDropdownQuery = useQuery({
    queryKey: ['distancesDropdown'],
    queryFn: () => getDistanceAll(),
    staleTime: 60 * 1000
  });

  useEffect(() => {
    if (distancesDropdownQuery.data) {
      setOption(distancesDropdownQuery.data.data);
    }
  }, [distancesDropdownQuery.data]);

  const handleSearch = (searchTerm: string) => {
    if (!searchTerm) setOption(distancesDropdownQuery.data?.data);
    else {
      const searchTermLowerCase = searchTerm.toLowerCase();
      const optionFilter = distancesDropdownQuery.data?.data.filter((item) => {
        return (
          item.description?.toLowerCase().includes(searchTermLowerCase) ||
          item.startingLocation?.toLowerCase().includes(searchTermLowerCase) ||
          item.destinationLocation?.toLowerCase().includes(searchTermLowerCase)
        );
      });

      setOption(optionFilter);
    }
  };

  return (
    <Select
      onChange={onChange}
      allowClear={allowClear}
      value={distancesDropdownQuery.isFetching ? undefined : value}
      style={{ ...style, height: 'fit-content', width: '100%' }}
      filterOption={false}
      onSearch={handleSearch}
      placeholder={placeholder}
      showSearch
      options={options?.map((option, _, arr) => {
        return {
          value: JSON.stringify({
            departureId: option.startingLocationId,
            destinationId: option.destinationLocationId,
            distance: option.total
          }),
          label: (
            <Flex justify='space-between' align='center'>
              <Flex vertical>
                <Flex gap={5}>
                  <Text strong>Điểm đi:</Text>
                  <Text> {option.startingLocation}</Text>
                </Flex>
                <Flex gap={5}>
                  <Text strong>Điểm đến:</Text>
                  <Text>{option.destinationLocation}</Text>
                </Flex>
                {option.description && (
                  <Flex gap={5}>
                    <Text strong>Mô tả:</Text>
                    <Text type='secondary' style={{ wordWrap: 'break-word' }}>
                      {option.description}
                    </Text>
                  </Flex>
                )}
              </Flex>
              <Flex gap={5}>
                <Text italic>{option.total} km</Text>
              </Flex>
            </Flex>
          )
        };
      })}
      loading={distancesDropdownQuery.isFetching}
    />
  );
}
