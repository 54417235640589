import { useQuery } from '@tanstack/react-query';
import { Select } from 'antd';
import { getProjectsDropdown } from 'src/apis/hr_ticket/projectCode.api';
import { SelectProps } from 'src/types/elements.type';

export default function ProjectSelect({ value, onChange, style, allowClear }: SelectProps) {
  const projectsDropdownQuery = useQuery({
    queryKey: ['projectsDropdown'],
    queryFn: () => getProjectsDropdown(),
    staleTime: 60 * 1000
  });

  return (
    <Select
      allowClear={allowClear}
      value={projectsDropdownQuery.isFetching ? undefined : value}
      style={style}
      onChange={onChange}
      options={projectsDropdownQuery.data?.data.map((data) => ({ value: data.code, label: data.code }))}
      showSearch
      filterOption={(input, option) => (option?.label?.toString() ?? '').toLowerCase()?.includes(input.toLowerCase())}
      loading={projectsDropdownQuery.isFetching}
    />
  );
}
