import { useQuery } from '@tanstack/react-query';
import { Select, SelectProps } from 'antd';
import { getAccountingAccountTypesDropdown } from 'src/apis/master_data/accountingAccountType.api';

export default function AccountingAccountTypeSelect({ value, onChange, style, allowClear }: SelectProps) {
  const accountingAccountTypesDropdownQuery = useQuery({
    queryKey: ['accountingAccountTypesDropdown'],
    queryFn: () => getAccountingAccountTypesDropdown(),
    staleTime: 60 * 1000
  });

  return (
    <Select
      allowClear={allowClear}
      value={accountingAccountTypesDropdownQuery.isFetching ? undefined : value}
      style={style}
      onChange={onChange}
      options={accountingAccountTypesDropdownQuery.data?.data.map((data) => ({ value: data.id, label: data.name }))}
      showSearch
      filterOption={(input, option) => (option?.label?.toString() ?? '').toLowerCase()?.includes(input.toLowerCase())}
      loading={accountingAccountTypesDropdownQuery.isFetching}
    />
  );
}
