import { useQuery } from '@tanstack/react-query';
import Select from 'antd/es/select';
import { useEffect, useState } from 'react';
import { getTicketStatusesDropdown } from 'src/apis/helpdesk/ticketStatus.api';
import { SelectProps } from 'src/types/elements.type';
import { TicketStatus } from 'src/types/helpdesk/ticket.type';
import { StatusType } from 'src/types/helpdesk/ticketStatus.type';

type TicketStatusSelectType = SelectProps & {
  statusType?: StatusType;
  placeholder?: string;
  mode?: 'hr' | 'ticket';
};

interface OptionType {
  value: string;
  label: string;
}

export default function TicketStatusSelect({
  value,
  onChange,
  style,
  allowClear,
  statusType,
  placeholder,
  mode
}: TicketStatusSelectType) {
  const ticketStatusesDropdownQuery = useQuery({
    queryKey: ['ticketStatusesDropdown', statusType],
    queryFn: () => getTicketStatusesDropdown(statusType),
    staleTime: 60 * 1000
  });

  const [ticketStatuses, setTicketStatuses] = useState<OptionType[]>();

  useEffect(() => {
    if (ticketStatusesDropdownQuery.data?.data && ticketStatusesDropdownQuery.data?.data.length > 0) {
      const options = ticketStatusesDropdownQuery.data?.data.map((item) => ({
        value: String(item.id),
        label: item.name
      }));
      if (mode === 'hr') {
        const filterOptions = options.filter((item) => {
          if (item.value === String(TicketStatus.AcceptReceiveRequest)) return null;
          if (item.value === String(TicketStatus.Processing)) return null;
          if (item.value === String(TicketStatus.HandleCompleted)) return null;
          if (item.value === String(TicketStatus.Finished)) return null;
          return item;
        });
        setTicketStatuses(filterOptions);
      } else {
        setTicketStatuses(options);
      }
    }
  }, [ticketStatusesDropdownQuery.data?.data, mode]);

  return (
    <Select
      allowClear={allowClear}
      value={ticketStatusesDropdownQuery.isFetching ? undefined : value}
      style={style}
      placeholder={placeholder}
      onChange={onChange}
      options={ticketStatuses}
      showSearch
      filterOption={(input, option) => (option?.label?.toString() ?? '').toLowerCase()?.includes(input.toLowerCase())}
      loading={ticketStatusesDropdownQuery.isFetching}
    />
  );
}
