import { Select, Typography } from 'antd';
import { UnitOfCurrencyDropdown } from 'src/types/master_data/unitOfCurrency.type';
import { SelectProps } from 'src/types/elements.type';
import './UnitOfCurrencySelect.scss';

const { Text } = Typography;

type Props = SelectProps & {
  value: string;
  options: UnitOfCurrencyDropdown[];
  loading: boolean;
  disabled: boolean;
};

export default function UnitOfCurrencySelect({
  value,
  options,
  style,
  allowClear,
  onChange,
  placeholder,
  loading,
  disabled
}: Props) {
  return (
    <Select
      rootClassName='unit-of-currency'
      allowClear={allowClear}
      disabled={disabled}
      value={loading ? undefined : value}
      style={style}
      placeholder={placeholder}
      onChange={onChange}
      options={options.map((item) => ({
        label: <Text className={`${item.conversionValue <= 0 && 'disable'}`}>{item.code}</Text>,
        value: JSON.stringify(item)
      }))}
      showSearch
      filterOption={(input, option) => {
        return (option?.label?.toString() ?? '').toLowerCase()?.includes(input.toLowerCase());
      }}
      loading={loading}
    />
  );
}
