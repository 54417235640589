import { FlatItem } from 'src/types/elements.type';
import { ISubSystem, SubSystemAdd } from 'src/types/master_data/subSystem.type';
import { PagedList } from 'src/types/pagedList.type';
import { http } from 'src/utils/http';

const controller = 'master-data/sub-systems';

export const getSubSystems = async (name: string | undefined, page: number, pageSize: number, orderBy?: string) => {
  return await http.get<PagedList<ISubSystem>>(controller, {
    params: {
      name,
      page,
      pageSize,
      orderBy
    }
  });
};

export const getSubSystem = async (id: number) => {
  return http.get<ISubSystem>(`${controller}/${id}`);
};

export const addSubSystem = async (subSystem: SubSystemAdd) => {
  return http.post<SubSystemAdd>(controller, subSystem);
};

export const updateSubSystem = async (id: number, subSystem: ISubSystem) => {
  return await http.put<ISubSystem[]>(`${controller}/${id}`, subSystem);
};

export const deleteSubSystem = async (id: number) => {
  await http.delete(`${controller}/${id}`);
};

export const getSubSystemsDropdown = async () => {
  return http.get<FlatItem[]>(`${controller}/dropdown`);
};

export const getFunctionsDropdown = async (subSystemId?: number) => {
  return http.get<FlatItem[]>(`${controller}/dropdown-function`, {
    params: {
      subSystemId: subSystemId
    }
  });
};
