import { EmployeeCompensationAdd, IEmployeeCompensation } from 'src/types/hr_ticket/employeeCompensation.type';
import { PagedList } from 'src/types/pagedList.type';
import { http } from 'src/utils/http';

const controller = 'hr/employee-compensations';

export const getEmployeeCompensations = async (
  page: number,
  pageSize: number,
  orderBy?: string,
  departmentId?: number,
  month?: number,
  year?: number
) => {
  return await http.get<PagedList<IEmployeeCompensation>>(`${controller}`, {
    params: {
      page,
      pageSize,
      orderBy,
      departmentId,
      month,
      year
    }
  });
};

export const getEmployeeCompensationById = async (id: number) => {
  return await http.get<IEmployeeCompensation>(`${controller}/${id}`);
};

export const addEmployeeCompensation = async (employeeCompensation: EmployeeCompensationAdd) => {
  return await http.post(controller, employeeCompensation);
};

export const updateEmployeeCompensation = async (id: number, employeeCompensation: EmployeeCompensationAdd) => {
  return await http.put(`${controller}/${id}`, employeeCompensation);
};

export const deleteEmployeeCompensation = async (id: number) => {
  return await http.delete(`${controller}/${id}`);
};
