import { useQuery } from '@tanstack/react-query';
import { Select } from 'antd';
import { getResourcesDropdown } from 'src/apis/master_data/resource.api';

interface Props {
  value?: string;
  style?: React.CSSProperties | undefined;
  allowClear?: boolean;
  disabled?: boolean;
  appInfoId?: number;
  onChange?: (
    value: string,
    option?:
      | {
          value: string;
          label: string;
        }
      | {
          value: string;
          label: string;
        }[]
  ) => void;
}

export default function ResourcesSelectV3({ value, onChange, style, allowClear, disabled, appInfoId }: Props) {
  const resourcesDropdownV3Query = useQuery({
    queryKey: ['resourcesDropdownV3', appInfoId],
    queryFn: () => getResourcesDropdown(appInfoId),
    staleTime: 60 * 1000
  });

  return (
    <Select
      allowClear={allowClear}
      disabled={disabled}
      placement='bottomLeft'
      value={resourcesDropdownV3Query.isFetching ? undefined : value}
      style={style}
      onChange={onChange}
      options={resourcesDropdownV3Query.data?.data.map((data) => ({
        value: JSON.stringify({
          id: data.id,
          name: data.name
        }),
        label: data.name
      }))}
      showSearch
      filterOption={(input, option) => (option?.label?.toString() ?? '').toLowerCase()?.includes(input.toLowerCase())}
      loading={resourcesDropdownV3Query.isFetching}
    />
  );
}
