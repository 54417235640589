import { FlatItem, HierarchicalItem } from 'src/types/elements.type';
import {
  EmployeeWithTicketsType,
  ITicketPermission,
  IUserTicketCategory,
  UserTicketCategoryAdd
} from 'src/types/master_data/userTicketCategory.type';
import { PagedList } from 'src/types/pagedList.type';
import { http } from 'src/utils/http';

const controller = 'master-data/user-ticket-categories';

export const getUserTicketCategories = async (
  page: number,
  pageSize: number,
  orderBy?: string,
  userName?: string,
  positionId?: number | undefined,
  departmentId?: number | undefined,
  ticketCategoryId?: number
) => {
  return await http.get<PagedList<IUserTicketCategory>>(`${controller}`, {
    params: {
      departmentId,
      page,
      pageSize,
      orderBy,
      userName,
      ticketCategoryId,
      positionId
    }
  });
};

export const getUserTicketCategory = async (id: number) => {
  return http.get<ITicketPermission>(`${controller}/${id}`);
};

export const getUserTicketCategoryByUserName = async (userName?: string) => {
  return http.get<IUserTicketCategory>(`${controller}/get-by-username`, {
    params: {
      userName
    }
  });
};

export const addUserTicketCategory = async (userTicketCategory: UserTicketCategoryAdd) => {
  return http.post<UserTicketCategoryAdd>(controller, userTicketCategory);
};

export const updateUserTicketCategory = async (id: number, userTicketCategory: UserTicketCategoryAdd) => {
  return await http.put(`${controller}/${id}`, userTicketCategory);
};

export const deleteUserTicketCategory = async (ids: number[]) => {
  await http.delete(controller, {
    data: ids
  });
};

export const getUserTicketCategoriesDropdown = async () => {
  return http.get<FlatItem[]>(`${controller}/dropdown`);
};

export const getUsersAvailableAssignDropdown = async (ticketCategory: number, departmentId: number) => {
  return http.get<EmployeeWithTicketsType[]>(`${controller}/user-available-assign`, {
    params: {
      ticketCategory,
      departmentId
    }
  });
};

export const getDepartmentsAvailableRaise = async (ticketCategoryId: number) => {
  return await http.get<HierarchicalItem[]>(`${controller}/department-available-raise`, {
    params: { ticketCategoryId }
  });
};
