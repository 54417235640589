import { useQuery } from '@tanstack/react-query';
import { Select } from 'antd';
import { getEmployeesDropdownByDepartments } from 'src/apis/master_data/employee.api';

interface Props {
  value?: React.SetStateAction<string[]>;
  style?: React.CSSProperties | undefined;
  allowClear?: boolean | undefined;
  onChange?: (value: React.SetStateAction<string[]>) => void;
  departmentId?: number;
  mode?: 'multiple' | 'tags';
  maxTagCount?: number | 'responsive';
}

export default function EmployeeMultipleSelectV2({
  value,
  style,
  allowClear,
  onChange,
  departmentId,
  mode = 'tags',
  maxTagCount
}: Props) {
  const employeesDropdownQuery = useQuery({
    queryKey: ['employeesDropdownV3', departmentId],
    queryFn: () => getEmployeesDropdownByDepartments(String(departmentId)),
    staleTime: 60 * 1000
  });

  return (
    <Select
      allowClear={allowClear}
      mode={mode}
      maxTagCount={maxTagCount}
      value={employeesDropdownQuery.isFetching ? undefined : value}
      style={style}
      onChange={onChange}
      options={employeesDropdownQuery.data?.data.map((data) => ({
        value: data.id,
        label: `${data.code} - ${data.fullName}`
      }))}
      showSearch
      filterOption={(input, option) => (option?.label?.toString() ?? '').toLowerCase()?.includes(input.toLowerCase())}
      loading={employeesDropdownQuery.isFetching}
    />
  );
}
