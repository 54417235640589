import { useSearchParams } from 'react-router-dom';
import { SearchParamType } from 'src/types/searchParams.type';

export const useUpdateSearchParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const updateSearchParams = (params: SearchParamType[]) => {
    setSearchParams((prev) => {
      params.forEach((param) => {
        if (!param.value) {
          prev.delete(param.key);
        } else {
          prev.set(param.key, String(param.value));
        }
      });
      return prev;
    });
  };

  return { updateSearchParams, searchParams };
};
