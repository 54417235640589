import { ItemMasterData } from 'src/types/itemMasterData.type';
import { http } from 'src/utils/http';

const controller = 'hr/item-master-datas';

export const getItemsMasterDataByCondition = async (searchCondition: string) => {
  return http.get<ItemMasterData[]>(`${controller}/search`, {
    params: {
      searchCondition: searchCondition || ''
    }
  });
};
